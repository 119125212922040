import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import './styles.css';
import styled from 'styled-components';

import { Button } from '@mui/material';

import { getDate } from '../../../utils/functions/UtilsJuvalfri';
import { formatMoney } from '../../../utils/functions/UtilsJuvalfri';

const StyledPageBreak = styled.div`
    @media print {
        height: ${props => props.height}
    }
    // height: ${props => props.height}
`

const StyledHeader = styled.div`
    display: none;
    @media print {
        display: block;
    }
`

const HojaCotizacion = (props) => {

    const [renderHoja, setRenderHoja] = useState(<></>);
    const [data, setData] = useState({})
    const [refacciones, setRefacciones] = useState([]);
    const [servicio, setServicio] = useState({});
    const [cotizacion, setCotizacion] = useState({});
    const [flag, setFlag] = useState(false);
    const [height, setHeight] = useState(0);
    const [height2, setHeight2] = useState(0);
    const ref = useRef(null);
    const ref2 = useRef(null);
    const [layout, setLayout] = useState(0);
    const [admin, setAdmin] = useState('');
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (flag) {
            setHeight(ref.current.clientHeight);
            setHeight2(ref2.current.clientHeight);
        }
        if (height2 > 550) {
            // console.log('tres', height2);
            setLayout(1);
        }
        // else if(height2 > 400) {
        else if (height2 > 460) {
            // console.log('dos', height2);
            setLayout(2);
        }
        // if(height > 450) {
        if (height > 470) {
            // console.log('Uno', height);
            setLayout(1);
        }
    }, [height, height2, flag])

    const printDocument = () => {
        let printContents = document.getElementById('printDiv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();
    }

    useEffect(() => {
        if (props.location.state) {
            setData(props.location.state.data);
            setRefacciones(props.location.state.refacciones);
            setServicio(props.location.state.servicio);
            setCotizacion(props.location.state.cotizacion);
            setAdmin(props.location.state.admin);
            document.title = `Juvalfri ${props.location.state.data.noReporte}-${props.location.state.data.noInventario}`;
        }
    }, [props])

    // cambia el modo de edicion del documento
    useEffect(() => {
        if (edit)
            document.designMode = "on";
        else
            document.designMode = "off";
    }, [edit])

    const getSuministros = (refacciones, servicio, cotizacion) => {
        if (layout === 0) {
            return (
                <>
                    <div ref={ref2} style={{ width: '770px', border: 'solid 0.5px black', borderRadius: '5px', marginLeft: '23px' }}>
                        <div className='text-center'>
                            <p className='mb-0 text-bold' style={{ background: '#BAD7F2', color: '#000', fontSize: '12px', border: 'solid black 0.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
                                Suministros
                            </p>
                        </div>
                        <div className='d-flex align-items-stretch'>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{ color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px' }}>&nbsp;&nbsp;No. CATALOGO: </p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px' }}>CANTIDAD</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px' }}>DESCRIPCIÓN</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>PRECIO UNITARIO</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>IMPORTE</p>
                        </div>
                        <div ref={ref}>
                            {refacciones.map(r => (
                                r.precioUnitario !== 0 ?
                                    <div className='d-flex' key={r.key}>
                                        <p className='mb-0 d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px' }}>&nbsp;&nbsp;{r.noCatalogo}</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px' }}>{r.cantidad}</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-center text-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px' }}>{r.refaccion}</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(r.precioUnitario)}`}&nbsp;&nbsp;</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(r.precioUnitario * r.cantidad)}`}&nbsp;&nbsp;</p>
                                    </div> : <></>
                            ))}
                        </div>
                        {/* <div className='d-flex'>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-bold' style={{ color: '#000', fontSize: '10px', width: '120px', border: 'solid black 0.5px' }}>&nbsp;&nbsp;SERVICIO</p>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-center p-1' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px' }}>{servicio.descripcion}</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                        </div> */}
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '490px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>SUBTOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(cotizacion.subtotal)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '490px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>IVA</p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(cotizacion.iva)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '490px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>TOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px', borderBottomRightRadius: '4px' }}>{`$ ${formatMoney(cotizacion.total)}`}</p>
                        </div>
                    </div>

                    <div className='justify-content-center' style={{ position: 'absolute', bottom: '60px', zIndex: '1', marginLeft: '23px' }}>
                        <hr className='my-2' style={{ width: '770px' }}></hr>
                        <div className='d-flex flex-row'>
                            <div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '170px', fontSize: '11px' }} className='my-1 px-1 d-flex align-items-center text-bold'>Términos y condiciones:</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='my-1 px-1 d-flex align-items-center'>Vigencia de la cotización:</p>
                                    <p style={{ fontSize: '11px' }} className='my-1 px-1 text-bold'>{cotizacion.terms.vigencia}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='mb-1 px-1 d-flex align-items-center'>Tiempo de entrega:</p>
                                    <p style={{ fontSize: '11px' }} className='mb-1 px-1 text-bold'>{cotizacion.terms.tiempoEntrega}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='mb-1 px-1 d-flex align-items-center'>Garantía:</p>
                                    <p style={{ fontSize: '11px' }} className='mb-1 px-1 text-bold'>{cotizacion.terms.garantia}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='mb-1 px-1 d-flex align-items-center'>Forma de pago:</p>
                                    <p style={{ fontSize: '11px' }} className='mb-1 px-1 text-bold'>{cotizacion.terms.formaDePago}</p>
                                </div>
                            </div>

                            <div>
                                <div className='mx-3 text-center'>
                                    <img src="/sello-juvalfri.svg" alt='juvalfri-sello' style={{ width: "130px", height: 'auto' }} />
                                </div>
                                {/* <div className='mx-3 text-center'>
                                <img src="/firma-abdias.svg" alt='juvalfri-sello' style={{width: "100px", height: 'auto', marginTop: '-5px', marginLeft: '10px'}} />
                            </div> */}
                                <div className='text-center' style={{ marginTop: '32px' }}>
                                    <p className='text-bold mb-0' style={{ fontSize: '8px' }}>__________________________________________________</p>
                                    <p className='text-bold mb-0' style={{ fontSize: '8px' }}>{admin}</p>
                                    <p className='text-bold mb-2' style={{ fontSize: '8px' }}>ADMINISTRADOR ÚNICO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        if (layout === 1) {
            return (
                <>
                    <div style={{ width: '770px', border: 'solid 0.5px black', borderRadius: '5px', marginLeft: '23px' }}>
                        <div className='text-center'>
                            <p className='mb-0 text-bold' style={{ background: '#BAD7F2', color: '#000', fontSize: '12px', border: 'solid black 0.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
                                Suministros
                            </p>
                        </div>
                        <div className='d-flex align-items-stretch'>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{ color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px' }}>&nbsp;&nbsp;No. CATALOGO: </p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px' }}>CANTIDAD</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px' }}>DESCRIPCIÓN</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>PRECIO UNITARIO</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>IMPORTE</p>
                        </div>
                        <div>
                            {refacciones.map(r => (
                                r.precioUnitario !== 0 ?
                                    <div className='d-flex' key={r.key}>
                                        <p className='mb-0 d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px' }}>&nbsp;&nbsp;{r.noCatalogo}</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px' }}>{r.cantidad}</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-center text-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px' }}>{r.refaccion}</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(r.precioUnitario)}`}&nbsp;&nbsp;</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(r.precioUnitario * r.cantidad)}`}&nbsp;&nbsp;</p>
                                    </div> : <></>
                            ))}
                        </div>
                    </div>

                    <div style={{ height: `${750 - height}px` }}></div>

                    <div style={{ height: '40px' }}>
                        {edit ? <></> : <img src="/bg-juvalfri.svg" alt='juvalfri-logo' style={{ position: 'absolute', top: '0px', left: '0px', width: "100%", height: 'auto' }} />}
                    </div>
                    <div style={{ position: 'absolute', top: '1136px', right: '23px', width: '240px', }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p className='mb-0' style={{ fontWeight: '600', fontSize: '14px' }}>
                                Cotización &nbsp;<span style={{ fontSize: '1.2em', fontWeight: '700' }}>{data.noReporte} </span> &nbsp; (continuación)
                            </p>
                            <p className='mb-0' style={{ fontWeight: '600', fontSize: '13px' }}>
                                CDMX &nbsp; | &nbsp;{getDate(data.fecha.cotizacion, 'dd/MM/yyyy')}
                            </p>
                        </div>
                    </div>

                    <div style={{ width: '770px', border: 'solid 0.5px black', borderRadius: '5px', marginLeft: '23px' }}>
                        <div className='text-center'>
                            <p className='mb-0 text-bold' style={{ background: '#BAD7F2', color: '#000', fontSize: '12px', border: 'solid black 0.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
                                Suministros
                            </p>
                        </div>
                        {/* <div className='d-flex'>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-bold' style={{ color: '#000', fontSize: '10px', width: '120px', border: 'solid black 0.5px' }}>&nbsp;&nbsp;SERVICIO</p>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-center p-1' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px' }}>{servicio.descripcion}</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                        </div> */}
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '490px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>SUBTOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(cotizacion.subtotal)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '490px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>IVA</p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(cotizacion.iva)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '490px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>TOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px', borderBottomRightRadius: '4px' }}>{`$ ${formatMoney(cotizacion.total)}`}</p>
                        </div>
                    </div>

                    <div className='justify-content-center' style={{ marginLeft: '23px' }}>
                        <hr className='my-3' style={{ width: '770px' }}></hr>
                        <div className='d-flex flex-row'>
                            <div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '170px', fontSize: '11px' }} className='my-1 px-1 d-flex align-items-center text-bold'>Términos y condiciones:</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='my-1 px-1 d-flex align-items-center'>Vigencia de la cotización:</p>
                                    <p style={{ fontSize: '11px' }} className='my-1 px-1 text-bold'>{cotizacion.terms.vigencia}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='mb-1 px-1 d-flex align-items-center'>Tiempo de entrega:</p>
                                    <p style={{ fontSize: '11px' }} className='mb-1 px-1 text-bold'>{cotizacion.terms.tiempoEntrega}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='mb-1 px-1 d-flex align-items-center'>Garantía:</p>
                                    <p style={{ fontSize: '11px' }} className='mb-1 px-1 text-bold'>{cotizacion.terms.garantia}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='mb-1 px-1 d-flex align-items-center'>Forma de pago:</p>
                                    <p style={{ fontSize: '11px' }} className='mb-1 px-1 text-bold'>{cotizacion.terms.formaDePago}</p>
                                </div>
                            </div>

                            <div>
                                <div className='mx-3 text-center'>
                                    <img src="/sello-juvalfri.svg" alt='juvalfri-sello' style={{ width: "130px", height: 'auto' }} />
                                </div>
                                {/* <div className='mx-3 text-center'>
                                <img src="/firma-abdias.svg" alt='juvalfri-sello' style={{width: "100px", height: 'auto', marginTop: '-5px'}} />
                            </div> */}
                                <div className='text-center' style={{ marginTop: '32px' }}>
                                    <p className='text-bold mb-0' style={{ fontSize: '8px' }}>__________________________________________________</p>
                                    <p className='text-bold mb-0' style={{ fontSize: '8px' }}>{admin}</p>
                                    <p className='text-bold mb-2' style={{ fontSize: '8px' }}>ADMINISTRADOR ÚNICO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

        if (layout === 2) {
            return (
                <>
                    <div style={{ width: '770px', border: 'solid 0.5px black', borderRadius: '5px', marginLeft: '23px' }}>
                        <div className='text-center'>
                            <p className='mb-0 text-bold' style={{ background: '#BAD7F2', color: '#000', fontSize: '12px', border: 'solid black 0.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>
                                Suministros
                            </p>
                        </div>
                        <div className='d-flex align-items-stretch'>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{ color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px' }}>&nbsp;&nbsp;No. CATALOGO: </p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px' }}>CANTIDAD</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px' }}>DESCRIPCIÓN</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>PRECIO UNITARIO</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>IMPORTE</p>
                        </div>
                        <div>
                            {refacciones.map(r => (
                                r.precioUnitario !== 0 ?
                                    <div className='d-flex' key={r.key}>
                                        <p className='mb-0 d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px' }}>&nbsp;&nbsp;{r.noCatalogo}</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px' }}>{r.cantidad}</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-center text-center' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px' }}>{r.refaccion}</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(r.precioUnitario)}`}&nbsp;&nbsp;</p>
                                        <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(r.precioUnitario * r.cantidad)}`}&nbsp;&nbsp;</p>
                                    </div> : <></>
                            ))}
                        </div>
                        {/* <div className='d-flex'>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-bold' style={{ color: '#000', fontSize: '10px', width: '120px', border: 'solid black 0.5px' }}>&nbsp;&nbsp;SERVICIO</p>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-center p-1' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px' }}>{servicio.descripcion}</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                        </div> */}
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '490px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>SUBTOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(cotizacion.subtotal)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '490px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>IVA</p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>{`$ ${formatMoney(cotizacion.iva)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '60px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', width: '490px' }}></p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px' }}>TOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{ color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px', borderBottomRightRadius: '4px' }}>{`$ ${formatMoney(cotizacion.total)}`}</p>
                        </div>
                    </div>

                    <div style={{ height: `${575 - height}px` }}></div>

                    <div style={{ height: '30px' }}>
                        {edit ? <></> : <img src="/bg-juvalfri.svg" alt='juvalfri-logo' style={{ position: 'absolute', top: '0px', left: '0px', width: "100%", height: 'auto' }} />}
                    </div>
                    <div style={{ position: 'absolute', top: '1136px', right: '23px', width: '240px', }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p className='mb-0' style={{ fontWeight: '600', fontSize: '14px' }}>
                                Cotización &nbsp;<span style={{ fontSize: '1.2em', fontWeight: '700' }}>{data.noReporte} </span> &nbsp; (continuación)
                            </p>
                            <p className='mb-0' style={{ fontWeight: '600', fontSize: '13px' }}>
                                CDMX &nbsp; | &nbsp;{getDate(data.fecha.cotizacion, 'dd/MM/yyyy')}
                            </p>
                        </div>
                    </div>

                    <div className='justify-content-center' style={{ marginLeft: '23px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', marginRight: '73px', fontSize: '12px' }} className='py-2'>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                <p style={{ width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black', borderTopLeftRadius: '5px' }} className='m-0 text-center text-bold'>SUBTOTAL:</p>
                                <p style={{ width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black' }} className='m-0 text-center text-bold'>IVA:</p>
                                <p style={{ width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black', borderRight: 'solid 0.5px black', borderTopRightRadius: '5px' }} className='m-0 text-center text-bold'>TOTAL:</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                <p style={{ width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black', borderBottom: 'solid 0.5px black' }} className='m-0 text-center text-bold'>{`$ ${formatMoney(cotizacion.subtotal)}`}</p>
                                <p style={{ width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black', borderBottom: 'solid 0.5px black' }} className='m-0 text-center text-bold'>{`$ ${formatMoney(cotizacion.iva)}`}</p>
                                <p style={{ width: '100px', border: 'solid 0.5px black' }} className='m-0 text-center text-bold'>{`$ ${formatMoney(cotizacion.total)}`}</p>
                            </div>
                        </div>
                        <hr className='my-2' style={{ width: '770px' }}></hr>
                        <div className='d-flex flex-row'>
                            <div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '170px', fontSize: '11px' }} className='my-1 px-1 d-flex align-items-center text-bold'>Términos y condiciones:</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='my-1 px-1 d-flex align-items-center'>Vigencia de la cotización:</p>
                                    <p style={{ fontSize: '11px' }} className='my-1 px-1 text-bold'>{cotizacion.terms.vigencia}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='mb-1 px-1 d-flex align-items-center'>Tiempo de entrega:</p>
                                    <p style={{ fontSize: '11px' }} className='mb-1 px-1 text-bold'>{cotizacion.terms.tiempoEntrega}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='mb-1 px-1 d-flex align-items-center'>Garantía:</p>
                                    <p style={{ fontSize: '11px' }} className='mb-1 px-1 text-bold'>{cotizacion.terms.garantia}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '500px', color: '#000' }}>
                                    <p style={{ width: '140px', fontSize: '11px' }} className='mb-1 px-1 d-flex align-items-center'>Forma de pago:</p>
                                    <p style={{ fontSize: '11px' }} className='mb-1 px-1 text-bold'>{cotizacion.terms.formaDePago}</p>
                                </div>
                            </div>

                            <div>
                                <div className='mx-3 text-center'>
                                    <img src="/sello-juvalfri.svg" alt='juvalfri-sello' style={{ width: "130px", height: 'auto' }} />
                                </div>
                                {/* <div className='mx-3 text-center'>
                                <img src="/firma-abdias.svg" alt='juvalfri-sello' style={{width: "100px", height: 'auto', marginTop: '-5px'}} />
                            </div> */}
                                <div className='text-center' style={{ marginTop: '32px' }}>
                                    <p className='text-bold mb-0' style={{ fontSize: '8px' }}>__________________________________________________</p>
                                    <p className='text-bold mb-0' style={{ fontSize: '8px' }}>{admin}</p>
                                    <p className='text-bold mb-2' style={{ fontSize: '8px' }}>ADMINISTRADOR ÚNICO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    useEffect(() => {
        if (data.refacciones) {
            setRenderHoja(
                <div id='printDiv' style={{ position: 'absolute', width: '816px', height: '1056px', background: '#FFF', zIndex: '-2' }} className='exo2 p-0'>
                    <div style={{ height: '150px' }}>
                        {edit ? <></> : <img src="/bg-juvalfri.svg" alt='juvalfri-logo' style={{ position: 'absolute', top: '0px', left: '0px', width: "100%", height: 'auto' }} />}
                    </div>

                    <div style={{ height: '20px' }}></div>

                    <div style={{ position: 'absolute', top: '80px', right: '23px', width: '200px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p className='mb-0' style={{ fontWeight: '600', fontSize: '14px' }}>
                                Cotización &nbsp;<span style={{ fontSize: '1.2em', fontWeight: '700' }}>{data.noReporte}</span>
                            </p>
                            <p className='mb-0' style={{ fontWeight: '600', fontSize: '13px' }}>
                                CDMX &nbsp; | &nbsp;{getDate(data.fecha.cotizacion, 'dd/MM/yyyy')}
                            </p>
                        </div>
                    </div>

                    <div style={{ width: '770px', marginLeft: '23px', fontStyle: 'italic', color: '#000', fontSize: '12px', marginTop: '12px' }} className='justify-content-between d-flex flex-row '>
                        <p className='mb-0 text-center'>
                            {'En respuesta a la solicitúd de cotización, se emite esta propuesta para su amable consideración:'}
                        </p>
                    </div>

                    <div className='justify-content-center' style={{ width: '770px', marginLeft: '23px' }}>
                        <hr className='my-3'></hr>
                        <div className='d-flex align-items-stretch' style={{ width: '640px', color: '#000' }}>
                            <p style={{ width: '120px', fontSize: '11px' }} className='my-0 px-1 d-flex align-items-center'>Empresa:</p>
                            <p style={{ fontSize: '11px' }} className='my-0 px-1 text-bold'>{data.hospital.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{ width: '640px', color: '#000' }}>
                            <p style={{ width: '120px', fontSize: '11px' }} className='mb-0 px-1 d-flex align-items-center'>Equipo:</p>
                            <p style={{ fontSize: '11px' }} className='mb-0 px-1 text-bold'>{data.equipo.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{ width: '640px', color: '#000' }}>
                            <p style={{ width: '120px', fontSize: '11px' }} className='mb-0 px-1 d-flex align-items-center'>Marca:</p>
                            <p style={{ fontSize: '11px' }} className='mb-0 px-1 text-bold'>{data.marca.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{ width: '640px', color: '#000' }}>
                            <p style={{ width: '120px', fontSize: '11px' }} className='mb-0 px-1 d-flex align-items-center'>Modelo:</p>
                            <p style={{ fontSize: '11px' }} className='mb-0 px-1 text-bold'>{data.modelo.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{ width: '640px', color: '#000' }}>
                            <p style={{ width: '120px', fontSize: '11px' }} className='mb-0 px-1 d-flex align-items-center'>No. Serie:</p>
                            <p style={{ fontSize: '11px' }} className='mb-0 px-1 text-bold'>{data.noSerie.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{ width: '640px', color: '#000' }}>
                            <p style={{ width: '120px', fontSize: '11px' }} className='mb-0 px-1 d-flex align-items-center'>Cliente:</p>
                            <p style={{ fontSize: '11px' }} className='mb-0 px-1 text-bold'></p>
                        </div>
                    </div>

                    <div style={{ marginTop: '24px' }}>
                        {getSuministros(refacciones, servicio, cotizacion)}
                    </div>
                </div>
            )
            setFlag(true);
        }
    }, [data, refacciones, servicio, cotizacion, layout, edit])

    return (
        <div style={{ background: '#FFF' }} className='exo2'>
            <div style={{ marginTop: '10px', marginBottom: '12px' }}>
                <Button variant='contained' color='info' onClick={() => props.history.goBack()} className='mx-3'>
                    Regresar &nbsp; <i className="fad fa-arrow-circle-left" style={{ transform: 'scale(1.3)' }}></i>
                </Button>
                <Button variant='contained' color='secondary' onClick={printDocument}>Imprimir hoja &nbsp; <i className="fas fa-print" style={{ transform: 'scale(1.3)' }}></i></Button>
                <Button className='mx-2' variant='contained' color='secondary' onClick={() => setEdit(edit => !edit)}>{edit ? 'Finalizar edición' : 'Editar documento'} &nbsp; <i className="fas fa-print" style={{ transform: 'scale(1.3)' }}></i></Button>
            </div>
            {renderHoja}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(HojaCotizacion);