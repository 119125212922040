import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/authActions";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import './styles.css';

import {
    Box, Divider, Typography, Grid, Container, Button, FormControl, InputLabel, MenuItem, Select,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Autocomplete, TextField, ListItem,
    ListItemButton, Fab
} from '@mui/material';

import { dropListaCotizacion } from '../../../utils/functions/UtilsJuvalfri';
import { formatMoney } from '../../../utils/functions/UtilsJuvalfri';
import CustomMessage from '../../../utils/components/CustomMessage';
import CustomChip from '../../../utils/components/CustomChip';

// Styled components
import styled from 'styled-components';

const TextBold = styled.p`
  font-weight: 700;
  margin: 0px;
`

function Cotizacion(props) {

    const [data, setData] = useState([]);
    const [renderData, setRenderData] = useState(<></>);
    const [renderMenu, setRenderMenu] = useState(<></>);
    const [tipoEquipo, setTipoEquipo] = useState('Tipo de Equipo');
    const [listaPrecios, setListaPrecios] = useState([{ noCatalogo: '1', refaccion: 'TRANSDUCTOR DE ULTRASONIDO', precioUnitario: '1050.00' }]);
    const [refacciones, setRefacciones] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [renderModal, setRenderModal] = useState(<></>);
    const [modalEdit, setModalEdit] = useState(false);
    const [currentNoCatalogo, setCurrentNoCatalogo] = useState(0);
    const [currentRef, setCurrentRef] = useState({});
    const [currentCantidad, setCurrentCantidad] = useState(1);
    const [renderRefaaciones, setRenderRefacciones] = useState(<></>);
    const [currentEditRef, setCurrentEditRef] = useState({});
    const [cotizacion, setCotizacion] = useState({});
    const [customFlag, setCustomFlag] = useState(false);
    const [customRefaccion, setCustomRefaccion] = useState({ noCatalogo: '', refaccion: '', precioUnitario: 0 });
    const [servicioFlag, setServicioFlag] = useState(false);
    const [servicio, setServicio] = useState({ descripcion: '', horas: 5, costoHora: 940, importe: 0 });
    const [terms, setTerms] = useState({ vigencia: '60 días naturales', tiempoEntrega: '35 días hábiles', garantia: '60 días naturales', formaDePago: 'Las estipuladas por la secretaría' })
    const [renderTerms, setRenderTerms] = useState(<></>);
    const [admin, setAdmin] = useState('Ing. Abdias Lopez Reyes');
    const [autocompleteRef, setAutocompleteRef] = useState('');
    const [apiRes, setApiRes] = useState({ show: false, message: '', severity: '' });

    let { id } = useParams();

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show } })
    }

    useEffect(() => {
        if (props.auth.user.rol !== 'Admin') {
            props.history.push("/dashboard");
        }
        document.title = 'Cotización';
    }, [props.auth.user])

    // Funcion para obtener los datos de la cotizacion
    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            let res;
            if (props.location.state !== undefined) {
                res = await axios.get(`/api/reportes/${props.location.state.id}`);
            } else {
                res = await axios.get(`/api/reportes/${id}`);
            }
            if (res.status === 200) {
                setData(res.data);
                document.title = 'Cotización ' + res.data.noReporte;
                if (res.data.refacciones) {
                    setRefacciones(res.data.refacciones);
                }
                if (res.data.cotizacion) {
                    setCotizacion(res.data.cotizacion);
                    setTerms(res.data.cotizacion.terms);
                }
                if (res.data.listaPrecios) {
                    setTipoEquipo(dropListaCotizacion[Number(res.data.listaPrecios) - 1]);
                }
                if (res.data.servicio) {
                    setServicio(res.data.servicio);
                }
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return { ...state, show: true, message: 'Ocurrió un error al cargar los datos del reporte, favor de intentarlo mas tarde', severity: 'error' } });
        }
    }

    useEffect(() => {
        if (tipoEquipo !== 'Tipo de Equipo') {
            getRefList(tipoEquipo);
        }
    }, [tipoEquipo])

    const getRefList = async (tipoEquipo) => {
        const index = dropListaCotizacion.indexOf(tipoEquipo) + 1;
        try {
            const res = await axios.get(`/api/refacciones?type=${index}`)
            if (res.status === 200) {
                setListaPrecios(res.data);
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return { ...state, show: true, message: 'Ocurrió un error al obtener la lista de precios, favor de intentarlo mas tarde', severity: 'error' } });
        }
    }

    const handleAgregarRefaccion = () => {
        setCustomFlag(false);
        setModalEdit(false);
        setModalShow(true);
        setServicioFlag(false);
    }

    const searchRef = (r) => {
        if (listaPrecios.find(x => x.noCatalogo === Number(r))) {
            setCurrentRef(listaPrecios.find(x => x.noCatalogo === Number(r)));
        }
    }

    const handleAddRefaccion = (x) => {
        if (x.length > 0) {
            x.forEach(d => {
                d.cantidad = 1;
                d.key = getUuid();
                if (d.refaccion) {
                    setRefacciones(refacciones => [...refacciones, {
                        noCatalogo: d.noCatalogo,
                        refaccion: d.refaccion.toUpperCase(),
                        precioUnitario: d.precioUnitario,
                        cantidad: d.cantidad,
                        key: d.key
                    }]);
                }
            })
        }
        setCurrentNoCatalogo(0);
        setCurrentCantidad(1);
        setCurrentRef({});
        setCustomFlag(false);
        setModalShow(false);
        setCustomRefaccion({ noCatalogo: '', refaccion: '', precioUnitario: 0 });
        setAutocompleteRef('');
    }

    const getUuid = () => { return uuidv4() };

    const handleDelete = (d, r) => {
        if (r < 2) {
            setRefacciones([]);
        } else {
            setRefacciones(refacciones.filter(x => x.key !== d));
        }
    }

    const handleSave = async (x) => {
        try {
            const res = await axios.post(`/api/reportes/updateCotizacion/${data._id}`, { refacciones: refacciones, listaPrecios: dropListaCotizacion.indexOf(x) + 1, servicio: servicio, cotizacion: cotizacion })
            if (res.status === 200) {
                setApiRes(state => { return { ...state, show: true, message: 'Cotización actualizada', severity: 'success' } });
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return { ...state, show: true, message: 'Ocurrió un error al actualizar la cotización, favor de intentarlo mas tarde', severity: 'error' } });
        }
    }

    const handleEdit = (d) => {
        setModalEdit(true);
        setModalShow(true);
        setCurrentEditRef(d);
    }

    useEffect(() => {
        updateCotizacion(refacciones, servicio, terms);
    }, [refacciones, servicio, terms])

    const updateCotizacion = (d, r, s) => {
        let subtotal = d.reduce(function (tot, arr) {
            return tot + arr.precioUnitario * arr.cantidad;
        }, 0);

        if (r.importe && Number(r.importe) !== 0) {
            subtotal += Number(r.importe);
        }

        const iva = subtotal * 0.16;
        const total = subtotal + iva;

        setCotizacion({ subtotal: subtotal, iva: iva, total: total, descripcion: r.descripcion, terms: s });
    }

    const handleUpdateRefaccion = (d, e) => {
        setModalShow(false);
        setRefacciones(refacciones.map(r => r.key === e ? d : r));
    }

    const getRefaccionesRegex = (r) => {
        let tmp = [];
        let tmp2 = '';
        r.forEach(r => {
            tmp2 = r.refaccion;
            tmp.push(tmp2);
        })
        return tmp;
    }

    const handleEditServicio = (srv) => {
        setServicio(srv);
        setServicio(state => { return { ...state, importe: srv.costoHora * srv.horas } })
        handleCloseModal();
    }

    const handleCloseModal = () => {
        setModalShow(false);
        setCustomFlag(false);
        setModalEdit(false);
        setServicioFlag(false);
    }

    // Efectos para renderizar
    // Render datos y menu principal
    useEffect(() => {
        if (data._id) {
            setRenderData(
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>
                                <TextBold># Reporte:</TextBold>
                                <p className='mb-0 px-2'>{data.noReporte}</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <TextBold>Equipo:</TextBold>
                                <p className='mb-0 px-2'>{data.equipo}</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <TextBold>Marca:</TextBold>
                                <p className='mb-0 px-2'>{data.marca}</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <TextBold>Modelo:</TextBold>
                                <p className='mb-0 px-2'>{data.modelo}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>
                                <TextBold>No. Serie:</TextBold>
                                <p className='mb-0 px-2'>{data.noSerie}</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <TextBold>No. Inventario:</TextBold>
                                <p className='mb-0 px-2'>{data.noInventario}</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <TextBold>Unidad Hospitalaria:</TextBold>
                                <p className='mb-0 px-2'>{data.hospital}</p>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <TextBold>Área:</TextBold>
                                <p className='mb-0 px-2'>{data.area}</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )
            setRenderMenu(
                <>
                    <div className='d-flex text-center justify-content-around' style={{ flexDirection: 'row', flexWrap: 'wrap', flexFlow: 'row wrap', alignContent: 'flex-end' }}>
                        <FormControl sx={{ minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Tipo de Equipo</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={tipoEquipo}
                                size='small'
                                onChange={(e) => setTipoEquipo(e.target.value)}
                                autoWidth
                                label="Tipo de Equipo"
                            >
                                {dropListaCotizacion.map(list => (
                                    <MenuItem key={list} value={list}>{list}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button variant='contained' color='secondary' className='m-1' onClick={() => handleAgregarRefaccion()}>Agregar Refacción &nbsp;&nbsp;<i className='fad fa-cogs' style={{ transform: 'scale(1.3)' }}></i></Button>
                        <Button variant='contained' color='secondary' className='m-1' onClick={() => setServicioFlag(true)}>Servicio &nbsp;&nbsp;<i className='fad fa-tools' style={{ transform: 'scale(1.3)' }}></i></Button>
                        <Link to={{ pathname: '/reportes/cotizacion/hojaCotizacion', state: { data: data, servicio: servicio, refacciones: refacciones, cotizacion: cotizacion, admin: admin } }} style={{ textDecoration: 'none' }}>
                            <Button variant='contained' color='info' className='m-1'>Imprimir Cotización &nbsp;&nbsp;<i className='fad fa-file-invoice-dollar' style={{ transform: 'scale(1.3)' }}></i></Button>
                        </Link>
                        <Link to={{ pathname: '/reportes/cotizacion/hojaCotizacion/sesderma', state: { data: data, servicio: servicio, refacciones: refacciones, cotizacion: cotizacion, admin: admin } }} style={{ textDecoration: 'none' }}>
                            <Button variant='contained' color='info' className='m-1'>Cotización Sesderma &nbsp;&nbsp;<i className='fad fa-file-invoice-dollar' style={{ transform: 'scale(1.3)' }}></i></Button>
                        </Link>
                        <Link target='_blank' to={{ pathname: '/reportes/ordenServicio/' + data._id, state: { id: data._id } }} style={{ textDecoration: 'none' }}>
                            <Button variant='contained' color='info' className='m-1'>Orden de Servicio &nbsp;&nbsp;<i className='fad fa-file-invoice' style={{ transform: 'scale(1.3)' }}></i></Button>
                        </Link>
                    </div>
                </>
            )
        }
    }, [data, tipoEquipo, listaPrecios, servicio, cotizacion, refacciones, admin])

    // Render modal de agregar refacciones
    useEffect(() => {
        if (tipoEquipo === 'Tipo de Equipo') {
            setRenderModal(<div>
                <p className='text-bold my-3'>Debe seleccionar un tipo de equipo en el boton de la izquierda</p>
            </div>)
        } else if (!modalEdit && !servicioFlag) {
            let lista = []
            if (currentRef.length > 0) {
                currentRef.forEach(ref => {
                    if (ref.indexOf(' ‣') > -1) {
                        lista.push(listaPrecios.find(r => r.noCatalogo === Number(ref.split(' ‣')[0])))
                    } else {
                        lista.push({ noCatalogo: null, refaccion: ref, precioUnitario: 0 })
                    }
                })
            }
            setRenderModal(<div style={{ minHeight: '400px' }}>
                <FormControl sx={{ m: 1, minWidth: 100 }} style={{ display: 'flex' }}>
                    <Autocomplete
                        disablePortal
                        freeSolo={true}
                        onInputChange={(event, newInputValue) => setAutocompleteRef(newInputValue)}
                        inputValue={autocompleteRef.refaccion}
                        onChange={(event, newInputValue) => setCurrentRef(newInputValue)}
                        value={currentRef.refaccion}
                        multiple={true}
                        id="combo-box-demo"
                        options={listaPrecios.map((option) => `${option.noCatalogo} ‣ ${option.refaccion}`)}
                        renderInput={(params) => <TextField {...params} label='Refaccion' />}
                    />
                </FormControl>
                <hr className='my-1' />
                <Grid container spacing={1} style={{ borderBottom: 'solid 1px black' }} className='mb-2 pb-2'>
                    <Grid item xs={12} sm={2}>
                        <p className='mb-0 text-bold text-center'># Catalogo</p>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <p className='mb-0 text-bold text-center'>Refacción</p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <p className='mb-0 text-bold text-center'>Precio Unitario</p>
                    </Grid>
                </Grid>
                {
                    lista.length > 0 ? lista.map((ref, i) => (
                        <Grid container spacing={1} key={i}>
                            <Grid item xs={12} sm={2}>
                                <p className='mb-0 text-center'>{ref.noCatalogo}</p>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <p className='mb-0 text-center'>{ref.refaccion}</p>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <p className='mb-0 text-center'>{`$ ${formatMoney(ref.precioUnitario)}`}</p>
                            </Grid>
                        </Grid>
                    )) : <div>No ha selecionado ninguna refaccion</div>
                }
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                        {lista.length > 0 ?
                            <Button variant='contained' color='secondary' className='m-1 p-2' onClick={() => handleAddRefaccion(lista)}>Agregar Refacciones &nbsp;&nbsp;<i className='fad fa-cogs' style={{ transform: 'scale(1.3)' }}></i></Button>
                            : <Button variant='contained' disabled color='secondary' className='m-1 p-2' onClick={() => handleAddRefaccion(lista)}>Agregar Refacciones &nbsp;&nbsp;<i className='fad fa-cogs' style={{ transform: 'scale(1.3)' }}></i></Button>}
                    </Grid>
                </Grid>
            </div>)
        }

    }, [tipoEquipo, currentNoCatalogo, currentRef, currentCantidad, modalEdit, customFlag, customRefaccion, servicioFlag, listaPrecios, autocompleteRef])

    // Render cotizacion 
    useEffect(() => {
        if (refacciones.length === 0 && !servicio.descripcion) {
            setRenderRefacciones(
                <>
                    <div>
                        <p className='text-center h5 my-3'>No hay refacciones ni servicios agregados</p>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button variant='contained' color='secondary' className='m-1' onClick={() => handleSave(tipoEquipo)}>Guardar &nbsp;&nbsp; <i className='fad fa-save' style={{ transform: 'scale(1.3)' }}></i></Button>
                    </div>
                </>);
        } else {
            setRenderRefacciones(
                <>
                    <ListItem disablePadding style={{ borderBottom: 'solid #CFD8DC 0.5px' }}>
                        <ListItemButton>
                            <Grid container spacing={1}>
                                <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>#</p>
                                </Grid>
                                <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>Cant.</p>
                                </Grid>
                                <Grid item sm={4} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>Concepto</p>
                                </Grid>
                                <Grid item sm={2} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>Precio Unitario</p>
                                </Grid>
                                <Grid item sm={2} className='d-flex align-items-center justify-content-end'>
                                    <p className='mb-0 text-bold'>Importe</p>
                                </Grid>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                    {refacciones.length > 0 ? refacciones.map(r => (
                        <ListItem key={r.key} disablePadding style={{ borderBottom: 'solid #CFD8DC 0.5px' }}>
                            <ListItemButton onDoubleClick={() => handleEdit(r)}>
                                <Grid container spacing={1}>
                                    <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                        <p className='mb-0'>{r.noCatalogo}</p>
                                    </Grid>
                                    <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                        <p className='mb-0'>{r.cantidad}</p>
                                    </Grid>
                                    <Grid item sm={4} className='d-flex align-items-center justify-content-center'>
                                        <p className='mb-0 text-center'>{r.refaccion}</p>
                                    </Grid>
                                    <Grid item sm={2} className='d-flex align-items-center justify-content-center'>
                                        <p className='mb-0'>{`$ ${formatMoney(r.precioUnitario)}`}</p>
                                    </Grid>
                                    <Grid item sm={2} className='d-flex align-items-center justify-content-end'>
                                        <p className='mb-0'>{`$ ${formatMoney(r.precioUnitario * r.cantidad)}`}</p>
                                    </Grid>
                                    <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                        <Button variant='contained' color='info' className='m-1' onClick={() => handleEdit(r)}>Editar</Button>
                                    </Grid>
                                    <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                        <Button variant='contained' color='error' className='m-1' onClick={() => handleDelete(r.key, refacciones.length)}>Eliminar</Button>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )) : null
                    }
                    {
                        servicio.descripcion && servicio.descripcion !== '' ?
                            <ListItem disablePadding style={{ borderBottom: 'solid #CFD8DC 0.5px' }}>
                                <ListItemButton onDoubleClick={() => setServicioFlag(true)}>
                                    <Grid container spacing={1}>
                                        <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                            <p className='mb-0'>Servicio</p>
                                        </Grid>
                                        <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                            <p className='mb-0'>{servicio.horas} hrs.</p>
                                        </Grid>
                                        <Grid item sm={4} className='d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 text-center'>{servicio.descripcion}</p>
                                        </Grid>
                                        <Grid item sm={2} className='d-flex align-items-center justify-content-center'>
                                            <p className='mb-0'>{`$ ${formatMoney(servicio.costoHora)}`}</p>
                                        </Grid>
                                        <Grid item sm={2} className='d-flex align-items-center justify-content-end'>
                                            <p className='mb-0'>{`$ ${formatMoney(servicio.importe)}`}</p>
                                        </Grid>
                                        <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                            <Button variant='contained' color='info' className='m-1' onClick={() => setServicioFlag(true)}>Editar</Button>
                                        </Grid>
                                        <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                            <Button variant='contained' color='error' className='m-1' onClick={() => setServicio({ descripcion: '', horas: 5, costoHora: 940, importe: 0 })}>Eliminar</Button>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem> : null
                    }
                    {refacciones.length > 0 || (servicio.descripcion && servicio.descripcion) !== '' ?
                        <div>
                            <ListItem disablePadding style={{ borderBottom: 'solid #CFD8DC 0.5px' }}>
                                <ListItemButton>
                                    <Grid container spacing={1}>
                                        <Grid item sm={5} className='d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 text-bold'></p>
                                        </Grid>
                                        <Grid item sm={3} className='d-flex align-items-center justify-content-end'>
                                            <p className='mb-0 text-bold'>Subtotal</p>
                                        </Grid>
                                        <Grid item sm={2} className='d-flex align-items-center justify-content-end'>
                                            <p className='mb-0 text-bold'>{`$ ${formatMoney(cotizacion.subtotal)}`}</p>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding style={{ borderBottom: 'solid #CFD8DC 0.5px' }}>
                                <ListItemButton>
                                    <Grid container spacing={1}>
                                        <Grid item sm={5} className='d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 text-bold'></p>
                                        </Grid>
                                        <Grid item sm={3} className='d-flex align-items-center justify-content-end'>
                                            <p className='mb-0 text-bold'>IVA</p>
                                        </Grid>
                                        <Grid item sm={2} className='d-flex align-items-center justify-content-end'>
                                            <p className='mb-0 text-bold'>{`$ ${formatMoney(cotizacion.iva)}`}</p>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <Grid container spacing={1}>
                                        <Grid item sm={5} className='d-flex align-items-center justify-content-center'>
                                            <p className='mb-0 text-bold'></p>
                                        </Grid>
                                        <Grid item sm={3} className='d-flex align-items-center justify-content-end'>
                                            <p className='mb-0 text-bold'>TOTAL</p>
                                        </Grid>
                                        <Grid item sm={2} className='d-flex align-items-center justify-content-end'>
                                            <p className='mb-0 text-bold'>{`$ ${formatMoney(cotizacion.total)}`}</p>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <br />
                            <div className='d-flex justify-content-end'>
                                <Button variant='contained' color='secondary' className='m-1' onClick={() => handleSave(tipoEquipo)}>Guardar &nbsp;&nbsp; <i className='fad fa-save' style={{ transform: 'scale(1.3)' }}></i></Button>
                            </div>
                        </div> : null
                    }
                </>
            )
        }
    }, [refacciones, tipoEquipo, cotizacion, servicio])

    //Render Modal para editar refacciones
    useEffect(() => {
        if (modalEdit) {
            if (currentEditRef) {
                setRenderModal(
                    <div>
                        <Grid container spacing={1}>
                            <Grid item sm={12} md={2}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{ display: 'flex' }}>
                                    <TextField id="outlined-basic" label="No. Catalogo" variant="outlined" value={currentEditRef.noCatalogo} onChange={(e) => setCurrentEditRef(state => { return { ...state, noCatalogo: e.target.value } })} />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} md={7}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{ display: 'flex' }}>
                                    <TextField id="outlined-basic" label="Concepto" variant="outlined" multiline value={currentEditRef.refaccion} onChange={(e) => setCurrentEditRef(state => { return { ...state, refaccion: e.target.value } })} />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} md={1}>
                                <FormControl sx={{ m: 1 }} style={{ display: 'flex' }}>
                                    <TextField id="outlined-basic" label="Cantidad" variant="outlined" value={currentEditRef.cantidad} onChange={(e) => setCurrentEditRef(state => { return { ...state, cantidad: Number(e.target.value) } })} />
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} md={2}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{ display: 'flex' }}>
                                    <TextField id="outlined-basic" label="Precio unitario" variant="outlined" value={currentEditRef.precioUnitario} onChange={(e) => setCurrentEditRef(state => { return { ...state, precioUnitario: Number(e.target.value) } })} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item xs={12} sm={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                            <Button variant='contained' color='secondary' className='m-1 p-2' onClick={() => handleUpdateRefaccion(currentEditRef, currentEditRef.key)}>Actualizar &nbsp;&nbsp;<i className='fad fa-cogs' style={{ transform: 'scale(1.3)' }}></i></Button>
                        </Grid>
                    </div>
                )
            }
        }
    }, [modalEdit, currentEditRef])

    //Render Modal para agregar o editar el servicio
    useEffect(() => {
        if (servicioFlag) {
            setModalShow(true);
            let tmp = '';
            let tmp2 = getRefaccionesRegex(refacciones);
            if (refacciones.length > 0 && (servicio.descripcion === '' || !servicio.descripcion)) {
                refacciones.forEach((r, i) => {
                    if (r.precioUnitario === 0) {
                        tmp += `${r.cantidad < 10 ? '0' + String(r.cantidad) : r.cantidad} ${tmp2[i]}, `;
                    } else if ((r.refaccion.indexOf('REPARACION') !== -1) || (r.refaccion.indexOf('REPARACIÓN') !== -1) ||
                        (r.refaccion.indexOf('FABRICACION') !== -1) || (r.refaccion.indexOf('FABRICACIÓN') !== -1) ||
                        (r.refaccion.indexOf('CAMBIO') !== -1) || (r.refaccion.indexOf('REEMPLAZO') !== -1) ||
                        (r.refaccion.indexOf('MANTENIMIENTO') !== -1) || (r.refaccion.indexOf('AJUSTE') !== -1) || (r.refaccion.indexOf('AJUSTES') !== -1) ||
                        (r.refaccion.indexOf('ADAPTACION') !== -1) || (r.refaccion.indexOf('ADAPTACIÓN') !== -1)) {
                        tmp += `${r.cantidad < 10 ? '0' + String(r.cantidad) : r.cantidad} ${tmp2[i]}, `;
                    } else {
                        tmp += `SUMINISTRO Y COLOCACIÓN DE ${r.cantidad < 10 ? '0' + String(r.cantidad) : r.cantidad} ${tmp2[i]}, `;
                    }
                })
                tmp += 'LIMPIEZA GENERAL Y PRUEBAS DE BUEN FUNCIONAMIENTO.';
                tmp = tmp.replace(",.", ",");
                tmp = tmp.replace(", .", ",");
                tmp = tmp.replace(".,", ",");
                tmp = tmp.replace(". ,", ",");
                tmp = tmp.replace(",,", ",");
                tmp = tmp.replace(", ,", ",");
                tmp = tmp.replace(' ,', ",");
                tmp = tmp.replace("  ", " ");
                setServicio(state => { return { ...state, descripcion: tmp } });
            }
            setRenderModal(
                <div>
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={8}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{ display: 'flex' }}>
                                <TextField id="outlined-basic" label="Descripción" variant="outlined" multiline value={servicio.descripcion} onChange={(e) => setServicio(state => { return { ...state, descripcion: e.target.value } })} />
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{ display: 'flex' }}>
                                <TextField id="outlined-basic" label="Horas" variant="outlined" value={servicio.horas} onChange={(e) => setServicio(state => { return { ...state, horas: Number(e.target.value) } })} />
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={2}>
                            <FormControl sx={{ m: 1 }} style={{ display: 'flex' }}>
                                <TextField id="outlined-basic" label="Precio por hora" variant="outlined" value={servicio.costoHora} onChange={(e) => setServicio(state => { return { ...state, costoHora: Number(e.target.value) } })} />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12} sm={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                        <Button variant='contained' color='secondary' className='m-1 p-2' onClick={() => handleEditServicio(servicio)}>Actualizar &nbsp;&nbsp;<i className='fad fa-cogs' style={{ transform: 'scale(1.3)' }}></i></Button>
                    </Grid>
                </div>
            )
        }
    }, [servicioFlag, servicio, refacciones])

    //Render terminos y condiciones
    useEffect(() => {
        setRenderTerms(
            <div>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={3}>
                        <FormControl sx={{ m: 1, minWidth: 100 }} style={{ display: 'flex' }}>
                            <TextField id="outlined-basic" label="Administrador Único" variant="outlined" value={admin} onChange={(e) => setAdmin(e.target.value)} />
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={2}>
                        <FormControl sx={{ m: 1, minWidth: 100 }} style={{ display: 'flex' }}>
                            <TextField id="outlined-basic" label="Vigencia" variant="outlined" value={terms.vigencia} onChange={(e) => setTerms(state => { return { ...state, vigencia: e.target.value } })} />
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={2}>
                        <FormControl sx={{ m: 1 }} style={{ display: 'flex' }}>
                            <TextField id="outlined-basic" label="Tiempo de entrega" variant="outlined" value={terms.tiempoEntrega} onChange={(e) => setTerms(state => { return { ...state, tiempoEntrega: e.target.value } })} />
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={2}>
                        <FormControl sx={{ m: 1 }} style={{ display: 'flex' }}>
                            <TextField id="outlined-basic" label="Garantía" variant="outlined" value={terms.garantia} onChange={(e) => setTerms(state => { return { ...state, garantia: e.target.value } })} />
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={3}>
                        <FormControl sx={{ m: 1 }} style={{ display: 'flex' }}>
                            <TextField id="outlined-basic" label="Forma de pago" variant="outlined" value={terms.formaDePago} onChange={(e) => setTerms(state => { return { ...state, formaDePago: e.target.value } })} />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        )
    }, [terms, admin])

    return (
        <Container maxWidth="xl">
            <Box className='text-center' >
                <Typography variant='h5' style={{ fontWeight: 700 }}>Cotización</Typography>
            </Box>
            <Divider className='my-2' textAlign="left">
                <CustomChip label='Datos del equipo' variant={'outlined'} color='#842033' borderColor='#842033' className='text-bold' />
            </Divider>
            {renderData}
            <Divider className='my-3' textAlign="left">
                <CustomChip label='Acciones' variant={'outlined'} color='#842033' borderColor='#842033' className='text-bold' />
            </Divider>
            {renderMenu}
            <Divider className='my-3' >
                <CustomChip label='Cotización' variant={'outlined'} color='#842033' borderColor='#842033' className='text-bold' />
            </Divider>
            {renderRefaaciones}
            <Divider className='my-2' textAlign="left">
                <CustomChip label='Términos y condiciones' variant={'outlined'} color='#842033' borderColor='#842033' className='text-bold' />
            </Divider>
            {renderTerms}
            <br></br>

            {/* Modal - Dialog render */}
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={modalShow}
                className='exo2'
                scroll='paper'
                onClose={() => handleCloseModal()}
            >
                <DialogTitle >{servicioFlag ? 'Agregar/Editar Servicio' : modalEdit ? 'Editar Refacción' : 'Agregar Refacción'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {servicioFlag ? 'Agregar o editar el texto del servicio, el costo y las horas de servicio' : modalEdit ? 'Aqui puedes editar el concepto o el precio de una refacción' : 'Escribe en el campo de abajo el nombre de la refacción o el numero de catalogo'}
                    </DialogContentText>
                    {renderModal}
                </DialogContent>
                <DialogActions>
                    <Button color='warning' variant='outlined' onClick={() => handleCloseModal()}>Cerrar</Button>
                </DialogActions>
            </Dialog>

            <Fab aria-label="add" onClick={() => handleSave(tipoEquipo)} style={{ position: 'fixed', top: '60px', left: '12px', zIndex: '10', backgroundColor: '#66bb6a', color: '#FFF' }}>
                <i className="fad fa-save fa-2x"></i>
            </Fab>
            <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />
        </Container>

    )
}

Cotizacion.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Cotizacion);