import React, { useEffect } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import CustomCard from '../../utils/components/CustomCard';
import { Box, Divider, Typography, Grid } from '@mui/material';

function Dashboard(props) {

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
          document.title = 'Menú Principal';
    }, [props.auth.user])

    return (
        <div className='container' style={{marginTop: '10px'}}>
            <Box className='text-center' >
                <Typography variant='h5' style={{fontWeight: 700}}>Menú Principal</Typography>
            </Box>
            <Divider variant="middle" className='my-3'/>
            <Grid container spacing={3}>
                {cards.map((card, i) => (
                    <Grid item xs key={i}>
                        <CustomCard 
                            title={card.title} 
                            to={card.to} 
                            button={card.button} 
                            cardIco={card.cardIco}
                            text={card.text}
                        />
                    </Grid>    
                ))}
            </Grid>
            <br></br>
        </div>
    )
}

const cards = [
    {
        title: "Reportes",
        text: "Aquí puedes crear reportes, cotizaciones y hojas de servicio, así como ver la tabla general de reportes",
        button: "Reportes",
        cardIco: "fad fa-file-medical-alt fa-3x",
        to: "/reportes"
    },
    {
        title: "Facturas",
        text: "Aquí puedes crear nuevas facturas y visualizar todas las facturas creadas.",
        button: "Facturas",
        cardIco: "fad fa-file-invoice-dollar fa-3x",
        to: "/facturas"
    },
    {
        title: "Lista de precios",
        text: "Listado de refacciones con precios para editar o agregar nuevos conceptos.",
        button: "Refacciones",
        cardIco: "fad fa-file-alt fa-3x",
        to: "/listaPrecios"
    },
    {
        title: "Contratos",
        text: "Lista de contratos para editar o agregar nuevos contratos.",
        button: "Contratos",
        cardIco: "fad fa-file-signature fa-3x",
        to: "/contratos"
    },
    {
        title: "Estadisticas",
        text: "Estadísticas de reportes, avance de contrato y facturación.",
        button: "Estadisticas",
        cardIco: "fad fa-analytics fa-3x",
        to: "/estadisticas"
    }
]

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps,{ logoutUser })(Dashboard);