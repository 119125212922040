import React from 'react';
import Chip from '@mui/material/Chip';

export default function CustomChip(props) {

    const stl = estatus => {
        switch(estatus) {
            case 'PENDIENTE': return { backgroundColor: '#ffee58', color: '#000' };
            case 'DIAGNOSTICADO': return { backgroundColor: '#42a5f5', color: '#000' };
            case 'EN ESPERA DE AUTORIZACION': return { backgroundColor: '#ffa726', color: '#000' };
            case 'AUTORIZADO': return { backgroundColor: '#ec407a', color: '#FFF' };
            case 'EN REPARACIÓN': return { backgroundColor: '#ab47bc', color: '#FFF' };
            case 'ATENDIDO': return { backgroundColor: '#26a69a', color: '#FFF' };
            case 'FACTURADO': return { backgroundColor: '#66bb6a', color: '#FFF' };
            case 'CANCELADO': return { backgroundColor: '#ef5350', color: '#FFF' };
            case 'EN GARANTÍA': return { backgroundColor: '#757575', color: '#FFF' };
            case 'BAJA DE EQUIPO': return { backgroundColor: '#8d6e63', color: '#FFF' };
            case 'PENDIENTE DE FIRMAS': return { backgroundColor: '#ffee58', color: '#000' };
            case 'RECIBIDA POR EL HOSPITAL': return { backgroundColor: '#42a5f5', color: '#000' };
            case 'FIRMADA': return { backgroundColor: '#ffa726', color: '#000' };
            case 'EN REVISIÓN PARA PAGO': return { backgroundColor: '#ec407a', color: '#FFF' };
            case 'EN ESPERA DE PAGO': return { backgroundColor: '#ab47bc', color: '#FFF' };
            case 'PAGADA': return { backgroundColor: '#66bb6a', color: '#FFF' };
            case 'CANCELADA': return { backgroundColor: '#ef5350', color: '#FFF' };
            case 'EN REVISION POR ERRORES': return { backgroundColor: '#757575', color: '#FFF' };
            default: return {}
        }
    }
    const stl2 = estatus => {
        switch(estatus) {
            case 'PENDIENTE': return { borderColor: '#ffee58', color: '#000' };
            case 'DIAGNOSTICADO': return { borderColor: '#42a5f5', color: '#000' };
            case 'EN ESPERA DE AUTORIZACION': return { borderColor: '#ffa726', color: '#000' };
            case 'AUTORIZADO': return { borderColor: '#ec407a', color: '#000' };
            case 'EN REPARACIÓN': return { borderColor: '#ab47bc', color: '#000' };
            case 'ATENDIDO': return { borderColor: '#26a69a', color: '#000' };
            case 'FACTURADO': return { borderColor: '#66bb6a', color: '#000' };
            case 'CANCELADO': return { borderColor: '#ef5350', color: '#000' };
            case 'EN GARANTÍA': return { borderColor: '#757575', color: '#000' };
            case 'BAJA DE EQUIPO': return { borderColor: '#8d6e63', color: '#000' };
            case 'PENDIENTE DE FIRMAS': return { borderColor: '#ffee58', color: '#000' };
            case 'RECIBIDA POR EL HOSPITAL': return { borderColor: '#42a5f5', color: '#000' };
            case 'FIRMADA': return { borderColor: '#ffa726', color: '#000' };
            case 'EN REVISIÓN PARA PAGO': return { borderColor: '#ec407a', color: '#000' };
            case 'EN ESPERA DE PAGO': return { borderColor: '#ab47bc', color: '#000' };
            case 'PAGADA': return { borderColor: '#66bb6a', color: '#000' };
            case 'CANCELADA': return { borderColor: '#ef5350', color: '#000' };
            case 'EN REVISION POR ERRORES': return { borderColor: '#757575', color: '#000' };
            default: return {}
        }
    }

    return (
        <>
            {props.variant === 'outlined' ? 
                <Chip
                    size={props.size}
                    label={props.label}
                    className={props.className}
                    style={props.estatus ? stl2(props.estatus) : { borderColor: props.borderColor, color: props.color }}
                    variant={props.variant}
                />
                : <Chip
                    size={props.size}
                    label={props.label}
                    className={props.className}
                    style={props.estatus ? stl(props.estatus) : { backgroundColor: props.backgroundColor, color: props.color }}
                    variant={props.variant}
                    />
            }
        </>
    );
}