import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Row, Col, Button, Form} from 'react-bootstrap';
import { connect } from 'react-redux';
// import './styles.css';
import styled from 'styled-components';

import { Container, Box, Typography } from '@mui/material'

import { getDate, formatMoney } from '../../utils/functions/UtilsJuvalfri';

const StyledPageBreak = styled.div`
    @media print {
        height: ${props => props.height}
    }
`

const StyledHeader = styled.div`
    display: none;
    @media print {
        display: block;
    }
`

const ListaRefacciones = (props) => {

    const [renderHoja, setRenderHoja] = useState(<></>);
    const [data, setData] = useState({})
    const [flag, setFlag] = useState(false);
    const [height, setHeight] = useState(0);
    const [height2, setHeight2] = useState(0);
    const ref = useRef(null);
    const ref2 = useRef(null);
    const [layout, setLayout] = useState(0);
    const [reportes, setReportes] = useState('');
    const [partida, setPartida] = useState('');
    const [fecha, setFecha] = useState('');
    const [admin, setAdmin] = useState('Ing. Abdias Lopez Reyes');
    const [sub, setSub] = useState('Lic. Sergio Meneses Hernández');
    const [jud, setJud] = useState('Ing. José Antonio Álvarez García');

    useEffect(() => {
        if(flag) {
            setHeight(ref.current.clientHeight);
            setHeight2(ref2.current.clientHeight);
        }
        if(height2 > 550) {
            // console.log('uno', height2);
            setLayout(1);
        }
        // else if(height2 > 400) {
        else if(height2 > 460) {
            // console.log('dos', height2);
            setLayout(2);
        }
        // if(height > 450) {
        if(height > 470) {
            // console.log('Uno', height);
            setLayout(1);
        }
    }, [height, height2, flag])

    useEffect(() => {
        document.title = 'Lista de Refacciones';
    }, [])

    const printDocument = () => {
        let printContents = document.getElementById('printDiv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();
    }

    const getData = async (e) => {
        e.preventDefault()
        const rep = reportes.split(',').map(Number);
        // console.log(rep);
        try {
            const res = await axios.post('/api/reportes/getReportByProp', {'noReporte': {$in: rep}});
            if(res.status === 200) {
                // console.log(res.data);
                let refServ = { refacciones: [], servicio: {descripcion: '', horas: 0, costoHora: 0, importe: 0}, cotizacion: {subtotal: 0, iva: 0, total: 0}}
                let tmp = '';
                res.data.forEach(x => {
                    if(x.refacciones.length > 0){
                        x.refacciones.forEach((r, i) => {
                            refServ.refacciones.push(r);
                            if(r.precioUnitario === 0) {
                                tmp += `${r.cantidad < 10 ? '0' + String(r.cantidad) : r.cantidad} ${r.refaccion}, `;
                            } else if ((r.refaccion.indexOf('REPARACION') !== -1) || (r.refaccion.indexOf('REPARACIÓN') !== -1) || 
                                        (r.refaccion.indexOf('FABRICACION') !== -1) || (r.refaccion.indexOf('FABRICACIÓN') !== -1) ||
                                        (r.refaccion.indexOf('CAMBIO') !== -1) || (r.refaccion.indexOf('REEMPLAZO') !== -1) ||
                                        (r.refaccion.indexOf('MANTENIMIENTO') !== -1) || (r.refaccion.indexOf('AJUSTE') !== -1) || (r.refaccion.indexOf('AJUSTES') !== -1) ||
                                        (r.refaccion.indexOf('ADAPTACION') !== -1) || (r.refaccion.indexOf('ADAPTACIÓN') !== -1)) {
                                tmp += `${r.cantidad < 10 ? '0' + String(r.cantidad) : r.cantidad} ${r.refaccion}, `;
                            } else {
                                tmp += `SUMINISTRO Y COLOCACIÓN DE ${r.cantidad < 10 ? '0' + String(r.cantidad) : r.cantidad} ${r.refaccion}, `;
                            }
                        })
                    }
                    if(x.cotizacion.total) {
                        refServ.cotizacion.subtotal += x.cotizacion.subtotal;
                        refServ.cotizacion.iva += x.cotizacion.iva;
                        refServ.cotizacion.total += x.cotizacion.total;
                    }
                    if(x.servicio.descripcion) {
                        refServ.servicio.horas += x.servicio.horas;
                        refServ.servicio.costoHora = x.servicio.costoHora;
                        refServ.servicio.importe += x.servicio.importe;
                    }
                })
                tmp += 'LIMPIEZA GENERAL Y PRUEBAS DE BUEN FUNCIONAMIENTO.';
                tmp = tmp.replace(",.", ",");
                tmp = tmp.replace(", .", ",");
                tmp = tmp.replace(".,", ",");
                tmp = tmp.replace(". ,", ",");
                tmp = tmp.replace(",,", ",");
                tmp = tmp.replace(", ,", ",");
                tmp = tmp.replace(' ,', ",");
                tmp = tmp.replace("  ", " ");
                refServ.servicio.descripcion = tmp;
                let fal = '';
                res.data.forEach(f => {
                  fal = fal + ', ' + f.falla;  
                })
                const tmp2 = {
                    noReporte: reportes,
                    noInventario: res.data[0].noInventario,
                    equipo: res.data[0].equipo,
                    marca: res.data[0].marca,
                    modelo: res.data[0].modelo,
                    hospital: res.data[0].hospital,
                    fallaReportada: fal,
                    area: res.data[0].area,
                    noContrato: res.data[0].noContrato,
                    noSerie: res.data[0].noSerie,
                    fecha: {factura: res.data[0].fecha.factura},
                    refacciones: refServ.refacciones,
                    servicio: refServ.servicio,
                    cotizacion: refServ.cotizacion
                }
                // console.log(tmp2);
                setData(tmp2);
            }
        } catch (err) {
            console.log(err);
            alert('No se encontró el número de reporte proporcionado, favor de verificar los datos proporcionados');
            getData();
        }
    }

    const getSuministros = (refacciones, servicio, cotizacion) => {
        if(layout === 0) {
            return(
                <>
                    <div ref={ref2} style={{width: '770px', border: 'solid 0.5px black', borderRadius: '5px', marginLeft: '23px'}}>
                        <div className='text-center'>
                            <p className='mb-0 text-bold' style={{background: '#BAD7F2', color: '#000', fontSize: '12px', border: 'solid black 0.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}}>
                                Suministros
                            </p>
                        </div>
                        <div className='d-flex align-items-stretch'>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px'}}>&nbsp;&nbsp;No. CATALOGO: </p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px'}}>CANTIDAD</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px'}}>DESCRIPCIÓN</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>PRECIO UNITARIO</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>IMPORTE</p>
                        </div>
                        <div ref={ref}>
                            {refacciones.map(r => (
                                r.precioUnitario !== 0 ? 
                                <div className='d-flex' key={r.key}>
                                    <p className='mb-0 d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px'}}>&nbsp;&nbsp;{r.noCatalogo}</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px'}}>{r.cantidad}</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-center text-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px'}}>{r.refaccion}</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(r.precioUnitario)}`}&nbsp;&nbsp;</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(r.precioUnitario*r.cantidad)}`}&nbsp;&nbsp;</p>
                                </div> : <></>
                            ))}
                        </div>
                        <div className='d-flex'>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-bold' style={{color: '#000', fontSize: '10px', width: '120px', border: 'solid black 0.5px'}}>&nbsp;&nbsp;SERVICIO</p>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-center p-1' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px'}}>{servicio.descripcion}</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '490px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>SUBTOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(cotizacion.subtotal)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '490px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>IVA</p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(cotizacion.iva)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '490px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>TOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px', borderBottomRightRadius: '4px'}}>{`$ ${formatMoney(cotizacion.total)}`}</p>
                        </div>
                    </div>

                    <div className='justify-content-center' style={{position: 'absolute', bottom: '80px', zIndex: '1', marginLeft: '23px'}}>
                        <hr className='my-2' style={{width: '770px'}}></hr>
                        <p className='mb-0 text-bold' style={{color: '#000', fontSize: '10px'}}>&nbsp;&nbsp;NOTA: ESTA LISTA DE REFACCIONES ES DE CARÁCTER ENUNCIATIVA MÁS NO LIMITATIVA</p>
                            <br></br>
                            <br></br>
                            <div className='d-flex justify-content-between px-3'>
                                <div>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>___________________________________</p>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>{sub.toUpperCase()}</p>    
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>SUBDIRECTOR DE MANTENIMIENTO Y SERVICIOS</p> 
                                </div>
                                <div>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>___________________________________</p>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>{jud.toUpperCase()}</p>    
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>JUD DE MANTENIMIENTO DE EQUIPO</p> 
                                </div>
                                <div>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>___________________________________</p>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>{admin.toUpperCase()}</p>    
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>ADMINISTRADOR ÚNICO</p> 
                                </div>
                            </div>
                    </div>
                </>
            )
        }
        if(layout === 1) {
            return(
                <>
                    <div style={{width: '770px', border: 'solid 0.5px black', borderRadius: '5px', marginLeft: '23px'}}>
                        <div className='text-center'>
                            <p className='mb-0 text-bold' style={{background: '#BAD7F2', color: '#000', fontSize: '12px', border: 'solid black 0.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}}>
                                Suministros
                            </p>
                        </div>
                        <div className='d-flex align-items-stretch'>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px'}}>&nbsp;&nbsp;No. CATALOGO: </p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px'}}>CANTIDAD</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px'}}>DESCRIPCIÓN</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>PRECIO UNITARIO</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>IMPORTE</p>
                        </div>
                        <div>
                            {refacciones.map(r => (
                                r.precioUnitario !== 0 ? 
                                <div className='d-flex' key={r.key}>
                                    <p className='mb-0 d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px'}}>&nbsp;&nbsp;{r.noCatalogo}</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px'}}>{r.cantidad}</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-center text-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px'}}>{r.refaccion}</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(r.precioUnitario)}`}&nbsp;&nbsp;</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(r.precioUnitario*r.cantidad)}`}&nbsp;&nbsp;</p>
                                </div> : <></>
                            ))}
                        </div>
                    </div>

                    <div style={{height: `${810-height}px`}}></div>

                    <div style={{height: '40px'}}>
                        <img src="/bg-juvalfri.svg" alt='juvalfri-logo' style={{position: 'absolute', top: '1056px', left: '0px', width: "100%", height: 'auto'}} />
                    </div>
                    <div style={{position: 'absolute', top: '1136px', right: '23px', width: '240px',}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p className='mb-0' style={{fontWeight: '600', fontSize: '14px'}}>
                                Lista de Refacciones &nbsp;<span style={{fontSize: '1.2em', fontWeight: '700'}}>{data.noReporte} </span>
                            </p>
                            <p className='mb-0' style={{fontWeight: '600', fontSize: '13px'}}>
                                CDMX &nbsp; | &nbsp;{fecha.toUpperCase()}
                            </p>
                            <p className='mb-0' style={{fontWeight: '600', fontSize: '13px'}}>
                                (continuación)
                            </p>
                        </div>
                    </div>

                    <div style={{width: '770px', border: 'solid 0.5px black', borderRadius: '5px', marginLeft: '23px'}}>
                        <div className='text-center'>
                            <p className='mb-0 text-bold' style={{background: '#BAD7F2', color: '#000', fontSize: '12px', border: 'solid black 0.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}}>
                                Suministros
                            </p>
                        </div>
                        <div className='d-flex'>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-bold' style={{color: '#000', fontSize: '10px', width: '120px', border: 'solid black 0.5px'}}>&nbsp;&nbsp;SERVICIO</p>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-center p-1' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px'}}>{servicio.descripcion}</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '490px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>SUBTOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(cotizacion.subtotal)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '490px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>IVA</p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(cotizacion.iva)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '490px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>TOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px', borderBottomRightRadius: '4px'}}>{`$ ${formatMoney(cotizacion.total)}`}</p>
                        </div>
                    </div>

                    <div className='justify-content-center' style={{marginLeft: '23px'}}>
                        <hr className='my-3' style={{width: '770px'}}></hr>
                        <p className='mb-0 text-bold' style={{color: '#000', fontSize: '10px'}}>&nbsp;&nbsp;NOTA: ESTA LISTA DE REFACCIONES ES DE CARÁCTER ENUNCIATIVA MÁS NO LIMITATIVA</p>
                            <br></br>
                            <br></br>
                            <div className='d-flex justify-content-between px-3'>
                                <div>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>___________________________________</p>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>{sub.toUpperCase()}</p>    
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>SUBDIRECTOR DE MANTENIMIENTO Y SERVICIOS</p> 
                                </div>
                                <div>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>___________________________________</p>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>{jud.toUpperCase()}</p>    
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>JUD DE MANTENIMIENTO DE EQUIPO</p> 
                                </div>
                                <div>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>___________________________________</p>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>{admin.toUpperCase()}</p>    
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>ADMINISTRADOR ÚNICO</p> 
                                </div>
                            </div>
                    </div>
                </>
            )
        }

        if(layout === 2) {
            return(
                <>
                   <div style={{width: '770px', border: 'solid 0.5px black', borderRadius: '5px', marginLeft: '23px'}}>
                        <div className='text-center'>
                            <p className='mb-0 text-bold' style={{background: '#BAD7F2', color: '#000', fontSize: '12px', border: 'solid black 0.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}}>
                                Suministros
                            </p>
                        </div>
                        <div className='d-flex align-items-stretch'>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px'}}>&nbsp;&nbsp;No. CATALOGO: </p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px'}}>CANTIDAD</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px'}}>DESCRIPCIÓN</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center text-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>PRECIO UNITARIO</p>
                            <p className='mb-0 text-bold d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>IMPORTE</p>
                        </div>
                        <div>
                            {refacciones.map(r => (
                                r.precioUnitario !== 0 ? 
                                <div className='d-flex' key={r.key}>
                                    <p className='mb-0 d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', width: '60px', border: 'solid black 0.5px'}}>&nbsp;&nbsp;{r.noCatalogo}</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '60px'}}>{r.cantidad}</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-center text-center' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px'}}>{r.refaccion}</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(r.precioUnitario)}`}&nbsp;&nbsp;</p>
                                    <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(r.precioUnitario*r.cantidad)}`}&nbsp;&nbsp;</p>
                                </div> : <></>
                            ))}
                        </div>
                        <div className='d-flex'>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-bold' style={{color: '#000', fontSize: '10px', width: '120px', border: 'solid black 0.5px'}}>&nbsp;&nbsp;SERVICIO</p>
                            <p className='mb-0 d-flex align-items-center justify-content-center text-center p-1' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '490px'}}>{servicio.descripcion}</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                            <p className='mb-0 d-flex align-items-center justify-content-end' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(servicio.importe)}`}&nbsp;&nbsp;</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '490px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>SUBTOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(cotizacion.subtotal)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '490px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>IVA</p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>{`$ ${formatMoney(cotizacion.iva)}`}</p>
                        </div>
                        <div className='d-flex align-items-center'>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '60px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', width: '490px'}}></p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px'}}>TOTAL</p>
                            <p className='mb-0 text-center text-bold' style={{color: '#000', fontSize: '10px', border: 'solid black 0.5px', width: '80px', borderBottomRightRadius: '4px'}}>{`$ ${formatMoney(cotizacion.total)}`}</p>
                        </div>
                    </div>

                    <div style={{height: `${585-height}px`}}></div>

                    <div style={{height: '30px'}}>
                        <img src="/bg-juvalfri.svg" alt='juvalfri-logo' style={{position: 'absolute', top: '1056px', left: '0px', width: "100%", height: 'auto'}} />
                    </div>
                    <div style={{position: 'absolute', top: '1136px', right: '23px', width: '280px',}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p className='mb-0' style={{fontWeight: '600', fontSize: '14px'}}>
                                Lista de Refacciones &nbsp;<span style={{fontSize: '1.2em', fontWeight: '700'}}>{data.noReporte} </span>
                            </p>
                            <p className='mb-0' style={{fontWeight: '600', fontSize: '13px'}}>
                                CDMX &nbsp; | &nbsp;{fecha.toUpperCase()}
                            </p>
                            <p className='mb-0' style={{fontWeight: '600', fontSize: '13px'}}>
                                (continuación)
                            </p>
                        </div>
                    </div>

                    <div className='justify-content-center' style={{marginLeft: '23px'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end', marginRight: '73px', fontSize: '12px'}} className='py-2'>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                                <p style={{width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black', borderTopLeftRadius: '5px'}} className='m-0 text-center text-bold'>SUBTOTAL:</p>
                                <p style={{width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black'}} className='m-0 text-center text-bold'>IVA:</p>
                                <p style={{width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black', borderRight: 'solid 0.5px black', borderTopRightRadius: '5px'}} className='m-0 text-center text-bold'>TOTAL:</p>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                                <p style={{width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black', borderBottom: 'solid 0.5px black'}} className='m-0 text-center text-bold'>{`$ ${formatMoney(cotizacion.subtotal)}`}</p>
                                <p style={{width: '100px', borderTop: 'solid 0.5px black', borderLeft: 'solid 0.5px black', borderBottom: 'solid 0.5px black'}} className='m-0 text-center text-bold'>{`$ ${formatMoney(cotizacion.iva)}`}</p>
                                <p style={{width: '100px', border: 'solid 0.5px black'}} className='m-0 text-center text-bold'>{`$ ${formatMoney(cotizacion.total)}`}</p>
                            </div>
                        </div>
                        <hr className='my-2' style={{width: '770px'}}></hr>
                        <p className='mb-0 text-bold' style={{color: '#000', fontSize: '10px'}}>&nbsp;&nbsp;NOTA: ESTA LISTA DE REFACCIONES ES DE CARÁCTER ENUNCIATIVA MÁS NO LIMITATIVA</p>
                            <br></br>
                            <br></br>
                            <div className='d-flex justify-content-between px-3'>
                                <div>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>___________________________________</p>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>{sub.toUpperCase()}</p>    
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>SUBDIRECTOR DE MANTENIMIENTO Y SERVICIOS</p> 
                                </div>
                                <div>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>___________________________________</p>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>{jud.toUpperCase()}</p>    
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>JUD DE MANTENIMIENTO DE EQUIPO</p> 
                                </div>
                                <div>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>___________________________________</p>
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>{admin.toUpperCase()}</p>    
                                    <p className='mb-0 text-bold text-center' style={{color: '#000', fontSize: '10px'}}>ADMINISTRADOR ÚNICO</p> 
                                </div>
                            </div>
                    </div>
                </>
            )
        }
    }

    useEffect(() => {
        if(data.refacciones) {
            document.title = 'Lista de Refacciones ' + data.noReporte;
            setRenderHoja(
                <div id='printDiv' style={{position: 'absolute', width: '816px', height: '1056px', background: '#FFF'}} className='exo2 p-0'>
                    <div style={{height: '150px'}}>
                        <img src="/bg-juvalfri.svg" alt='juvalfri-logo' style={{position: 'absolute', top: '0px', left: '0px', width: "100%", height: 'auto'}} />
                    </div>
                    <div style={{width: '770px', marginLeft: '23px', fontStyle: 'italic', color: '#000', fontSize: '12px'}}>
                        <p className='mb-0'>Secretaria de Salud de la Ciudad de México</p>
                    </div>

                    <div style={{position: 'absolute', top: '80px', right: '23px', width: '250px'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p className='mb-0' style={{fontWeight: '600', fontSize: '14px'}}>
                                Lista de Refacciones &nbsp;<span style={{fontSize: '1.2em', fontWeight: '700'}}>{data.noReporte}</span>
                            </p>
                            <p className='mb-0' style={{fontWeight: '600', fontSize: '13px'}}>
                                CDMX &nbsp; | &nbsp;{fecha.toUpperCase()}
                            </p>
                        </div>
                    </div>

                    <div className='justify-content-center' style={{width: '770px', marginLeft: '23px'}}>
                        <hr className='my-2'></hr>
                        <div className='d-flex align-items-stretch' style={{width: '640px', color: '#000'}}>
                            <p style={{width: '120px', fontSize: '11px'}} className='my-0 px-1 d-flex align-items-center'>Unidad Hospitalaria:</p>
                            <p style={{fontSize: '11px'}} className='my-0 px-1 text-bold'>{data.hospital.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{width: '640px', color: '#000'}}>
                            <p style={{width: '120px', fontSize: '11px'}} className='mb-0 px-1 d-flex align-items-center'>Equipo:</p>
                            <p style={{fontSize: '11px'}} className='mb-0 px-1 text-bold'>{data.equipo.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{width: '640px', color: '#000'}}>
                            <p style={{width: '120px', fontSize: '11px'}} className='mb-0 px-1 d-flex align-items-center'>Marca:</p>
                            <p style={{fontSize: '11px'}} className='mb-0 px-1 text-bold'>{data.marca.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{width: '640px', color: '#000'}}>
                            <p style={{width: '120px', fontSize: '11px'}} className='mb-0 px-1 d-flex align-items-center'>Modelo:</p>
                            <p style={{fontSize: '11px'}} className='mb-0 px-1 text-bold'>{data.modelo.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{width: '640px', color: '#000'}}>
                            <p style={{width: '120px', fontSize: '11px'}} className='mb-0 px-1 d-flex align-items-center'>No. Serie:</p>
                            <p style={{fontSize: '11px'}} className='mb-0 px-1 text-bold'>{data.noSerie.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{width: '640px', color: '#000'}}>
                            <p style={{width: '120px', fontSize: '11px'}} className='mb-0 px-1 d-flex align-items-center'>No. Inventario:</p>
                            <p style={{fontSize: '11px'}} className='mb-0 px-1 text-bold'>{data.noInventario.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{width: '640px', color: '#000'}}>
                            <p style={{width: '120px', fontSize: '11px'}} className='mb-0 px-1 d-flex align-items-center'>Contrato:</p>
                            <p style={{fontSize: '11px'}} className='mb-0 px-1 text-bold'>{data.noContrato.toUpperCase()}</p>
                        </div>
                        <div className='d-flex align-items-stretch' style={{width: '640px', color: '#000'}}>
                            <p style={{width: '120px', fontSize: '11px'}} className='mb-0 px-1 d-flex align-items-center'>Partida:</p>
                            <p style={{fontSize: '11px'}} className='mb-0 px-1 text-bold'>{partida.toUpperCase()}</p>
                        </div>
                    </div>

                    <div style={{marginTop: '12px'}}>
                        {getSuministros(data.refacciones, data.servicio, data.cotizacion)}
                    </div>
                </div>
            )
            setFlag(true);
        }
    }, [data, layout])

    return(
        <div style={{background: '#FFF', marginTop: '10px'}}>
            <Box className='text-center' >
                <Typography variant='h5' style={{fontWeight: 700}}>Lista de refacciones</Typography>
            </Box>
            <Container maxWidth='xl'>
                <Form onSubmit={(e) => getData(e)}>
                    <Form.Group>
                        <Form.Label className='text-bold'>Reportes:</Form.Label>
                        <Form.Control
                                onChange={(e) => setReportes(e.target.value)}
                                value={reportes}
                                id="reportes"
                                type="text"
                                required
                        >
                        </Form.Control>
                    </Form.Group>
                    <hr />
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label className='text-bold'>Partida:</Form.Label>
                                <Form.Control
                                        onChange={(e) => setPartida(e.target.value)}
                                        value={partida}
                                        id="Partida"
                                        type="text"
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label className='text-bold'>Fecha:</Form.Label>
                                <Form.Control
                                        onChange={(e) => setFecha(e.target.value)}
                                        value={fecha}
                                        id="Partida"
                                        type="text"
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label className='text-bold'>Administrador Unico:</Form.Label>
                                <Form.Control
                                        onChange={(e) => setAdmin(e.target.value)}
                                        value={admin}
                                        id="Partida"
                                        type="text"
                                        required
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label className='text-bold'>Subdirector de Mantenimiento :</Form.Label>
                                <Form.Control
                                        onChange={(e) => setSub(e.target.value)}
                                        value={sub}
                                        id="Partida"
                                        type="text"
                                        required
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label className='text-bold'>JUD Mantenimiento:</Form.Label>
                                <Form.Control
                                        onChange={(e) => setJud(e.target.value)}
                                        value={jud}
                                        id="Partida"
                                        type="text"
                                        required
                                >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button type='submit' variant='success' className='my-2'>Buscar</Button>
                    <br />
                    {data.length > 0 ? <p>Datos de la orden tomados del reporte: {data[0].numero.reporte}</p> : <></>}
                </Form>
                <Button variant='success' className='m-3' onClick={(e) => printDocument(e)}>Imprimir hoja &nbsp; <i className="fas fa-print"></i></Button>
                {renderHoja}
            </Container>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps)(ListaRefacciones);