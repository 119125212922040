import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from "react-redux";
import { Container, DropdownButton, Dropdown, Table } from 'react-bootstrap'

import { formatMoney } from '../../utils/functions/UtilsJuvalfri';

const ListaPrecios = (props) => {

    const [tipoEquipo, setTipoEquipo] = useState('Tipo de Equipo');
    const [listaPrecios, setListaPrecios] = useState([{noCatalogo: '1', refaccion: 'TRANSDUCTOR DE ULTRASONIDO', precioUnitario: '1050.00'}]);
    const [renderDropdown, setRenderDropdown] = useState(<></>);
    const [renderLista, setRenderLista] = useState(<></>);

    const dropLista = ['ELECTROCARDIÓGRAFOS', 'TOCOCARDIOGRAFOS', 'MONITORES', 'MESAS DE CIRUGÍA'];

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
    }, [props])

    useEffect(() => {
        if(tipoEquipo !== 'Tipo de Equipo'){
            getRefList(tipoEquipo);
            document.title = 'Lista de precios ' + tipoEquipo;
        } else {
            setRenderLista(<></>);
        }
    }, [tipoEquipo])

    const getRefList = async (tipoEquipo) => {
        const index = dropLista.indexOf(tipoEquipo) + 1;
        try {
            const res = await axios.get(`/api/refacciones?type=${index}`)
            if(res.status === 200) {
                setListaPrecios(res.data);
            }
        } catch (err) {
            console.log(err);
            alert('Ocurrió un error al obtener la lista de precios, favor de intentarlo mas tarde');
        }
    }

    useEffect(() => {
        setRenderDropdown(
            <DropdownButton
                drop={'down'}
                style={{fontWeight: '700 !important'}}
                title={tipoEquipo}>
                {dropLista.map(list => (
                    <Dropdown.Item
                        key={list}
                        onClick={() => setTipoEquipo(list)}
                    >{list}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        )
    }, [tipoEquipo])

    useEffect(() => {
        if(listaPrecios.length > 1) {
            setRenderLista(<>
                <Table striped bordered hover style={{width: '100%'}}>
                    <thead className='fixed'>
                        <tr>
                            <th><div>No. Catalogo</div></th>
                            <th><div>Descripcion</div></th>
                            <th><div style={{width: '120px'}}>Precio unitario</div></th>
                        </tr>
                    </thead>
                    <tbody> 
                        {listaPrecios.map(d => (
                            <tr key={d._id}
                            >
                                <td>{d.noCatalogo}</td>
                                <td>{d.refaccion}</td>
                                <td>{`$ ${formatMoney(d.precioUnitario)}`}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>)
        }
    }, [listaPrecios])

    return(
        <>
            <Container style={{marginTop: '10px'}}>
                <p className='text-bold color-prim2 h3 mb-3 text-center'>Lista de precios</p>
                {renderDropdown}
                <br></br>
                {renderLista}
            </Container>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(ListaPrecios);