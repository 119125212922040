import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import { Container } from '@mui/material'
import { getDate, formatMoney } from '../../utils/functions/UtilsJuvalfri';

import FormControl from '@mui/material/FormControl';
import { Select, MenuItem, InputLabel, Grid } from '@mui/material';
import CustomMessage from '../../utils/components/CustomMessage';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const General = (props) => {
    
    const [data, setData] = useState(null);
    const [render, setRender] = useState(<></>);
    const [contratos, setContratos] = useState(null);
    const [currentContrato, setCurrentContrato] = useState({ noContrato: ''});
    const [renderContrato, setRenderContrato] = useState(<></>);
    const [g1, setG1] = useState(null);
    const [g2, setG2] = useState(null);
    const [g3, setG3] = useState(null);
    const [g4, setG4] = useState(null);

    const [apiRes, setApiRes] = useState({show: false, message: '', severity: ''});

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show}})
    }

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
    }, [props])

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            const res = await axios.get('/api/contratos');
            if(res.status === 200) {
                setContratos(res.data);
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al cargar los contratos, intente más tarde', severity: 'error' }});
        }
    }

    const getEstadistica = async () => {
        try {
            const res = await axios.post('/api/reportes/getEstadistica', {noContrato: currentContrato.noContrato});
            if(res.status === 200) {
                setData(res.data);
                document.title = 'Estadísticas Generales';
            }
        } catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if(contratos !== null) {
            setRenderContrato(
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl sx={{ minWidth: 100 }} style={{display: 'flex'}}>
                        <InputLabel id="demo-simple-select-label">Contrato</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentContrato.noContrato}
                            label="Contrato"
                            onChange={(e) => setCurrentContrato(contratos.find(c => c.contrato.noContrato === e.target.value).contrato)}
                        >
                            {contratos.map((c) => {
                                return <MenuItem key={c.contrato.noContrato} value={c.contrato.noContrato}>{c.contrato.noContrato}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            )
        }

        if(currentContrato.noContrato !== '') { 
            console.log(currentContrato.noContrato)
            getEstadistica();
        }
    }, [contratos, currentContrato])

    useEffect(() => {
        if(data !== null) {
            let arrData = [];
            data.rGeneral.porEstatus.forEach((d) => {
                arrData.push(d.y);
            })
            setG1({
                labels: ['PENDIENTE', 'DIAGNOSTICADO', 'EN ESPERA DE AUTORIZACION', 'AUTORIZADO', 'EN REPARACIÓN', 'ATENDIDO', 'FACTURADO', 'CANCELADO', 'EN GARANTÍA', 'BAJA DE EQUIPO'],
                datasets: [
                    {
                    label: 'Estatus',
                    // data: [12, 19, 3, 5, 2, 3],
                    data: arrData,
                    backgroundColor: [
                        '#FFEE5888',
                        '#42A5F588',
                        '#FFA72688',
                        '#EC407A88',
                        '#AB47BC88',
                        '#26A69A88',
                        '#66BB6A88',
                        '#EF535088',
                        '#75757588',
                        '#8D6E6388',
                    ],
                    borderColor: [
                        '#FFEE58',
                        '#42A5F5',
                        '#FFA726',
                        '#EC407A',
                        '#AB47BC',
                        '#26A69A',
                        '#66BB6A',
                        '#EF5350',
                        '#757575',
                        '#8D6E63',
                    ],
                    borderWidth: 2,
                    },
                ],
            })
            arrData = [];
            data.rGeneral.porEquipo.forEach((d) => {
                arrData.push(d.y);
            })
            setG2({
                labels: ['ELECTROCARDIÓGRAFOS', 'TOCOCARDIÓGRAFOS', 'MONITORES DE SIGNOS VITALES', 'MESAS'],
                datasets: [
                  {
                    label: 'Estatus',
                    data: arrData,
                    backgroundColor: [
                      '#8E24AA88',
                      '#FFB30088',
                      '#039BE588',
                      '#AEEA0088',
                      '#546E7A88',
                    ],
                    borderColor: [
                      '#8E24AA',
                      '#FFB300',
                      '#039BE5',
                      '#AEEA00',
                      '#546E7A',
                    ],
                    borderWidth: 2,
                  },
                ],
            })
            arrData = [];
            data.rGeneral.ejercidoPorEquipo.forEach((d) => {
                arrData.push(d.y);
            })
            setG3({
                labels: ['ELECTROCARDIÓGRAFOS', 'TOCOCARDIÓGRAFOS', 'MONITORES DE SIGNOS VITALES', 'MESAS'],
                datasets: [
                  {
                    label: 'Ejercido',
                    data: arrData,
                    backgroundColor: [
                      '#8E24AA88',
                      '#FFB30088',
                      '#039BE588',
                      '#AEEA0088',
                      '#546E7A88',
                    ],
                    borderColor: [
                      '#8E24AA',
                      '#FFB300',
                      '#039BE5',
                      '#AEEA00',
                      '#546E7A',
                    ],
                    borderWidth: 2,
                  },
                ],
            })
            arrData = [];
            data.rGeneral.porMes.forEach((d) => {
                arrData.push(d.y);
            })
            setG4({
                labels: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
                datasets: [
                  {
                    label: 'Ejercido',
                    data: arrData,
                    backgroundColor: [
                      '#FF525288',
                      '#E040FB88',
                      '#536DFE88',
                      '#40C4FF88',
                      '#64FFDA88',
                      '#B2FF5988',
                      '#FFFF0088',
                      '#FFAB4088',
                      '#8D6E6388',
                      '#607D8B88',
                      '#FF408188',
                      '#00E67688',
                    ],
                    borderColor: [
                      '#FF5252',
                      '#E040FB',
                      '#536DFE',
                      '#40C4FF',
                      '#64FFDA',
                      '#B2FF59',
                      '#FFFF00',
                      '#FFAB40',
                      '#8D6E63',
                      '#607D8B',
                      '#FF4081',
                      '#00E676',
                    ],
                    borderWidth: 2,
                  },
                ],
            })
        }
    }, [data])

    //Pendiente de teminar las estadisticas
    useEffect(() => {
        if(data !== null && g1 !== null && g2 !== null && g3 !== null && g4 !== null) {
            if(data.rGeneral.porEquipo) {
                setRender(
                    <div>
                        <h2 className='text-bold color-prim2 text-center'>Estadísticas Generales</h2>
                        <hr />
                        <Row>
                            <br/>
                            <Col sm='12' md='6' lg='4'>
                                <div style={{border: 'solid black 1.5px', borderRadius: '10px', maxWidth: '400px'}} className='m-2'>
                                    <p style={{color: '#000', fontSize: '16px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}} className='text-bold pb-1 mb-0 text-center'>
                                        &nbsp;&nbsp;Avance General
                                    </p>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '170px', fontSize: '16px'}} className='my-1 px-1 text-bold d-flex align-items-center'>Ejercicio al:</p>
                                        <p style={{fontSize: '16px'}} className='my-1 px-1'>{getDate(new Date().toISOString().slice(0, 10), 'DDD')}</p>
                                    </div>
                                    <hr style={{marginTop: '4px', marginBottom: '4px'}}></hr>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '170px', fontSize: '16px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>Total autorizado:</p>
                                        <p style={{fontSize: '16px'}} className='mb-1 px-1'>$ {formatMoney(data.rGeneral.totalEjercido)}</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '170px', fontSize: '16px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>Avance:</p>
                                        <p style={{fontSize: '16px'}} className='mb-1 px-1'>{data.rGeneral.porcentaje} %</p>
                                    </div>
                                    <hr style={{marginTop: '4px', marginBottom: '4px'}}></hr>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '170px', fontSize: '16px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>Electrocardiógrafos:</p>
                                        <p style={{fontSize: '16px'}} className='mb-1 px-1'>$ {formatMoney(data.rGeneral.ejercidoPorEquipo[0].y)}</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '170px', fontSize: '16px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>Tococardiógrafos:</p>
                                        <p style={{fontSize: '16px'}} className='mb-1 px-1'>$ {formatMoney(data.rGeneral.ejercidoPorEquipo[1].y)}</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '170px', fontSize: '16px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>Monitores:</p>
                                        <p style={{fontSize: '16px'}} className='mb-1 px-1'>$ {formatMoney(data.rGeneral.ejercidoPorEquipo[2].y)}</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '170px', fontSize: '16px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>Mesas:</p>
                                        <p style={{fontSize: '16px'}} className='mb-1 px-1'>$ {formatMoney(data.rGeneral.ejercidoPorEquipo[3].y)}</p>
                                    </div>
                                </div>
                            </Col>
                            <br/>
                            <Col sm='12' md='6' lg='4'>
                                <div style={{border: 'solid black 1.5px', borderRadius: '10px', maxWidth: '400px'}} className='m-2'>
                                    <p style={{color: '#000', fontSize: '16px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}} className='text-bold pb-1 mb-0 text-center'>
                                        &nbsp;&nbsp;Reportes por Estatus
                                    </p>
                                    {data.rGeneral.porEstatus.map(d => (
                                        <div key={d.x} className='d-flex align-items-stretch' style={{color: '#000'}}>
                                            <p style={{width: '40px', fontSize: '16px', marginLeft: '8px'}} className='my-1 px-1'>{d.y}</p>
                                            <p style={{fontSize: '16px'}} className='my-1 px-1 text-bold d-flex align-items-center'>{d.x}</p>
                                        </div>
                                    ))}
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '40px', fontSize: '16px', marginLeft: '8px'}} className='my-1 px-1'>{data.rGeneral.totalReportes}</p>
                                        <p style={{fontSize: '16px'}} className='my-1 px-1 text-bold d-flex align-items-center'>TOTAL DE REPORTES</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '40px', fontSize: '12px', marginLeft: '8px'}} className='my-1 px-1'>*</p>
                                        <p style={{fontSize: '12px'}} className='my-1 px-1 d-flex align-items-center'>Todos los reportes incluyendo cancelados, baja y en espera</p>
                                    </div>
                                </div>
                            </Col>
                            <br/>
                            <Col sm='12' md='6' lg='4'>
                                <div style={{border: 'solid black 1.5px', borderRadius: '10px', maxWidth: '400px'}} className='m-2'>
                                    <p style={{color: '#000', fontSize: '16px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}} className='text-bold pb-1 mb-0 text-center'>
                                        &nbsp;&nbsp;Reportes por tipo de Equipo
                                    </p>
                                    {data.rGeneral.porEquipo.map(d => (
                                        <div key={d.x} className='d-flex align-items-stretch' style={{color: '#000'}}>
                                            <p style={{width: '40px', fontSize: '16px', marginLeft: '8px'}} className='my-1 px-1'>{d.y}</p>
                                            <p style={{fontSize: '16px'}} className='my-1 px-1 text-bold d-flex align-items-center'>{d.x}</p>
                                        </div>
                                    ))}
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '40px', fontSize: '12px', marginLeft: '8px'}} className='my-1 px-1'>*</p>
                                        <p style={{fontSize: '12px'}} className='my-1 px-1 d-flex align-items-center'>Contando solo reportes que estan autorizados, en reparación, atendidos y en garantía</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col sm='12' lg='6'>
                                <div style={{border: 'solid 1px black', borderRadius: '20px'}} className='m-2'>
                                    <p className='h3 text-center m-3'>Reportes por Estatus</p>
                                    <Doughnut data={g1}/>
                                    <br></br>
                                </div>
                            </Col>
                            <br/>
                            <Col lg='6'>
                                <div  style={{border: 'solid 1px black', borderRadius: '20px'}} className='m-2'>
                                    <p className='h3 text-center m-3'>Reportes por Mes</p>
                                    <Doughnut data={g4}/>
                                    <br></br>
                                </div>
                            </Col>
                        </Row>
                        <Row className='my-3'>
                            <Col sm='12' lg='6'>
                                <div style={{border: 'solid 1px black', borderRadius: '20px'}} className='m-2'>
                                    <p className='h3 text-center m-3'>Reportes por equipo</p>
                                    <Doughnut data={g2}/>
                                    <br></br>
                                </div>
                            </Col>
                            <Col sm='12' lg='6'>
                                <div style={{border: 'solid 1px black', borderRadius: '20px'}} className='m-2'>
                                    <p className='h3 text-center m-3'>Ejercido por equipo</p>
                                    <Doughnut data={g3}/>
                                    <br></br>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )
            }
        }
    }, [data, g1, g2, g3, g4])

    return (
        <Container maxWidth='xl' style={{marginTop: '10px'}}>
            {renderContrato}
            {render}
            <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />
        </Container>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(General);