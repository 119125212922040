import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import axios from 'axios';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Fab from '@mui/material/Fab';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Box, Divider, Typography, Grid, Button, Container } from '@mui/material';

import CustomChip from '../../utils/components/CustomChip';

import CustomMessage from '../../utils/components/CustomMessage';
import { status } from '../../utils/functions/UtilsJuvalfri';
import { equipos } from '../../utils/functions/UtilsJuvalfri';
import { hospitales } from '../../utils/functions/UtilsJuvalfri';

function EditarReporte(props) {

    const [data, setData] = useState([])
    const [fechas, setFechas] = useState({
        reporte: new Date(), 
        diagnostico: new Date(), 
        cotizacion: new Date(), 
        autorizacion: new Date(), 
        servicio: new Date(), 
        entrega: new Date(), 
        recoleccion: new Date(), 
        facturacion: new Date()})
    const [factura, setFactura] = useState({facturado: false, noFactura: ''})
    const [garantia, setGarantia] = useState({flag: false, date: new Date(), descripcion: ''})
    const [renderData, setRenderData] = useState(<></>);
    const [apiRes, setApiRes] = useState({show: false, message: '', severity: ''});

    let { id } = useParams();

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
    }, [props])

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            let res;
            if(props.location.state !== undefined) {
                res = await axios.get(`/api/reportes/${props.location.state.id}`);
            } else {
                res = await axios.get(`/api/reportes/${id}`);
            }
            if(res.status === 200) {
                setData(res.data);
                setFechas({
                    reporte: res.data.fecha.reporte ? new Date(res.data.fecha.reporte) : null, 
                    diagnostico: res.data.fecha.diagnostico ? new Date(res.data.fecha.diagnostico): null, 
                    cotizacion: res.data.fecha.cotizacion ? new Date(res.data.fecha.cotizacion) : null, 
                    autorizacion: res.data.fecha.autorizacion ? new Date(res.data.fecha.autorizacion) : null,
                    servicio: res.data.fecha.servicio ? new Date(res.data.fecha.servicio) : null, 
                    entrega: res.data.fecha.entrega ? new Date(res.data.fecha.entrega) : null, 
                    recoleccion: res.data.fecha.recoleccion ? new Date(res.data.fecha.recoleccion) : null,
                    facturacion: res.data.fecha.facturacion ? new Date(res.data.fecha.facturacion) : null,
                });
                setFactura({facturado: res.data.factura.facturado, noFactura: res.data.factura.noFactura ? res.data.factura.noFactura : ''});
                setGarantia({flag: res.data.garantia.flag, date: res.data.garantia.date ? new Date(res.data.garantia.date) : null, descripcion: res.data.garantia.descripcion ? res.data.garantia.descripcion : ''});
                document.title = 'Editar Reporte ' + res.data.noReporte;
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al cargar el reporte, favor de intentarlo mas tarde', severity: 'error' }});
            console.log(err);
        }
    }

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show}})
    }

    useEffect(() => {
        if(data._id) {
            setRenderData(
                <>
                    <Divider className='my-3' textAlign="left">
                        <CustomChip label='Detalles y estatus' className='text-bold'/>
                    </Divider>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <Autocomplete
                                    disablePortal
                                    freeSolo={true}
                                    onInputChange={(event, newInputValue) => setData({ ...data, estatus: newInputValue})}
                                    inputValue={data.estatus}
                                    aria-multiline='true'
                                    id="combo-box-demo"
                                    options={status}
                                    renderInput={(params) => <TextField {...params} label='Estatus' />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="# Reporte" variant="outlined" value={data.noReporte} onChange={(e) => setData({ ...data, noReporte: e.target.value})} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Observaciones" variant="outlined" multiline={true} value={data.observaciones} onChange={(e) => setData({ ...data, observaciones: e.target.value})} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Recomendaciones para el Usuario" variant="outlined" multiline={true} value={data.recomendaciones} onChange={(e) => setData({ ...data, recomendaciones: e.target.value})} />
                            </FormControl>
                        </Grid>
                    </Grid>
                    
                    <Grid container spacing={3} className='mt-1'>
                        <Grid item xs={12} sm={6} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex', justifyContent: 'center', alignItems: 'start'}}>
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Checkbox
                                            checked={garantia.flag}
                                            onChange={(e) => setGarantia({ ...garantia, flag: e.target.checked})}
                                        />
                                    }
                                    label="Garantía"
                                    labelPlacement="start"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <MobileDatePicker
                                    label="Fecha de servicio por garantía"
                                    inputFormat="dd/MM/yyyy"
                                    value={garantia.date}
                                    onChange={(newValue) => setGarantia({ ...garantia, date: newValue})}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField className='mx-2' id="outlined-basic" label="Descripcion de garantía" variant="outlined" multiline value={garantia.descripcion} onChange={(e) => setGarantia({ ...garantia, descripcion: e.target.value })} />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className='mt-1'>
                        <Grid item xs={12} sm={6} lg={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Checkbox
                                            checked={factura.facturado}
                                            onChange={(e) => setFactura({ ...factura, facturado: e.target.checked})}
                                        />
                                    }
                                    label="Facturado"
                                    labelPlacement="start"
                                />
                                <TextField className='mx-2' id="outlined-basic" label="No. Factura" variant="outlined" value={factura.noFactura} onChange={(e) => setFactura({ ...factura, noFactura: e.target.value })} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} >
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Checkbox
                                            checked={data.ampliacion}
                                            onChange={(e) => setData({ ...data, ampliacion: e.target.checked})}
                                        />
                                    }
                                    label="Ampliación"
                                    labelPlacement="start"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Divider className='my-3' textAlign="left">
                        <CustomChip label='Fechas' className='text-bold'/>
                    </Divider>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <MobileDatePicker
                                    label="Fecha de Diagnostico"
                                    inputFormat="dd/MM/yyyy"
                                    value={fechas.diagnostico}
                                    onChange={(newValue) => setFechas({ ...fechas, diagnostico: newValue})}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <MobileDatePicker
                                    label="Fecha de Cotización"
                                    inputFormat="dd/MM/yyyy"
                                    value={fechas.cotizacion}
                                    onChange={(newValue) => setFechas({ ...fechas, cotizacion: newValue})}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <MobileDatePicker
                                    label="Fecha de Autorización"
                                    inputFormat="dd/MM/yyyy"
                                    value={fechas.autorizacion}
                                    onChange={(newValue) => setFechas({ ...fechas, autorizacion: newValue})}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <MobileDatePicker
                                    label="Fecha de Servicio"
                                    inputFormat="dd/MM/yyyy"
                                    value={fechas.servicio}
                                    onChange={(newValue) => setFechas({ ...fechas, servicio: newValue})}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <MobileDatePicker
                                    label="Fecha de Recolección"
                                    inputFormat="dd/MM/yyyy"
                                    value={fechas.recoleccion}
                                    onChange={(newValue) => setFechas({ ...fechas, recoleccion: newValue})}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <MobileDatePicker
                                    label="Fecha de Entrega"
                                    inputFormat="dd/MM/yyyy"
                                    value={fechas.entrega}
                                    onChange={(newValue) => setFechas({ ...fechas, entrega: newValue})}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <MobileDatePicker
                                    label="Fecha de Facturación"
                                    inputFormat="dd/MM/yyyy"
                                    value={fechas.facturacion}
                                    onChange={(newValue) => setFechas({ ...fechas, facturacion: newValue})}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Divider className='my-3' textAlign="left">
                        <CustomChip label='Informacion del reporte' className='text-bold'/>
                    </Divider>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={5}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <Autocomplete
                                    disablePortal
                                    freeSolo={true}
                                    onInputChange={(event, newInputValue) => setData({ ...data, equipo: newInputValue})}
                                    inputValue={data.equipo}
                                    aria-multiline='true'
                                    id="combo-box-demo"
                                    options={equipos}
                                    renderInput={(params) => <TextField {...params} label='Equipo' />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Marca" variant="outlined" value={data.marca} onChange={(e) => setData({ ...data, marca: e.target.value})} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Modelo" variant="outlined" value={data.modelo} onChange={(e) => setData({ ...data, modelo: e.target.value})} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="No. Serie" variant="outlined" multiline={true} value={data.noSerie} onChange={(e) => setData({ ...data, noSerie: e.target.value})} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="No. Inventario" variant="outlined" value={data.noInventario} onChange={(e) => setData({ ...data, noInventario: e.target.value})} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <MobileDatePicker
                                    label="Fecha de Reporte"
                                    inputFormat="dd/MM/yyyy"
                                    value={fechas.reporte}
                                    onChange={(newValue) => setFechas({ ...fechas, reporte: newValue})}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <Autocomplete
                                    disablePortal
                                    freeSolo={true}
                                    onInputChange={(event, newInputValue) => setData({ ...data, hospital: newInputValue})}
                                    inputValue={data.hospital}
                                    aria-multiline='true'
                                    id="combo-box-demo"
                                    options={hospitales}
                                    renderInput={(params) => <TextField {...params} label='Unidad Hospitalaria' />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Área" variant="outlined" value={data.area} onChange={(e) => setData({ ...data, area: e.target.value})} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Falla Reportada" variant="outlined" multiline={true} value={data.fallaReportada} onChange={(e) => setData({ ...data, fallaReportada: e.target.value})} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="No. Contrato" variant="outlined" value={data.noContrato} onChange={(e) => setData({ ...data, noContrato: e.target.value})} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }, [data, fechas, factura, garantia])

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            const tmp = data;
            tmp.fecha = fechas;
            tmp.factura = factura;
            tmp.garantia = garantia;
            const res = await axios.post(`/api/reportes/updateReport/${data._id}`, {data: tmp});
            if(res.status === 200) {
                setApiRes(state => { return{ ...state, show: true, message: 'El reporte fue actualizado con exito', severity: 'success' }});
            }
        } catch (err) {
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al actualizar el reporte, favor de intentarlo mas tarde', severity: 'error' }});
            console.log(err);
        }
    }

  return (
    <Container maxWidth='xl' style={{marginTop: '10px'}}>
        <Box className='text-center' >
            <Typography variant='h5' style={{fontWeight: 700}}>Editar Reporte</Typography>
        </Box>

        <Fab aria-label="add" onClick={handleSubmit} style={{position: 'fixed', top: '60px', left: '12px', zIndex: '10', backgroundColor: '#66bb6a', color: '#FFF'}}>
            <i className="fad fa-save fa-2x"></i>
        </Fab>

        {renderData}

        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                {/* <Button variant="contained" color="success" onClick={handleSubmit}>Guardar &nbsp; <i className="fad fa-save"></i></Button> */}
            </Grid>
        </Grid>
        <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />
    </Container>
  );
}

EditarReporte.prototype = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(EditarReporte)