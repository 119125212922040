import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import axios from 'axios';
import { DateTime } from "luxon";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from '@mui/material/TablePagination';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import EditIcon from '@mui/icons-material/Edit';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';

// Custom components and functions
import CustomChip from '../../utils/components/CustomChip';
import CustomLinearProgress from '../../utils/components/CustomLinearProgress';
import { getDate, getProgress, getGarantia, formatMoney } from '../../utils/functions/UtilsJuvalfri';
import useWindowDimensions from '../../utils/functions/useWindowDimensions';
import { status } from '../../utils/functions/UtilsJuvalfri';
import { partidas } from '../../utils/functions/UtilsJuvalfri';
import { hospitales } from '../../utils/functions/UtilsJuvalfri';

// Styled components
import styled from 'styled-components';

const HeadText = styled.p`
    font-weight: 700;
    margin: 0px;
    font-size: 1.1em;
    padding: 0px;
`

const TextBold = styled.p`
  font-weight: 700;
  margin: 0px;
`

function createData(id, noReporte, estatus, equipo, marca, modelo, 
  noSerie, noInventario, hospital, fechaReporte, fechaAutorizacion, 
  area, fallaReportada, observaciones, noContrato, refacciones, 
  fechaDiagnostico, recomendaciones, cotizacion, fechaServicio, servicio) {
  return {
    id,
    noReporte,
    estatus,
    equipo,
    marca,
    modelo,
    noSerie,
    noInventario,
    hospital,
    fechaReporte,
    fechaAutorizacion,
    area,
	  fallaReportada,
    observaciones,
    noContrato,
    refacciones,
    fechaDiagnostico,
    recomendaciones,
    cotizacion,
    fechaServicio,
    servicio
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}
        onDoubleClick={() => setOpen(!open)}
        selected={open}
        hover
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            style={{transform: 'scale(0.8)'}}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center" style={{width: '60px'}}>
          <TextBold>{row.noReporte}</TextBold>
        </TableCell>
        <TableCell align="center" style={{width: '120px'}}>
          <CustomChip label={row.estatus} variant='filled' estatus={row.estatus}/>
          {row.estatus === 'AUTORIZADO' || row.estatus === 'EN REPARACIÓN' ? <CustomLinearProgress progress={getProgress(row.refacciones)} /> : null}
        </TableCell>
        <TableCell align="center">{row.equipo}</TableCell>
        <TableCell align="center">{row.hospital}</TableCell>
        <TableCell align="center">{row.marca}</TableCell>
        <TableCell align="center">{row.modelo}</TableCell>
        <TableCell align="center">{row.noSerie}</TableCell>
        <TableCell align="center">{row.noInventario}</TableCell>
        <TableCell align="center">{row.area}</TableCell>
        <TableCell align="center">{getDate(row.fechaReporte, 'dd/MM/yyyy')}</TableCell>
        <TableCell align="center">{getDate(row.fechaAutorizacion, 'dd/MM/yyyy')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <RenderDetails row={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function TablaReportes(props) {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState({estatus: 'ESTATUS', equipo:'EQUIPO', hospital:'HOSPITAL', noReporte: '', noInventario: ''});
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const roundButton1 = useRef(null);
    const roundButton2 = useRef(null);
    const roundButton3 = useRef(null);
    const [data2, setData2] = useState([]);
    const { height, width } = useWindowDimensions();

    useEffect(() => {
      if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
    }, [props])

    useEffect(() => {
      getData();
    }, [])

    useEffect(() => {
      if(data){ 
        const tmp = data.map((row) => {
          return createData( row._id, row.noReporte, row.estatus, row.equipo, row.marca, row.modelo, 
            row.noSerie, row.noInventario, row.hospital, row.fecha.reporte, row.fecha.autorizacion, 
            row.area, row.fallaReportada, row.observaciones, row.noContrato, row.refacciones,
            row.fecha.diagnostico, row.recomendaciones, row.cotizacion, row.fecha.servicio, row.servicio
            )
        })
        setRows(tmp);
      }
    }, [data])

    const getData = async () => {
      try {
          const res = await axios.get('/api/reportes');
          if(res.status === 200) {
              setData(res.data);
              setData2(res.data);
              document.title = 'Reportes';
          }
      } catch (err) {
          console.log(err);
          alert('Ocurrió un error al cargar los reportes, favor de intentarlo mas tarde');
      }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const actions = [
      { icon: <FileCopyIcon />, name: 'Nuevo Reporte' },
      { icon: <SaveIcon />, name: 'Descargar Tabla' },
      { icon: <RefreshIcon />, name: 'Actualizar' },
    ];

  useEffect(() => {
    simpleFilter()
  }, [filter])

  const simpleFilter = () => {
      let est, hosp, eq;

      if(filter.estatus === 'TODOS' || filter.estatus === 'ESTATUS'){
          est = /[a-z]/i;
      } else {
          est = new RegExp(filter.estatus, 'i');
      }

      if(filter.hospital === 'TODOS' || filter.hospital === 'HOSPITAL'){
          hosp = /[a-z]/i;
      } else {
          hosp = new RegExp(filter.hospital, 'i');
      }

      if(filter.equipo === 'TODOS' || filter.equipo === 'EQUIPO'){
          eq = /[a-z]/i;
      } else {
          eq =  partidas.indexOf(filter.equipo) === 0 ? /electrocard/i: 
                  partidas.indexOf(filter.equipo) === 1 ? /tococard|cardiotoc|fonod|cardiotóc|cardiótoc/i : 
                  partidas.indexOf(filter.equipo) === 2 ? /monitor/i : 
                  partidas.indexOf(filter.equipo) === 3 ? /mesa/i : 
                  partidas.indexOf(filter.equipo) === 4 ? /autoclave|esterilizad/i : ''
      }

      let arrTmp = [];

      if(filter.noReporte !== '') {
        if(data2.find(x => x.noReporte === Number(filter.noReporte))) {
          arrTmp.push(data2.find(x => x.noReporte === Number(filter.noReporte)));
        }
      } else if(filter.noInventario !== '') {
        const noInv = new RegExp(filter.noInventario, 'i');
        arrTmp = data2.filter(x => est.test(x.estatus) && hosp.test(x.hospital) && eq.test(x.equipo) && noInv.test(x.noInventario));
      } else {
        arrTmp = data2.filter(x => est.test(x.estatus) && hosp.test(x.hospital) && eq.test(x.equipo));
      }

      if(arrTmp !== undefined && arrTmp.length < 1) {
          setData([]);
      } else {
          setData(arrTmp);
      }
  }

  const handleClick = (action) => {
    switch(action) {
      case 'Nuevo Reporte': return roundButton1.current.click();
      case 'Descargar Tabla': return roundButton2.current.click();
      case 'Actualizar': return roundButton3.current.click();
    }
  }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 12, left: 12 }}
            icon={<SpeedDialIcon />}
            direction="right"
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => handleClick(action.name)}
              />
            ))}
          </SpeedDial>
            <TableContainer sx={{ height: `${height - 100}px`}}>
                <Table size="small" stickyHeader aria-label="collapsible table">
                    <TableHead>
						<TableRow>
							<TableCell style={{width: '50px'}}/>
							{/* <TableCell align="center"><HeadText># Reporte</HeadText></TableCell> */}
							<TableCell align="center" style={{minWidth: '90px'}}>
                <TextField 
                  id="outlined-basic" 
                  label="# Reporte" 
                  variant="standard" 
                  size="small"
                  value={filter.noReporte} 
                  onChange={(e) => setFilter({...filter, noReporte: e.target.value === '' ? '' : e.target.value})}
                /></TableCell>
							{/* <TableCell align="center"><HeadText>Estatus</HeadText></TableCell> */}
							<TableCell align="center">
                  <TextField
                    id="filled-select-currency-native"
                    select
                    style={{display: 'flex', maxWidth: '200px'}}
                    size='small'
                    label="Estatus"
                    value={filter.estatus}
                    onChange={(e) => setFilter({...filter, estatus: e.target.value})}
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                  >
                    <option>ESTATUS</option>
                    {status.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
              </TableCell>
							{/* <TableCell align="center"><HeadText>Equipo</HeadText></TableCell> */}
							<TableCell align="center">
                  <TextField
                    id="filled-select-currency-native"
                    select
                    style={{display: 'flex', maxWidth: '200px'}}
                    label="Equipo"
                    size='small'
                    value={filter.equipo}
                    onChange={(e) => setFilter({...filter, equipo: e.target.value})}
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                  >
                    <option>EQUIPO</option>
                    {partidas.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </TextField>
              </TableCell>
              {/* <TableCell align="center" style={{width: '200px !important'}}><HeadText>Hospital</HeadText></TableCell> */}
							<TableCell align="center">
                <TextField
                  id="filled-select-currency-native"
                  select
                  style={{display: 'flex', maxWidth: '200px', minWidth: '120px'}}
                  label="Hospital"
                  size='small'
                  value={filter.hospital}
                  onChange={(e) => setFilter({...filter, hospital: e.target.value})}
                  SelectProps={{
                    native: true,
                  }}
                  variant="standard"
                >
                  <option>HOSPITAL</option>
                  {hospitales.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </TableCell>
							<TableCell align="center"><HeadText>Marca</HeadText></TableCell>
							<TableCell align="center"><HeadText>Modelo</HeadText></TableCell>
							<TableCell align="center" style={{width: '110px !important'}}><HeadText>No. Serie</HeadText></TableCell>
							{/* <TableCell align="center"><HeadText>No. Inventario</HeadText></TableCell> */}
              <TableCell align="center" style={{minWidth: '90px', maxWidth: '120px'}}>
                <TextField 
                  id="outlined-basic" 
                  label="No. Inventario" 
                  variant="standard" 
                  size="small"
                  value={filter.noInventario} 
                  onChange={(e) => setFilter({...filter, noInventario: e.target.value === '' ? '' : e.target.value})}
                /></TableCell>
							<TableCell align="center"><HeadText>Área</HeadText></TableCell>
							<TableCell align="center"><HeadText>Fecha de Reporte</HeadText></TableCell>
							<TableCell align="center"><HeadText>Fecha de Autorización</HeadText></TableCell>
						</TableRow>
                    </TableHead>
                    <TableBody>
                    {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                            <Row key={row.id} row={row} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 25, { value: -1, label: 'Todo' }]}
                style={{position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#1F6D58', color: 'white', display: 'flex', justifyContent: 'center'}}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Link className='d-none' ref={roundButton1} to={{pathname: '/reportes/nuevo/'}}>
                <button>Nuevo Reporte</button>
            </Link>
            <Link target='_blank' className='d-none' ref={roundButton2} to={{pathname: '/reportes/descargar/'}}>
                <button>Descargar Tabla</button>
            </Link>
              <button className='d-none' ref={roundButton3} onClick={() => getData()}>Actualizar</button>
        </Paper>
    );
}

function RenderDetails(props) {
  const garantia = getGarantia(props.row.fechaServicio);

	return(
		<>
			<Box className='py-2'>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-arround'}}>
            <Link target='_blank' to={{pathname: '/reportes/editar/' + props.row.id, state: {id: props.row.id}}} style={{textDecoration: 'none'}}>
              <Button variant='contained' color='secondary' className='mx-2'>Editar Reporte &nbsp; <i className='fad fa-file-edit'></i></Button>
            </Link>
            <Link target='_blank' to={{pathname: '/reportes/cotizacion/' + props.row.id, state: {id: props.row.id}}} style={{textDecoration: 'none'}}>
              <Button variant='contained' color='primary' className='mx-2'>Cotización &nbsp; <i className='fad fa-edit'></i></Button>
            </Link>
            <Link target='_blank' to={{pathname: '/reportes/hojaDiagnostico/' + props.row.id, state: {id: props.row.id}}} style={{textDecoration: 'none'}}>
              <Button variant='contained' color='info' className='mx-2'>Hoja de Diagnostico &nbsp; <i className='fad fa-info-circle'></i></Button>
            </Link>
            <Link target='_blank' to={{pathname: '/reportes/ordenServicio/' + props.row.id, state: {id: props.row.id}}} style={{textDecoration: 'none'}}>
              <Button variant='contained' color='info' className='mx-2'>Orden de Servicio &nbsp; <i className='fad fa-info-circle'></i></Button>
            </Link>
            <Link target='_blank' to={{pathname: '/reportes/seguimiento/' + props.row.id, state: {id: props.row.id}}} style={{textDecoration: 'none'}}>
              <Button variant='contained' sx={{color: 'primary'}} className='mx-2'>Seguimiento &nbsp; <i className='fad fa-tasks'></i></Button>
            </Link>
          </div>
        </div>
        <hr></hr>
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          <div style={{fontSize: '0.9em', color: '#000', maxWidth: '350px'}} >
            <p className='text-bold pb-0 mb-0 text-center'>
              Datos del Equipo
            </p>
            <hr className='my-1' />
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'># Reporte:</p>
                <p className='mb-1 px-1'>{props.row.noReporte}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Estatus:</p>
                <CustomChip label={props.row.estatus} variant='outlined' estatus={props.row.estatus}/>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='my-1 px-1 d-flex align-items-center text-bold'>Equipo:</p>
                <p className='my-1 px-1'>{props.row.equipo}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Marca:</p>
                <p className='mb-1 px-1'>{props.row.marca}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Modelo:</p>
                <p className='mb-1 px-1'>{props.row.modelo}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>No. Serie:</p>
                <p className='mb-1 px-1'>{props.row.noSerie}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>No. Inventario:</p>
                <p className='mb-1 px-1'>{props.row.noInventario}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-0 px-1 d-flex align-items-center text-bold'>Falla:</p>
                <p className='mb-0 px-1' style={{maxWidth: '250px'}}>{props.row.fallaReportada}</p>
            </div>
            <hr className='my-1' />
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Hospital:</p>
                <p className='mb-1 px-1'>{props.row.hospital}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Área:</p>
                <p className='mb-1 px-1'>{props.row.area}</p>
            </div>
            <hr className='my-1' />
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Reporte:</p>
                <p className='mb-1 px-1'>{getDate(props.row.fechaReporte, 'DDDD').toUpperCase()}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Diagnostico:</p>
                <p className='mb-1 px-1'>{getDate(props.row.fechaDiagnostico, 'DDDD').toUpperCase()}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Autorización:</p>
                <p className='mb-1 px-1'>{getDate(props.row.fechaAutorizacion, 'DDDD').toUpperCase()}</p>
            </div>
            <div className='d-flex align-items-stretch'>
                <p style={{width: '100px'}} className='mb-0 px-1 d-flex align-items-center text-bold'>Servicio:</p>
                <p className='mb-0 px-1'>{getDate(props.row.fechaServicio, 'DDDD').toUpperCase()}</p>
            </div>
            <hr className='my-1' />
            <div className='d-flex align-items-stretch'>
              {props.row.fechaServicio ?
              <>
                <p 
                  style={{width: '350px', color: `${garantia.days <= 60 ? '#206922' : '#c4362c'}`}}
                  className='mb-1 px-1 d-flex align-items-center text-bold'
                >
                    {garantia.text}
                </p>
              </> : <></>}
            </div>
          </div>

          <div className='px-3'>
            {props.row.refacciones.length > 0 ? 
                <div style={{fontSize: '0.9em', color: '#000', maxWidth: '350px'}} >
                    <p className='text-bold pb-0 mb-0 text-center'>
                      Refacciones
                    </p>
                    <hr className='my-1' />
                    {props.row.refacciones.map(d => (
                        <div key={d.key} className='d-flex align-items-stretch'>
                            <p style={{minWidth: '20px'}} className='my-1 px-1 text-bold d-flex align-items-center'>{d.cantidad}</p>
                            <p className='my-1 px-1 text-bold d-flex align-items-center justify-content-end' style={{color: `${d.estatus ? '#206922' : '#c4362c'}`}}>{d.estatus ? '✔' : '⚠'}</p>
                            <p style={{maxWidth: '315px'}} className='my-1 px-1'>{d.refaccion}</p>
                        </div>
                    ))}
                    <CustomLinearProgress progress={getProgress(props.row.refacciones)} />
                    <div className='d-flex align-items-stretch'>
                      <p className='my-1 px-1'>{`${props.row.servicio.horas} horas de servicio -> $ ${formatMoney(props.row.servicio.importe)}`}</p>
                    </div>
                    <div className='d-flex align-items-stretch'>
                      <p style={{width: '100px'}} className='my-1 px-1 d-flex align-items-center text-bold'>Subtotal:</p>
                      <p className='my-1 px-1'>$ {formatMoney(props.row.cotizacion.subtotal)}</p>
                    </div>
                    <div className='d-flex align-items-stretch'>
                      <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>IVA:</p>
                      <p className='mb-1 px-1'>$ {formatMoney(props.row.cotizacion.iva)}</p>
                    </div>
                    <div className='d-flex align-items-stretch'>
                      <p style={{width: '100px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Total:</p>
                      <p className='mb-1 px-1'>$ {formatMoney(props.row.cotizacion.total)}</p>
                    </div>
                </div> : 
                <div style={{fontSize: '0.9em', color: '#000', maxWidth: '350px'}} >
                  <p className='text-bold pb-0 mb-0 text-center'>
                    Refacciones
                  </p>
                  <hr className='my-1' />
                  <p className='pb-0 mb-0 text-center'>
                    No se han agregado refacciones a este equipo.
                  </p>
                </div>
            }
          </div>
          <div className='px-3'>
            <p className='text-bold pb-0 mb-0 text-center' style={{fontSize: '0.9em'}}>
              Mas Información
            </p>
            <hr className='my-1' />
            <div className='d-flex align-items-stretch' style={{fontSize: '0.9em'}}>
                <p style={{width: '120px'}} className='mb-1 px-1 d-flex align-items-cente text-bold'>Observaciones:</p>
                <p className='mb-1 px-1' style={{maxWidth: '230px'}}>{props.row.observaciones.toUpperCase()}</p>
            </div>
            <div className='d-flex align-items-stretch' style={{fontSize: '0.9em'}}>
                <p style={{width: '120px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>Recomendaciones:</p>
                <p className='mb-1 px-1' style={{maxWidth: '230px'}}>{props.row.recomendaciones}</p>
            </div>
            <div className='d-flex align-items-stretch' style={{fontSize: '0.9em'}}>
                <p style={{width: '120px'}} className='mb-1 px-1 d-flex align-items-center text-bold'>No. Contrato:</p>
                <p className='mb-1 px-1'>{props.row.noContrato}</p>
            </div>
          </div>
        </div>
			</Box>
		</>
	)
}

TablaReportes.prototype = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(TablaReportes)