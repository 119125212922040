import * as React from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

export default function CustomCard(props) {
  return (
    <Box sx={{ minWidth: 275 }} className='d-flex h-100'>
      <Card className='text-center d-flex flex-column justify-content-between p-2' elevation={3}>
        <div>
          <Box className='mb-2' sx={{color: 'primary.dark'}}>
            <i className={props.cardIco}></i>
          </Box>
        </div>
        <div>
          <Typography style={{fontWeight: 700}} variant="h6" component="div">
            {props.title}
          </Typography>
          <Typography variant="body1" className='p-1'>
            {props.text}
          </Typography>
        </div>
        <div>
          <Divider variant="middle"  className='my-1'/>
          <CardActions style={{display: 'flex', justifyContent: 'center'}}>
            <Link to={props.to} style={{textDecoration: 'none'}}>
              <Button variant='outlined'>{props.button} &nbsp; {props.buttonIco ?<i className={props.buttonIco}></i> : <i className="fad fa-arrow-circle-right"></i>}</Button>
            </Link>
          </CardActions>
        </div>
      </Card>
    </Box>
  );
}