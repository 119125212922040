import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import axios from 'axios';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import { Box, Divider, Typography, Grid, Button, Container } from '@mui/material';

import CustomMessage from '../../utils/components/CustomMessage';
import CustomChip from '../../utils/components/CustomChip';
import { equipos } from '../../utils/functions/UtilsJuvalfri';
import { hospitales } from '../../utils/functions/UtilsJuvalfri';

function NuevoReporte(props) {
    const [equipo, setEquipo] = useState('');
    const [marca, setMarca] = useState('');
    const [modelo, setModelo] = useState('');
    const [noSerie, setNoSerie] = useState('');
    const [noInventario, setNoInventario] = useState('');
    const [fechaDeReporte, setFechaDeReporte] = useState(new Date());
    const [hospital, setHospital] = useState('');
    const [area, setArea] = useState('');
    const [falla, setFalla] = useState('');
    const [noContrato, setNoContrato] = useState('');
    const [apiRes, setApiRes] = useState({show: false, message: '', severity: ''});

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
    }, [props])

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show}})
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        const data = {
            equipo, marca, modelo, noSerie, noInventario, fechaDeReporte, hospital, area, noContrato, falla,
        };
        
        try {
            const res = await axios.post('/api/reportes', data);
            if(res.status === 200) {
                setApiRes(state => { return{ ...state, show: true, message: 'El reporte fue creado con exito', severity: 'success' }});
                setTimeout(() => {
                    props.history.push('/reportes');
                  }, 2000);
            }
        } catch (err) {
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al crear el documento, favor de intentarlo mas tarde', severity: 'error' }});
            console.log(err);
        }
    }

  return (
    <Container maxWidth='xl' style={{marginTop: '10px'}}>
        <Box className='text-center' >
            <Typography variant='h5' style={{fontWeight: 700}}>Nuevo Reporte</Typography>
        </Box>
        
        <Divider className='my-3' textAlign="left">
            <CustomChip label='Informacion del reporte' className='text-bold'/>
        </Divider>

        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <Autocomplete
                            disablePortal
                            freeSolo={true}
                            onInputChange={(event, newInputValue) => setEquipo(newInputValue)}
                            inputValue={equipo}
                            aria-multiline='true'
                            id="combo-box-demo"
                            options={equipos}
                            renderInput={(params) => <TextField {...params} label='Equipo' />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Marca" variant="outlined" value={marca} onChange={(e) => setMarca(e.target.value)} />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Modelo" variant="outlined" value={modelo} onChange={(e) => setModelo(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="No. Serie" variant="outlined" multiline={true} value={noSerie} onChange={(e) => setNoSerie(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="No. Inventario" variant="outlined" value={noInventario} onChange={(e) => setNoInventario(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <MobileDatePicker
                            label="Fecha de Reporte"
                            inputFormat="dd/MM/yyyy"
                            value={fechaDeReporte}
                            onChange={(newValue) => setFechaDeReporte(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <Autocomplete
                            disablePortal
                            freeSolo={true}
                            onInputChange={(event, newInputValue) => setHospital(newInputValue)}
                            inputValue={hospital}
                            aria-multiline='true'
                            id="combo-box-demo"
                            options={hospitales}
                            renderInput={(params) => <TextField {...params} label='Unidad Hospitalaria' />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Área" variant="outlined" value={area} onChange={(e) => setArea(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Falla Reportada" variant="outlined" multiline={true} value={falla} onChange={(e) => setFalla(e.target.value)} />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="No. Contrato" variant="outlined" value={noContrato} onChange={(e) => setNoContrato(e.target.value)} />
                    </FormControl>
                </Grid>
            </Grid>
            <hr></hr>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" color="success" onClick={handleSubmit}>Guardar &nbsp; <i className="fad fa-save"></i></Button>
                </Grid>
            </Grid>
        </div>
        {apiRes.show ? 
            <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />    
        : <></>}
    </Container>
  );
}

NuevoReporte.prototype = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(NuevoReporte)