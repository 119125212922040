import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import CustomMessage from '../../utils/components/CustomMessage';
import { Box, Card, CardActions, Fab, Button, Typography, Divider, Grid, Container } from '@mui/material';

import { formatMoney, getVigencia } from '../../utils/functions/UtilsJuvalfri';

const Contratos = (props) => {

    const [contratos, setContratos] = useState([]);
    const [data, setData] = useState([]);
    const [apiRes, setApiRes] = useState({ show: false, message: '', severity: '' });
    const [render, setRender] = useState(null);

    useEffect(() => {
        if (props.auth.user.rol !== 'Admin') {
            props.history.push("/dashboard");
        }
        document.title = 'Nueva factura';
    }, [props])

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show } })
    }

    const getData = async () => {
        try {
            const res = await axios.get('/api/contratos');
            if (res.status === 200) {
                setContratos(res.data);
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return { ...state, show: true, message: 'Ocurrió un error al cargar los contratos, intente más tarde', severity: 'error' } });
        }
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if (contratos.length > 0) {
            setRender(<div>
                <Grid container spacing={1} >
                    {contratos.map(c => (
                        <Grid item xs={12} sm={6} md={3} key={c._id}>
                            <Box sx={{ minWidth: 275 }} className='d-flex h-100'>
                                <Card className='text-center d-flex flex-column justify-content-between p-2' elevation={3}>
                                    <div>
                                        <Box className='mb-1 text-bold' sx={{ color: 'primary.dark' }}>
                                            <p>Contrato</p>
                                        </Box>
                                    </div>
                                    <div>
                                        <Typography style={{ fontWeight: 700 }} variant="h6" component="div">
                                            {c.contrato.noContrato}
                                        </Typography>
                                        <Typography variant="body1" className='p-1'>
                                            {c.contrato.dependencia}
                                        </Typography>
                                        <Typography variant="body2" className='p-1'>
                                            {getVigencia(c.contrato.fechaInicio, c.contrato.fechaFin)}
                                        </Typography>
                                        <Typography variant="body2" className='p-1'>
                                            $ {formatMoney(c.contrato.monto)}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Divider variant="middle" className='my-1' />
                                        <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Link to={`/contratos/editar/${c._id}`} style={{ textDecoration: 'none' }}>
                                                <Button variant='outlined'>Editar &nbsp;<i className='fad fa-edit'></i></Button>
                                            </Link>
                                        </CardActions>
                                    </div>
                                </Card>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </div>
            )
        }
    }, [contratos])

    return (
        <Container maxWidth={'xl'} style={{ marginTop: '10px' }}>
            {render}
            <Link to='/contratos/nuevo'>
                <Fab aria-label="add" style={{ position: 'fixed', bottom: '24px', left: '12px', zIndex: '10', backgroundColor: '#66bb6a', color: '#FFF' }}>
                    <i className="fad fa-plus fa-2x"></i>
                </Fab>
            </Link>
            <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />
        </Container>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Contratos);