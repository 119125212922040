import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../../actions/authActions';
import axios from 'axios';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Fab from '@mui/material/Fab';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Box, Divider, Typography, Grid, Button, Container, ListItem, ListItemButton, 
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@mui/material';

import CustomChip from '../../../utils/components/CustomChip';

import CustomMessage from '../../../utils/components/CustomMessage';
import { status } from '../../../utils/functions/UtilsJuvalfri';
import { equipos } from '../../../utils/functions/UtilsJuvalfri';
import { hospitales } from '../../../utils/functions/UtilsJuvalfri';

function Seguimiento(props) {

    const [data, setData] = useState([]);
    const [checklist, setChecklist] = useState([]);
    const [compra, setCompra] = useState([]);
    const [refacciones, setRefacciones] = useState([]);
    const [observaciones, setObservaciones] = useState([]);
    const [renderData, setRenderData] = useState(<></>);
    const [renderRefacciones, setRenderRefacciones] = useState(<></>);

    const [modalShow, setModalShow] = useState(false);
    const [renderModal, setRenderModal] = useState(<></>);
    const [modalEdit, setModalEdit] = useState(false);
    const [currentEditRef, setCurrentEditRef] = useState(null);

    const [inventario, setInventario] = useState(null);
    const [autocompleteRef, setAutocompleteRef] = useState('');
    const [currentRef, setCurrentRef] = useState({});
    const [cantidadEdit, setCantidadEdit] = useState(0);

    const [apiRes, setApiRes] = useState({show: false, message: '', severity: ''});

    let { id } = useParams();

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
    }, [props])

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            let res;
            if(props.location.state !== undefined) {
                res = await axios.get(`/api/reportes/${props.location.state.id}`);
            } else {
                res = await axios.get(`/api/reportes/${id}`);
            }
            if(res.status === 200) {
                setData(res.data);
                if(res.data.refacciones){
                    setRefacciones(res.data.refacciones);

                    let checks = res.data.refacciones.map(r => {
                        return {
                            key: r.key,
                            estatus: r.estatus ? r.estatus : false,
                        }
                    })
                    setChecklist(checks);

                    let compras = res.data.refacciones.map(r => {
                        return {
                            key: r.key,
                            compra: r.compra ? r.compra : '',
                        }
                    })
                    setCompra(compras);

                    let obs = res.data.refacciones.map(r => {
                        return {
                            key: r.key,
                            observaciones: r.observaciones ? r.observaciones : '',
                        }
                    })
                    setObservaciones(obs);
                }
                document.title = 'Seguimiento Reporte ' + res.data.noReporte;
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al cargar el reporte, favor de intentarlo mas tarde', severity: 'error' }});
        }
    }

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show}})
    }

    const handleSave = async () => {
        let tmp = refacciones.map(r => {
            return {
                key: r.key,
                noCatalogo: r.noCatalogo,
                refaccion: r.refaccion,
                precioUnitario: r.precioUnitario,
                cantidad: r.cantidad,
                estatus: checklist.find(c => c.key === r.key) ? checklist.find(c => c.key === r.key).estatus : false,
                compra: compra.find(c => c.key === r.key) ? compra.find(c => c.key === r.key).compra : '',
                observaciones: observaciones.find(c => c.key === r.key) ? observaciones.find(c => c.key === r.key).observaciones : '',
                inventarioJuvalfri: r.inventarioJuvalfri ? r.inventarioJuvalfri : '',
                refKey: r.refKey ? r.refKey : '',

            }
        })

        try {
            const res = await axios.post(`/api/reportes/updateRefacciones/${data._id}`, {refacciones: tmp})
            if(res.status === 200) {
                setApiRes(state => { return { ...state, show: true, message: 'Seguimiento actualizado', severity: 'success' }});
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return { ...state, show: true, message: 'Ocurrió un error al actualizar la cotización, favor de intentarlo mas tarde', severity: 'error' }});
        }
    }

    const setEstatus = (d, e) => { 
        setChecklist(checklist.map(r => r.key === e ? {key: e, estatus: d} : r));
    }

    const setCompra2 = (d, e) => { 
        setCompra(compra.map(r => r.key === e ? {key: e, compra: d} : r));
    }

    const setObs = (d, e) => { 
        setObservaciones(observaciones.map(r => r.key === e ? {key: e, observaciones: d} : r));
    }

    const handleCloseModal = () => {
        setModalShow(false);
        setModalEdit(false);
        setCurrentEditRef(null);
        setAutocompleteRef('');
        setCurrentRef('');
        setCantidadEdit(0);
    }

    const handleEdit = (d) => {
        setModalEdit(true);
        setModalShow(true);
        getInventario();
        setCurrentEditRef(d);
        setCantidadEdit(d.cantidad);
    }

    const handleDelete = async (d) => {
        alert('Preguntenle a Jorge :v');
    }

    const getInventario = async () => {
        try {
            const res = await axios.get('/api/inventarios');
            if(res.status === 200) {
                setInventario(res.data[0].inventario);
                console.log(res.data[0].inventario);
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return { ...state, show: true, message: 'Ocurrió un error al cargar el inventario, favor de intentarlo mas tarde', severity: 'error' }});
        }
    }

    const handleUpdateRefaccion = async (d, e, r, s) => {
        console.log(inventario);
        let tmp = inventario.find(i => i.key === r.key);
        if(tmp) { 
            tmp.cantidad = tmp.cantidad - s;
            tmp.reporteAsignado = tmp.reporteAsignado ? tmp.reporteAsignado + data.noReporte + ', ' : data.noReporte + ', ';
        }
        tmp = inventario.map(i => i.key === r.key ? tmp : i);
        console.log(tmp);
    }

    useEffect(() => {
        if(data._id) {
            setRenderData(
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: 'flex'}}>
                                <p className='text-bold mb-0'># Reporte:</p>
                                <p className='mb-0 px-2'>{data.noReporte}</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <p className='text-bold mb-0'>Equipo:</p>
                                <p className='mb-0 px-2'>{data.equipo}</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <p className='text-bold mb-0'>Marca:</p>
                                <p className='mb-0 px-2'>{data.marca}</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <p className='text-bold mb-0'>Modelo:</p>
                                <p className='mb-0 px-2'>{data.modelo}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{display: 'flex'}}>
                                <p className='text-bold mb-0'>No. Serie:</p>
                                <p className='mb-0 px-2'>{data.noSerie}</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <p className='text-bold mb-0'>No. Inventario:</p>
                                <p className='mb-0 px-2'>{data.noInventario}</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <p className='text-bold mb-0'>Unidad Hospitalaria:</p>
                                <p className='mb-0 px-2'>{data.hospital}</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <p className='text-bold mb-0'>Área:</p>
                                <p className='mb-0 px-2'>{data.area}</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )
            
            
        }

        if(refacciones.length === 0 ) {
            setRenderRefacciones(
                <>
                    <div>
                        <p className='text-center h5 my-3'>No hay refacciones ni servicios agregados</p>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button variant='contained' color='secondary' className='m-1' onClick={() => handleSave()}>Guardar &nbsp;&nbsp; <i className='fad fa-save' style={{transform: 'scale(1.3)'}}></i></Button>
                    </div>
                </>);
        } else { 
            setRenderRefacciones(
                <>
                    <ListItem disablePadding style={{borderBottom: 'solid #CFD8DC 0.5px'}}>
                        <ListItemButton>
                            <Grid container spacing={1}>
                                <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>#</p>
                                </Grid>
                                <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>Cant.</p>
                                </Grid>
                                <Grid item sm={3} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>Concepto</p>
                                </Grid>
                                <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>Existencia</p>
                                </Grid>
                                <Grid item sm={2} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>Donde se comprará</p>
                                </Grid>
                                <Grid item sm={2} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>Observaciones</p>
                                </Grid>
                                <Grid item sm={2} className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0 text-bold'>Inventario Juvalfri</p>
                                </Grid>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                        {refacciones.length > 0 ? refacciones.map(r => (
                        <ListItem key={r.key} disablePadding style={{borderBottom: 'solid #CFD8DC 0.5px'}}>
                            <ListItemButton>
                                <Grid container>
                                    <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                        <p className='mb-0'>{r.noCatalogo}</p>
                                    </Grid>
                                    <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                        <p className='mb-0'>{r.cantidad}</p>
                                    </Grid>
                                    <Grid item sm={3} className='d-flex align-items-center justify-content-center'>
                                        <p className='mb-0 text-center'>{r.refaccion}</p>
                                    </Grid>
                                    <Grid item sm={1} className='d-flex align-items-center justify-content-center'>
                                        <FormControl sx={{ m: 1 }}>
                                            <Checkbox
                                                checked={checklist.find(c => c.key === r.key) ? checklist.find(c => c.key === r.key).estatus : false}
                                                onChange={(e) => setEstatus(e.target.checked, r.key)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <FormControl className='text-center' sx={{ m: 1, minWidth: 100}} style={{display: 'flex'}}>
                                            <TextField size='small' id="outlined-basic" label="..." variant="outlined" value={compra.find(c => c.key === r.key) ? compra.find(c => c.key === r.key).compra : ''} onChange={(e) => setCompra2(e.target.value, r.key)} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={2}>
                                        <FormControl className='text-center' sx={{ m: 1, minWidth: 100}} style={{display: 'flex'}}>
                                            <TextField size='small' multiline id="outlined-basic" label="..." variant="outlined" value={observaciones.find(c => c.key === r.key) ? observaciones.find(c => c.key === r.key).observaciones : ''} onChange={(e) => setObs(e.target.value, r.key)} />
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item sm={2}>
                                        <FormControl className='text-center' sx={{ m: 1, minWidth: 100}} style={{display: 'flex'}}>
                                            <p>{r.inventarioJuvalfri ? r.inventarioJuvalfri : '...'}</p>
                                            {r.inventarioJuvalfri ? 
                                            <Button size='small' variant='contained' color='error' onClick={() => handleDelete(r)}>Eliminar inventario</Button> : 
                                            <Button size='small' variant='contained' color='info' onClick={() => handleEdit(r)}>Agregar inventario</Button>    
                                        }
                                        </FormControl>
                                    </Grid> */}
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        )) : null
                    }
                </>
            )
        }
    }, [data, refacciones, checklist, compra, observaciones])

    useEffect(() => {
        if(modalShow && inventario !== null && currentEditRef !== null) {
            // console.log(inventario)
            let noInv = '';

            if(typeof currentRef === 'string') {
                if(currentRef.indexOf(' ‣') > -1){
                    noInv = inventario.find(r => r.inventarioJuvalfri === currentRef.split(' ‣')[0])
                } else {
                    noInv = ({inventarioJuvalfri: null, refaccion: currentRef, noCatalogo: 0})
                }

            }
            setRenderModal(
                <div style={{minHeight: '400px'}}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <Autocomplete
                            disablePortal
                            onInputChange={(event, newInputValue) => setAutocompleteRef(newInputValue)}
                            inputValue={autocompleteRef.refaccion}
                            onChange={(event, newInputValue) => setCurrentRef(newInputValue)}
                            value={currentRef ? currentRef.refaccion : ''}
                            id="combo-box-demo"
                            options={inventario.map((option) => `${option.inventarioJuvalfri} ‣ ${option.noCatalogo} - ${option.refaccion}`)}
                            renderInput={(params) => <TextField {...params} label='Refaccion' />}
                        />
                    </FormControl>
                    <p className='my-0 text-bold'>Disponible</p>
                    <Grid container spacing={1} style={{borderBottom: 'solid 1px black'}} className='mb-2 pb-2'>
                        <Grid item xs={12} sm={2}>
                            <p className='mb-0 text-bold text-center'>Inventario Juvalfri</p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className='mb-0 text-bold text-center'>Refacción</p>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <p className='mb-0 text-bold text-center'>Partida</p>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <p className='mb-0 text-bold text-center'>Disponible en almacén</p>
                        </Grid>
                    </Grid>
                    {noInv ? 
                        <Grid container spacing={1} className='mb-2 pb-2'>
                            <Grid item xs={12} sm={2}>
                                <p className='mb-0 text-center'>{noInv.inventarioJuvalfri}</p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <p className='mb-0 text-center'>{noInv.refaccion}</p>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <p className='mb-0 text-center'>{noInv.partida}</p>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <p className='mb-0 text-center'>{noInv.cantidad}</p>
                            </Grid>
                        </Grid> : null}
                    <hr className='my-3' />
                    <p className='my-0 text-bold'>Agregar al reporte</p>
                    {noInv ? 
                        <Grid container spacing={1} style={{borderBottom: 'solid 1px #DDDDDD'}} className='mb-2 pb-2'>
                            <Grid item xs={12} sm={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p className='mb-0 text-center'>{noInv.inventarioJuvalfri}</p>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p className='mb-0 text-center'>{noInv.refaccion}</p>
                            </Grid>
                            <Grid item xs={12} sm={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p className='mb-0 text-center'>{noInv.partida}</p>
                            </Grid>
                            <Grid item xs={12} sm={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                    <TextField id="outlined-basic" label="Cantidad" variant="outlined" multiline={true} value={cantidadEdit} onChange={(e) => setCantidadEdit(e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                                <Button variant='contained' color='secondary' className='m-1 p-2' onClick={() => handleUpdateRefaccion(currentEditRef, currentEditRef.key, noInv, cantidadEdit)}>Agregar &nbsp;&nbsp;<i className='fad fa-cogs' style={{transform: 'scale(1.3)'}}></i></Button>
                            </Grid>
                        </Grid> : null}
                    
                </div>
            )
        }
    }, [inventario, modalShow, currentEditRef, autocompleteRef, currentRef, cantidadEdit])

  return (
    <Container maxWidth='xl' style={{marginTop: '10px'}}>
        <Box className='text-center' >
            <Typography variant='h5' style={{fontWeight: 700}}>Seguimiento</Typography>
        </Box>
        <Divider className='my-2' textAlign="left">
            <CustomChip label='Datos del equipo' variant={'outlined'} color='#842033' borderColor='#842033' className='text-bold'/>
        </Divider>
        {renderData}
        <Divider className='my-3' textAlign="center">
            <CustomChip label='Refacciones' variant={'outlined'} color='#842033' borderColor='#842033' className='text-bold'/>
        </Divider>
        {renderRefacciones}

        <Fab aria-label="add" onClick={() => handleSave()} style={{position: 'fixed', top: '60px', left: '12px', zIndex: '10', backgroundColor: '#66bb6a', color: '#FFF'}}>
            <i className="fad fa-save fa-2x"></i>
        </Fab>

        <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={modalShow}
                className='exo2'
                scroll='paper'
                onClose={() => handleCloseModal()}
            >
                <DialogTitle >Asignación del inventario</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Seleccione una refacción del Inventario para asignarla al equipo.
                    </DialogContentText>
                    {renderModal}
                </DialogContent>
                <DialogActions>
                    <Button color='warning' variant='outlined' onClick={() => handleCloseModal()}>Cerrar</Button>
                </DialogActions>
            </Dialog>

        <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />
    </Container>
  );
}

Seguimiento.prototype = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Seguimiento)