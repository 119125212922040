import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import axios from 'axios';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import { Box, Divider, Typography, Grid, Button, Container, Fab } from '@mui/material';

import CustomMessage from '../../utils/components/CustomMessage';
import CustomChip from '../../utils/components/CustomChip';

function NuevoContrato(props) {
    const [data, setData] = useState({
        noContrato: '',
        fechaInicio: new Date(),
        fechaFin: new Date(),
        dependencia: 'Secretaria de Salud de la Ciudad de México',
        monto: '',
        director: '',
        subdirector: '',
        jud: '',
        unidad: 'Dirección General de Administración y Finanzas',
        direccion: 'Dirección de Recursos Materiales, Abastecimientos y Servicios',
        subdireccion: 'Subdirección de Mantenimiento y Servicios',
        jefatura: 'Jefatura de Unidad Departamental de Mantenimiento de Equipo',
    });
    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());
    const [emisor, setEmisor] = useState({
            denominacion: 'Comercializadora de Servicios Integrales Juvalfri S.A. de C.V.',
            regimenFiscal: '601 - Régimen General de Ley Personas Morales',
            rfc: 'CSI200211-SI1',
            direccion: 'Quiches No. 19 Int. 4, Col. La Raza, Azcapotzalco, México CDMX',
            telefono: '55 4861-7332',
            administradorUnico: 'Ing. Abdias Lopez Reyes'
    });
    const [receptor, setReceptor] = useState({
        denominacion: 'GOBIERNO DE LA CIUDAD DE MÉXICO / SECRETARÍA DE SALUD',
        rfc: 'GDF9712054NA',
        direccion: 'PLAZA DE LA CONSTITUCION S/N, COL. CENTRO DE LA CIUDAD DE MÉXICO AREA 1, CUAUHTÉMOC CIUDAD DE MÉXICO C.P. 06000'
    });
    
    const [apiRes, setApiRes] = useState({show: false, message: '', severity: ''});

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
    }, [props])

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show}})
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault();
        const contrato = {
            contrato: {
                noContrato: data.noContrato,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                dependencia: data.dependencia,
                monto: data.monto,
                director: data.director,
                subdirector: data.subdirector,
                jud: data.jud,
                unidad: data.unidad,
                direccion: data.direccion,
                subdireccion: data.subdireccion,
                jefatura: data.jefatura,
            },
            emisor: {
                denominacion: emisor.denominacion,
                regimenFiscal: emisor.regimenFiscal,
                rfc: emisor.rfc,
                direccion: emisor.direccion,
                telefono: emisor.telefono,
                administradorUnico: emisor.administradorUnico
            },
            receptor: {
                denominacion: receptor.denominacion,
                rfc: receptor.rfc,
                direccion: receptor.direccion,
            },
        };
        
        try {
            const res = await axios.post('/api/contratos', contrato);
            if(res.status === 200) {
                setApiRes(state => { return{ ...state, show: true, message: 'El contrato fue creado con exito', severity: 'success' }});
                setTimeout(() => {
                    props.history.push('/contratos');
                  }, 2000);
            }
        } catch (err) {
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al crear el contrato, favor de intentarlo mas tarde', severity: 'error' }});
            console.log(err);
        }
    }

  return (
    <Container maxWidth='xl' style={{marginTop: '10px'}}>
        <Box className='text-center' >
            <Typography variant='h5' style={{fontWeight: 700}}>Nuevo Contrato</Typography>
        </Box>

        <Fab aria-label="add" onClick={(e) => handleSubmit(e)} style={{position: 'fixed', top: '60px', left: '12px', zIndex: '10', backgroundColor: '#66bb6a', color: '#FFF'}}>
            <i className="fad fa-save fa-2x"></i>
        </Fab>
        
        <Divider className='my-3' textAlign="left">
            <CustomChip label='Datos del contrato' className='text-bold'/>
        </Divider>

        <div>
            <Grid container spacing={3}>

                <Grid item xs={12} md={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="No. Contrato" variant="outlined" multiline={true} value={data.noContrato} onChange={(e) => setData({...data, noContrato: e.target.value})} />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={2}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <MobileDatePicker
                            label="Fecha de inicio"
                            inputFormat="dd/MM/yyyy"
                            value={fechaInicio}
                            onChange={(newValue) => setFechaInicio(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={2}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <MobileDatePicker
                            label="Fecha de termino"
                            inputFormat="dd/MM/yyyy"
                            value={fechaFin}
                            onChange={(newValue) => setFechaFin(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Dependecia" variant="outlined" multiline={true} value={data.dependencia} onChange={(e) => setData({...data, dependencia: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Monto" variant="outlined" type={'number'} multiline={true} value={data.monto} onChange={(e) => setData({...data, monto: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Director" variant="outlined" multiline={true} value={data.director} onChange={(e) => setData({...data, director: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Sub Director" variant="outlined" multiline={true} value={data.subdirector} onChange={(e) => setData({...data, subdirector: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="JUD" variant="outlined" multiline={true} value={data.jud} onChange={(e) => setData({...data, jud: e.target.value})} />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Dirección" variant="outlined" multiline={true} value={data.direccion} onChange={(e) => setData({...data, direccion: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Sub Dirección" variant="outlined" multiline={true} value={data.subdireccion} onChange={(e) => setData({...data, subdireccion: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Jefatura" variant="outlined" multiline={true} value={data.jefatura} onChange={(e) => setData({...data, jefatura: e.target.value})} />
                    </FormControl>
                </Grid>
                
            </Grid>
        </div>

        <Divider className='my-3' textAlign="left">
            <CustomChip label='Datos del Emisor' className='text-bold'/>
        </Divider>

        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={5}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Denominación" variant="outlined" multiline={true} value={emisor.denominacion} onChange={(e) => setEmisor({...emisor, denominacion: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Régimen Fiscal" variant="outlined" type={'number'} multiline={true} value={emisor.regimenFiscal} onChange={(e) => setEmisor({...emisor, regimenFiscal: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="RFC" variant="outlined" multiline={true} value={emisor.rfc} onChange={(e) => setEmisor({...emisor, rfc: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Dirección" variant="outlined" multiline={true} value={emisor.direccion} onChange={(e) => setEmisor({...emisor, direccion: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Telefono" variant="outlined" multiline={true} value={emisor.telefono} onChange={(e) => setEmisor({...emisor, telefono: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Administrador único" variant="outlined" multiline={true} value={emisor.administradorUnico} onChange={(e) => setEmisor({...emisor, administradorUnico: e.target.value})} />
                    </FormControl>
                </Grid>
            </Grid>
        </div>

        <Divider className='my-3' textAlign="left">
            <CustomChip label='Datos del Receptor' className='text-bold'/>
        </Divider>

        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={5}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Denominación" variant="outlined" multiline={true} value={receptor.denominacion} onChange={(e) => setReceptor({...receptor, denominacion: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={7}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="Dirección" variant="outlined" multiline={true} value={receptor.direccion} onChange={(e) => setReceptor({...receptor, direccion: e.target.value})} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                        <TextField id="outlined-basic" label="RFC" variant="outlined" multiline={true} value={receptor.rfc} onChange={(e) => setReceptor({...receptor, rfc: e.target.value})} />
                    </FormControl>
                </Grid>
            </Grid>

            <hr></hr>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Button className='mb-3' variant="contained" color="success" onClick={handleSubmit}>Guardar &nbsp; <i className="fad fa-save"></i></Button>
                </Grid>
            </Grid>
        </div>
        {apiRes.show ? 
            <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />    
        : <></>}
    </Container>
  );
}

NuevoContrato.prototype = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(NuevoContrato)