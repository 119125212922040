import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { Button } from 'react-bootstrap';

import { formatMoney, getDate } from '../../utils/functions/UtilsJuvalfri';

const TablaGeneralReportes = (props) => {

    const [data, setData] = useState(null);
    const [render, setRender] = useState(<></>);

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
        }
    }, [props])

    const getData = async () => {
        try {
            const res = await axios.get('/api/reportes');
            if(res.status === 200) {
                setData(res.data.slice(0).reverse());
            } else {
                console.log('Hola :D');
            }
        } catch(e) {
            console.log(e)
        }
    }
    
    useEffect(() => {
        getData();
        document.title = 'Tabla General de Reportes';
    }, [])

    useEffect(() => {
        if(data !== null) {
            setRender(
                <div>
                    <br />
                    <p className='text-bold'>Tabla general de reportes con refacciones por equipo</p>
                    <table id='table-to-xls' style={{borderCollapse: 'collapse', border: '1px solid black', width: '100%', fontFamily: 'Arial', fontSize: '0.7em'}}>
                        <thead>
                            <tr className='text-center' style={{borderCollapse: 'collapse', border: '1px solid black', backgroundColor: '#9bc2e6'}}>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'70px'}}># Reporte</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Unidad Hospitalaria</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Equipo</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'120px'}}>Marca</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Modelo</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'120px'}}>No. Serie</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>No. Inventario</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Fecha de reporte</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Fecha de autorización</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Cantidad</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Refacción</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Estatus</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Subtotal</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>IVA</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, j) =>
                            <>
                                {d.refacciones.map((dr, i) => (
                                    <tr key={dr.key} className='text-center'>
                                        {i < 1 ? 
                                            <>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{d.noReporte}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'150px'}}>{d.hospital}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'150px'}}>{d.equipo}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.marca}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{d.modelo}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width: '100px'}}>{d.noSerie}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.noInventario}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{getDate(d.fecha.reporte, 'dd/MM/yyyy')}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{getDate(d.fecha.autorizacion, 'dd/MM/yyyy')}</td>
                                            </> : <></>}
                                        <td colSpan='1'  style={{borderCollapse: 'collapse', border: '1px solid black'}}>{dr.cantidad}</td>
                                        <td  style={{borderCollapse: 'collapse', border: '1px solid black'}}>{dr.refaccion}</td>
                                        {i < 1 ? <><td rowSpan={d.refacciones.length} style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.estatus}</td></> : <></>}
                                        {i < 1 ? 
                                            <>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{formatMoney(d.cotizacion.subtotal)}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{formatMoney(d.cotizacion.iva)}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{formatMoney(d.cotizacion.total)}</td>
                                            </> : <></>}
                                    </tr>
                                ))}
                            </>
                            )}
                        </tbody>
                    </table>
                    <hr className='my-3' />

                    <br />
                    <p className='text-bold'>Tabla general de reportes, estatus y montos</p>
                    <table id='table-to-xls' style={{borderCollapse: 'collapse', border: '1px solid black', width: '100%', fontFamily: 'Arial', fontSize: '0.7em'}}>
                        <thead>
                            <tr className='text-center' style={{borderCollapse: 'collapse', border: '1px solid black', backgroundColor: '#9bc2e6'}}>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'70px'}}># Reporte</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Unidad Hospitalaria</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Equipo</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'120px'}}>Marca</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Modelo</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'120px'}}>No. Serie</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>No. Inventario</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Fecha de reporte</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Fecha de autorización</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width: '150px'}}>Estatus</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Subtotal</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>IVA</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d, j) =>
                                <tr key={d._id} className='text-center'>

                                    <td style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{d.noReporte}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black', width:'150px'}}>{d.hospital}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black', width:'150px'}}>{d.equipo}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.marca}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{d.modelo}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black', width: '100px'}}>{d.noSerie}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.noInventario}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{getDate(d.fecha.reporte, 'dd/MM/yyyy')}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{getDate(d.fecha.autorizacion, 'dd/MM/yyyy')}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.estatus}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{formatMoney(d.cotizacion.subtotal)}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{formatMoney(d.cotizacion.iva)}</td>
                                    <td style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{formatMoney(d.cotizacion.total)}</td>

                                </tr>
                            )}
                        </tbody>
                    </table>
                    <hr className='my-3' />
                </div>
            )
        }
    }, [data])

    return (
        <div className='container-fluid' style={{marginTop: '10px'}}>
            {render}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(TablaGeneralReportes)