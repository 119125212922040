import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Container, Box, Typography, Button, Paper } from '@mui/material';

class Landing extends Component {
  render() {
    return (
      <Container maxWidth='sm' style={{marginTop: '10px'}}>
        <Paper elevation={5} className='p-3 p-md-5'>
          <Box className="text-center">
            <img src="./Juvalfri.svg" alt='juvalfri-logo' style={{width: "100%"}} className='mb-4'></img>
            <Typography variant="h5" style={{fontSize: '1.8em', fontWeight: '600'}}>Inicia sesión para continuar</Typography>
            <Link to="/login" style={{textDecoration: 'none'}}>
              <Button size="large" variant="contained" color="primary" style={{marginTop: '20px'}}>Iniciar Sesión &nbsp; <i className="fad fa-sign-in"></i></Button>
            </Link>
          </Box>
        </Paper>
      </Container>
    );
  }
}

export default Landing;
