import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import './styles.css';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Button } from '@mui/material';

import { getDate } from '../../../utils/functions/UtilsJuvalfri';

const StyledPageBreak = styled.div`
    @media print {
        height: ${props => props.height}
    }
`

const StyledHeader = styled.div`
    display: none;
    @media print {
        display: block;
    }
`

function OrdenServicio(props) {

    const [renderHoja, setRenderHoja] = useState(<></>);
    const [data, setData] = useState({});
    const [flag, setFlag] = useState(false);
    const [height, setHeight] = useState(0);
    const [height2, setHeight2] = useState(0);
    const ref = useRef(null);
    const ref2 = useRef(null);
    const [layout, setLayout] = useState(0);
    const [edit, setEdit] = useState(false);
    let { id } = useParams();

    useEffect(() => {
        if (flag) {
            setHeight(ref.current.clientHeight);
            setHeight2(ref2.current.clientHeight);
        }
        if (height2 > 540) {
            setLayout(1);
        }
    }, [height, height2, flag])

    useEffect(() => {
        if (props.auth.user.rol !== 'Admin') {
            props.history.push("/dashboard");
        }
    }, [props])

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            let res;
            if (props.location.state !== undefined) {
                res = await axios.get(`/api/reportes/${props.location.state.id}`);
            } else {
                res = await axios.get(`/api/reportes/${id}`);
            }
            if (res.status === 200) {
                setData(res.data);
                document.title = 'Orden de Servicio' + res.data.noReporte;
            }
        } catch (err) {
            console.log(err);
            alert('Ocurrió un error al cargar los reportes, favor de intentarlo mas tarde');
        }
    }

    const printDocument = () => {
        let printContents = document.getElementById('printDiv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();

    }

    // cambia el modo de edicion del documento
    useEffect(() => {
        if (edit)
            document.designMode = "on";
        else
            document.designMode = "off";
    }, [edit])

    const getServicio = () => {
        if (layout === 0) {
            return (
                <>
                    <div ref={ref2} style={{ width: '770px' }} >
                        <div ref={ref} style={{ border: 'solid black 1px', borderRadius: '5px', marginTop: '18px' }}>
                            <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} className='text-bold mb-0 text-center'>
                                &nbsp;&nbsp;DESCRIPCIÓN DEL SERVICIO
                            </p>
                            <div className='d-flex'>
                                <p style={{ fontSize: '10px', textDecoration: 'underline' }} className='py-4 px-3 text-center'>{data.servicio.descripcion}</p>
                            </div>
                        </div>
                        <div style={{ border: 'solid black 1px', borderRadius: '5px', marginTop: '18px' }}>
                            <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} className='text-bold mb-0 text-center'>
                                &nbsp;&nbsp;REFACCIONES
                            </p>
                            <div className='d-flex'>
                                <div style={{ width: '545px' }} className='d-flex flex-column'>
                                    <div className='d-flex'>
                                        <p className='text-center my-2 text-bold' style={{ width: '100px', fontSize: '10px' }}>CANTIDAD</p>
                                        <p className='text-center my-2 text-bold' style={{ width: '465px', fontSize: '10px' }}>DESCRIPCION</p>
                                    </div>
                                    {
                                        data.refacciones.map(r => (
                                            <div className='d-flex align-items-center' key={r.key}>
                                                <p className='text-center mb-1' style={{ width: '100px', fontSize: '10px', textDecoration: 'underline' }}>{r.cantidad}</p>
                                                <p className='text-center mb-1' style={{ width: '465px', fontSize: '10px', textDecoration: 'underline' }}>{r.refaccion}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div style={{ width: '225px', border: 'solid black 1px', height: `${data.refacciones.length < 5 ? '200px' : 'auto'}`, borderBottomRightRadius: '4px' }}>
                                    <p className='text-center mb-0 text-bold' style={{ width: '205px', fontSize: '10px' }}>SELLO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ marginTop: '80px' }} className='d-flex justify-content-between'>
                            <div style={{ width: '250px' }}>
                                <p className='text-center mb-0'>__________________________</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>RESPONSABLE</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>NOMBRE Y FIRMA</p>
                            </div>
                            <div style={{ width: '250px' }}>
                                <p className='text-center mb-0'>__________________________</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>USUARIO</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>NOMBRE Y FIRMA</p>
                            </div>
                            <div style={{ width: '250px' }}>
                                <p className='text-center mb-0'>__________________________</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>BIOMÉDICA/MANTENIMIENTO</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>NOMBRE Y FIRMA</p>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        if (layout === 1) {
            return (
                <>
                    <div style={{ width: '770px' }} >
                        <div style={{ border: 'solid black 1px', borderRadius: '5px', marginTop: '18px' }}>
                            <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} className='text-bold mb-0 text-center'>
                                &nbsp;&nbsp;DESCRIPCIÓN DEL SERVICIO
                            </p>
                            <div className='d-flex'>
                                <p style={{ fontSize: '10px', textDecoration: 'underline' }} className='py-4 px-3 text-center'>{data.servicio.descripcion}</p>
                            </div>
                        </div>
                        <div style={{ height: `${740 - height}px` }}></div>

                        <div style={{ height: '160px' }}>
                            {edit ? <></> : <img src="/bg-juvalfri.svg" alt='juvalfri-logo' style={{ position: 'absolute', top: '0px', left: '0px', width: "100%", height: 'auto' }} />}
                        </div>

                        <div style={{ position: 'absolute', top: '1136px', right: '23px', width: '290px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p className='mb-0' style={{ fontWeight: '600', fontSize: '14px' }}>
                                    Orden de Servicio &nbsp;<span style={{ fontSize: '1.2em', fontWeight: '700' }}>{data.noReporte}</span> &nbsp; (continuación)
                                </p>
                            </div>
                        </div>

                        <div style={{ border: 'solid black 1px', borderRadius: '5px', marginTop: '18px' }}>
                            <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} className='text-bold mb-0 text-center'>
                                &nbsp;&nbsp;REFACCIONES
                            </p>
                            <div className='d-flex'>
                                <div style={{ width: '545px' }} className='d-flex flex-column'>
                                    <div className='d-flex'>
                                        <p className='text-center my-2 text-bold' style={{ width: '100px', fontSize: '10px' }}>CANTIDAD</p>
                                        <p className='text-center my-2 text-bold' style={{ width: '465px', fontSize: '10px' }}>DESCRIPCION</p>
                                    </div>
                                    {
                                        data.refacciones.map(r => (
                                            <div className='d-flex align-items-center' key={r.key}>
                                                <p className='text-center mb-1' style={{ width: '100px', fontSize: '10px', textDecoration: 'underline' }}>{r.cantidad}</p>
                                                <p className='text-center mb-1' style={{ width: '465px', fontSize: '10px', textDecoration: 'underline' }}>{r.refaccion}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div style={{ width: '225px', border: 'solid black 1px', height: `${data.refacciones.length < 5 ? '200px' : 'auto'}`, borderBottomRightRadius: '4px' }}>
                                    <p className='text-center mb-0 text-bold' style={{ width: '205px', fontSize: '10px' }}>SELLO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ marginTop: '80px' }} className='d-flex justify-content-between'>
                            <div style={{ width: '250px' }}>
                                <p className='text-center mb-0'>__________________________</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>RESPONSABLE</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>NOMBRE Y FIRMA</p>
                            </div>
                            <div style={{ width: '250px' }}>
                                <p className='text-center mb-0'>__________________________</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>USUARIO</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>NOMBRE Y FIRMA</p>
                            </div>
                            <div style={{ width: '250px' }}>
                                <p className='text-center mb-0'>__________________________</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>BIOMÉDICA/MANTENIMIENTO</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>NOMBRE Y FIRMA</p>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        if (layout === 2) {
            return (
                <>
                    <div style={{ width: '705px' }} >
                        <div style={{ border: 'solid black 1px', borderRadius: '10px', marginTop: '18px' }}>
                            <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} className='text-bold mb-0 text-center'>
                                &nbsp;&nbsp;DESCRIPCIÓN DEL SERVICIO
                            </p>
                            <div className='d-flex'>
                                <p style={{ fontSize: '10px', textDecoration: 'underline' }} className='py-4 px-3 text-center'>{data.servicio.descripcion}</p>
                            </div>
                        </div>
                        <div style={{ border: 'solid black 1px', borderRadius: '10px', marginTop: '18px' }}>
                            <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} className='text-bold mb-0 text-center'>
                                &nbsp;&nbsp;REFACCIONES
                            </p>
                            <div className='d-flex'>
                                <div style={{ width: '480px' }} className='d-flex flex-column'>
                                    <div className='d-flex'>
                                        <p className='text-center my-2 text-bold' style={{ width: '100px', fontSize: '10px' }}>CANTIDAD</p>
                                        <p className='text-center my-2 text-bold' style={{ width: '400px', fontSize: '10px' }}>DESCRIPCION</p>
                                    </div>
                                    {
                                        data.refacciones.map(r => (
                                            <div className='d-flex align-items-center' key={r.key}>
                                                <p className='text-center mb-1' style={{ width: '100px', fontSize: '10px', textDecoration: 'underline' }}>{r.cantidad}</p>
                                                <p className='text-center mb-1' style={{ width: '400px', fontSize: '10px', textDecoration: 'underline' }}>{r.refaccion}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div style={{ width: '225px', border: 'solid black 1px', height: `${data.refacciones.length < 5 ? '200px' : 'auto'}`, borderBottomRightRadius: '8.5px' }}>
                                    <p className='text-center mb-0 text-bold' style={{ width: '205px', fontSize: '10px' }}>SELLO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ marginTop: '80px' }} className='d-flex justify-content-between'>
                            <div style={{ width: '250px' }}>
                                <p className='text-center mb-0'>__________________________</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>RESPONSABLE</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>NOMBRE Y FIRMA</p>
                            </div>
                            <div style={{ width: '250px' }}>
                                <p className='text-center mb-0'>__________________________</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>USUARIO</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>NOMBRE Y FIRMA</p>
                            </div>
                            <div style={{ width: '250px' }}>
                                <p className='text-center mb-0'>__________________________</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>BIOMÉDICA/MANTENIMIENTO</p>
                                <p className='text-center mb-0' style={{ color: '#000', fontSize: '10px' }}>NOMBRE Y FIRMA</p>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    useEffect(() => {
        if (data.servicio) {
            document.title = `Servicio ${data.noReporte}-${data.noInventario}`;
            setRenderHoja(
                <div id='printDiv' style={{ position: 'absolute', width: '816px', height: '1056px' }} className='exo2 p-0'>

                    <div style={{ height: '160px' }}>
                        {edit ? <></> : <img src="/bg-juvalfri.svg" alt='juvalfri-logo' style={{ position: 'absolute', top: '0px', left: '0px', width: "100%", height: 'auto' }} />}
                    </div>

                    <div style={{ position: 'absolute', top: '80px', right: '23px', width: '200px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p className='mb-0' style={{ fontWeight: '600', fontSize: '14px' }}>
                                Orden de Servicio &nbsp;<span style={{ fontSize: '1.2em', fontWeight: '700' }}>{data.noReporte}</span>
                            </p>
                        </div>
                    </div>

                    <div style={{ width: '770px', marginLeft: '23px' }}>
                        <div className='d-flex justify-content-between'>
                            <div style={{ width: '375px', border: 'solid black 1.5px', borderRadius: '5px' }}>
                                <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} className='text-bold mb-0 text-center'>
                                    &nbsp;&nbsp;DATOS DEL EQUIPO
                                </p>
                                <div className='d-flex align-items-stretch' style={{ width: '343.44px', color: '#000' }}>
                                    <p style={{ width: '104px', fontSize: '10px' }} className='my-1 px-1 text-bold d-flex align-items-center'>EQUIPO:</p>
                                    <p style={{ width: '239.44px', fontSize: '10px' }} className='my-1 px-1'>{data.equipo.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '343.44px', color: '#000' }}>
                                    <p style={{ width: '104px', fontSize: '10px' }} className='mb-1 px-1 text-bold d-flex align-items-center'>MARCA:</p>
                                    <p style={{ width: '239.44px', fontSize: '10px' }} className='mb-1 px-1'>{data.marca.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '343.44px', color: '#000' }}>
                                    <p style={{ width: '104px', fontSize: '10px' }} className='mb-1 px-1 text-bold d-flex align-items-center'>MODELO:</p>
                                    <p style={{ width: '239.44px', fontSize: '10px' }} className='mb-1 px-1'>{data.modelo.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '343.44px', color: '#000' }}>
                                    <p style={{ width: '104px', fontSize: '10px' }} className='mb-1 px-1 text-bold d-flex align-items-center'>NO. SERIE:</p>
                                    <p style={{ width: '239.44px', fontSize: '10px' }} className='mb-1 px-1'>{data.noSerie.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '343.44px', color: '#000' }}>
                                    <p style={{ width: '104px', fontSize: '10px' }} className='mb-1 px-1 text-bold d-flex align-items-center'>NO. INVENTARIO:</p>
                                    <p style={{ width: '239.44px', fontSize: '10px' }} className='mb-1 px-1'>{data.noInventario.toUpperCase()}</p>
                                </div>
                            </div>
                            <div style={{ width: '375px', border: 'solid black 1.5px', borderRadius: '5px' }}>
                                <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} className='text-bold mb-0 text-center'>
                                    &nbsp;&nbsp;DATOS DEL HOSPITAL
                                </p>
                                <div className='d-flex align-items-stretch' style={{ width: '343.44px', color: '#000' }}>
                                    <p style={{ width: '90px', fontSize: '10px' }} className='my-1 px-1 text-bold d-flex align-items-center'>FECHA:</p>
                                    <p style={{ width: '270.44px', fontSize: '10px' }} className='my-1 px-1'>{data.fecha.facturacion ? (getDate(data.fecha.facturacion, 'DDD')).toUpperCase() : (getDate(data.fecha.servicio, 'DDD')).toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '343.44px', color: '#000' }}>
                                    <p style={{ width: '90px', fontSize: '10px' }} className='mb-1 px-1 text-bold d-flex align-items-center'>HOSPITAL:</p>
                                    <p style={{ width: '270.44px', fontSize: '10px' }} className='mb-1 px-1'>{data.hospital.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '343.44px', color: '#000' }}>
                                    <p style={{ width: '90px', fontSize: '10px' }} className='mb-1 px-1 text-bold d-flex align-items-center'>ÁREA:</p>
                                    <p style={{ width: '270.44px', fontSize: '10px' }} className='mb-1 px-1'>{data.area.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ width: '343.44px', color: '#000' }}>
                                    <p style={{ width: '90px', fontSize: '10px' }} className='mb-1 px-1 text-bold d-flex align-items-center'>NO. CONTRATO:</p>
                                    <p style={{ width: '270.44px', fontSize: '10px' }} className='mb-1 px-1'>{data.noContrato.toUpperCase()}</p>
                                </div>
                            </div>
                        </div>
                        {getServicio()}
                    </div>
                </div>
            )
            setFlag(true);
        } else {
            setRenderHoja(
                <>
                    <p className='h5 m-4'>No se han agregago refacciones ni servicios a este reporte</p>
                </>
            )
        }
    }, [data, layout, edit])

    return (
        <div style={{ background: '#FFF' }}>
            <div style={{ marginTop: '10px', marginBottom: '12px' }}>
                <Button variant='contained' color='info' onClick={() => props.history.goBack()} className='mx-3'>
                    Regresar &nbsp; <i className="fad fa-arrow-circle-left" style={{ transform: 'scale(1.3)' }}></i>
                </Button>
                <Button variant='contained' color='secondary' onClick={printDocument}>Imprimir hoja &nbsp; <i className="fas fa-print" style={{ transform: 'scale(1.3)' }}></i></Button>
                <Button className='mx-2' variant='contained' color='secondary' onClick={() => setEdit(edit => !edit)}>{edit ? 'Finalizar edición' : 'Editar documento'} &nbsp; <i className="fas fa-print" style={{ transform: 'scale(1.3)' }}></i></Button>
            </div>
            {renderHoja}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(OrdenServicio);