import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './stylesActa.css';

import { Button } from '@mui/material'

import { getDate, formatMoney } from '../../utils/functions/UtilsJuvalfri';

function ActaEntrega(props) {

    const [data, setData] = useState(null);
    const [renderHoja, setRenderHoja] = useState(<></>);
    let { id } = useParams();
    const [emisor, setEmisor] = useState({
        denominacion: 'Comercializadora de Servicios Integrales Juvalfri S.A. de C.V.',
        regimenFiscal: '601 - Régimen General de Ley Personas Morales',
        rfc: 'CSI200211-SI1',
        direccion: 'Quiches No. 19 Int. 4, Col. La Raza, Azcapotzalco, México CDMX',
        telefono: '55 4861-7332',
        administradorUnico: 'Ing. Abdias Lopez Reyes'
    });
    const [receptor, setReceptor] = useState({
        denominacion: 'GOBIERNO DE LA CIUDAD DE MÉXICO / SECRETARÍA DE SALUD',
        rfc: 'GDF9712054NA',
        direccion: 'PLAZA DE LA CONSTITUCION S/N, COL. CENTRO DE LA CIUDAD DE MÉXICO AREA 1, CUAUHTÉMOC CIUDAD DE MÉXICO C.P. 06000'
    });
    const [factura, setFactura] = useState({
        noFactura: '',
        cfdi: '',
        fechaEmision: new Date(),
        subTotal: 0.00,
        iva: 0.00,
        total: 0.00,
        reportes: [],
        conceptos: [],
        hospital: '',
        estatus: 'PENDIENTE DE FIRMAS',
        noContrato: 'NO. SSCDMX-DGAF-074-2021',
        vigenciaContrato: 'VIGENCIA DEL 01 DE ENERO AL 31 DE DICIEMBRE DEL 2021',
        ejecucion: 'DEL 13 AL 23 DE ABRIL DEL 2021',
        serie: '',
        folio: '',
        folioActaEntrega: '',
        importeLetra: '',
        dirUnMed: '',
        drmas: 'David Melchor Miranda Ortega',
        jud: 'Ing. Darío Alejandro Alvarado Torres',
        subMan: 'Ing. José Mauricio Pérez Pérez',
        fechaHospital: new Date(),
        fechaServiciosMedicos: new Date(),
        fecha2: new Date(),
        fecha3: new Date(),
        observaciones: '',
    });
    const [acta, setActa] = useState({
        fechaInicio: new Date(),
        fechaTerminacion: new Date(),
        directorUnidadMedica: '',
        subdireccionAdministrativa: '',
        ingenieroBiomedico: '',
        unidadMedica: '',
    });
    const [reportes, setReportes] = useState([]);
    const [cfdi, setCfdi] = useState({});

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            let res;
            if(props.location.state !== undefined) {
                res = await axios.get(`/api/facturas/${props.location.state.id}`);
                if(res.status === 200) {
                    setData(res.data);
                    verifyReports(res.data.factura.reportes);
                    let dtaFac = res.data.factura;
                    dtaFac.fechaHospital = res.data.factura.fechaHospital ? new Date(res.data.factura.fechaHospital) : null;
                    dtaFac.fechaServiciosMedicos = res.data.factura.fechaServiciosMedicos ? new Date(res.data.factura.fechaServiciosMedicos) : null;
                    setFactura(dtaFac);
                    setEmisor(res.data.emisor);
                    setReceptor(res.data.receptor);
                    setActa(res.data.actaEntrega);
                    parseCfdi2(res.data.factura.cfdi, res.data.factura.conceptos);
                }
            } else {
                res = await axios.get(`/api/facturas/${id}`);
                if(res.status === 200) {
                    setData(res.data);
                    let dtaFac = res.data.factura;
                    dtaFac.fechaHospital = res.data.factura.fechaHospital ? new Date(res.data.factura.fechaHospital) : null;
                    dtaFac.fechaServiciosMedicos = res.data.factura.fechaServiciosMedicos ? new Date(res.data.factura.fechaServiciosMedicos) : null;
                    setFactura(dtaFac);
                    setEmisor(res.data.emisor);
                    setReceptor(res.data.receptor);
                    setActa(res.data.actaEntrega);
                    parseCfdi2(res.data.factura.cfdi, res.data.factura.conceptos);
                }
            }
        } catch(err) {
            console.log(err);
            alert('Ocurrió un error al cargar el reporte, favor de intentarlo mas tarde');
        }
    }

    const parseCfdi2 = async(dd, cc) => {
        if(dd !== '') {
            try {
                const res = await axios.post('/api/facturas/parseCfdi', {"cfdi": dd})
                if(res.status === 200) {
                    // console.log(res.data.cfdi2.conceptos);
                    setEmisor(state => {return{... state,
                        denominacion: res.data.cfdi2.emisor.Nombre,
                        rfc: res.data.cfdi2.emisor.Rfc
                    }});
                    setReceptor(state => {return{... state,
                        denominacion: res.data.cfdi2.receptor.Nombre,
                        rfc: res.data.cfdi2.receptor.Rfc
                    }});
                    setFactura(state =>{return{... state,
                        noFactura: res.data.cfdi2.comprobante.Serie + res.data.cfdi2.comprobante.Folio,
                        folio: res.data.cfdi2.comprobante.Folio,
                        serie: res.data.cfdi2.comprobante.Serie,
                        subTotal: res.data.cfdi2.comprobante.SubTotal,
                        iva: res.data.cfdi2.totalImpuestos.TotalImpuestosTrasladados,
                        total: res.data.cfdi2.comprobante.Total,
                        fechaEmision: res.data.cfdi2.comprobante.Fecha,
                    }})

                    let tmpCfdi = res.data.cfdi2;
                    let tmp = res.data.cfdi2.conceptos;
                    
                    res.data.cfdi2.conceptos.forEach((c, i) => {
                        tmp[i]['$'].Descripcion = cc[i];
                    })
                    tmpCfdi.conceptos = tmp;
                    setCfdi(tmpCfdi);
                }
            } catch (e) {
                console.log(e);
                alert('Ocurrió un error con los datos ingresados, favor de revisar los datos e intentarlo de nuevo');
            }
        }
    }

    const printDocument = () => {
        let printContents = document.getElementById('printDiv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();
    }

    const verifyReports = async(repo) => {
        try {
            let tmp = [];
            for( let x of Object.keys(repo)) {
                if(repo[Number(x)].indexOf(',')) {
                    const res = await axios.get(`/api/reportes/getReportByNumber/?reporte=${repo[Number(x)].split(',')[0]}`);
                    if(res.status === 200){
                        tmp.push(res.data.reportes[0]);
                    }
                } else {
                    const res = await axios.get(`/api/reportes/getReportByNumber/?reporte=${repo[Number(x)]}`);
                    if(res.status === 200){
                        tmp.push(res.data.reportes[0]);
                    }
                }
            }
            setReportes(tmp);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if(cfdi && factura) {
            verifyReports(factura.reportes);
        }
    }, [cfdi, factura])

    const getEquipos = () => {
        return(
            <>
                <div className='d-flex justify-content-center'>
                    <div style={{marginTop: '4px', width: '770px'}}>
                        <div className='d-flex align-items-center'>
                            <div style={{width: '50px', height: '33px'}}>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', height: '33px', borderTop: 'solid black 1px', borderLeft: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px 2px 3px 2px'}}>No. Progresivo</p>
                            </div>
                            <div style={{width: '190px', height: '33px'}}>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', height: '33px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>DESCRIPCIÓN DEL EQUIPO</p>
                            </div>
                            <div style={{width: '100px', height: '33px'}}>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', height: '33px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>MARCA</p>
                            </div>
                            <div style={{width: '100px', height: '33px'}}>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', height: '33px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>MODELO</p>
                            </div>
                            <div style={{width: '150px', height: '33px'}}>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', height: '33px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>No. SERIE</p>
                            </div>
                            <div style={{width: '80px', height: '33px'}}>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', height: '33px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>No. INVENTARIO</p>
                            </div>
                            <div style={{width: '90px', height: '33px'}}>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', height: '33px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>PRECIO UNITARIO</p>
                            </div>
                        </div>
                        {cfdi.conceptos.map((c, i) => {
                            return (
                                <div className='d-flex' key={cfdi.comprobante.Sello.substring(i, i+5)}>
                                    <div style={{width: '50px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', borderLeft: 'solid black 1px',}} className='d-flex align-items-center justify-content-center'>
                                        <p className='text-center mb-0 text-bold' style={{fontSize: '9px', padding: '2px'}}>{i+1}</p>
                                    </div>
                                    <div style={{width: '190px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px',}} className='d-flex align-items-center justify-content-center'>
                                        <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>{reportes[i].equipo.toUpperCase()}</p>
                                    </div>
                                    <div style={{width: '100px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px',}} className='d-flex align-items-center justify-content-center'>
                                    {/* <p className='text-center' style={{fontSize: '9px'}}>{c['$'].ClaveProdServ} -</p> */}
                                        <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>{reportes[i].marca.toUpperCase()}</p>
                                    </div>
                                    <div style={{width: '100px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px',}} className='d-flex align-items-center justify-content-center'>
                                        <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>{reportes[i].modelo.toUpperCase()}</p>
                                    </div>
                                    <div style={{width: '150px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px',}} className='d-flex align-items-center justify-content-center'>
                                        <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>{reportes[i].noSerie.toUpperCase()}</p>
                                    </div>
                                    <div style={{width: '80px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px',}} className='d-flex align-items-center justify-content-center'>
                                        <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>{reportes[i].noInventario.toUpperCase()}</p>
                                    </div>
                                    <div style={{width: '90px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px',}} className='d-flex align-items-center justify-content-center'>
                                        <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>$ {formatMoney(c['$'].Importe)}</p>
                                    </div>
                                </div>
                            )
                            })}
                        <div className='d-flex'>
                            <div style={{width: '50px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '190px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '100px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '100px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '149px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '80px', borderLeft: 'solid black 1px', borderBottom: 'solid black 1px',}} className='d-flex align-items-center justify-content-center'>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', padding: '2px'}}>SUBTOTAL</p>
                            </div>
                            <div style={{width: '91px', borderLeft: 'solid black 1px', borderRight: 'solid black 1px', borderBottom: 'solid black 1px', }} className='d-flex align-items-center justify-content-center'>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>$ {formatMoney(cfdi.comprobante.SubTotal)}</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div style={{width: '50px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '190px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '100px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '100px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '149px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '80px', borderLeft: 'solid black 1px'}} className='d-flex align-items-center justify-content-center'>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', padding: '2px'}}>I.V.A. 16%</p>
                            </div>
                            <div style={{width: '91px', borderLeft: 'solid black 1px', borderRight: 'solid black 1px'}} className='d-flex align-items-center justify-content-center'>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>$ {formatMoney(cfdi.totalImpuestos.TotalImpuestosTrasladados)}</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div style={{width: '50px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '190px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '100px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '100px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '149px'}}>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>&nbsp;</p>
                            </div>
                            <div style={{width: '81px', border: 'solid black 1px'}} className='d-flex align-items-center justify-content-center'>
                                <p className='text-center mb-0 text-bold' style={{fontSize: '9px', padding: '2px'}}>TOTAL</p>
                            </div>
                            <div style={{width: '90px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px'}} className='d-flex align-items-center justify-content-center'>
                                <p className='text-center mb-0' style={{fontSize: '9px', padding: '2px'}}>$ {formatMoney(cfdi.comprobante.Total)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        if(cfdi.comprobante) {
            document.title = `Acta-${cfdi.comprobante.Serie+cfdi.comprobante.Folio} ${factura.hospital}`;
            setRenderHoja(
                <div id='printDiv'>
                    <div style={{position: 'absolute', width: '816px', height: '1056px', background: '#FFF'}} className='p-0'>
                        <div style={{position: 'absolute', top: '20px', right: '50px', width: '340px'}}>
                            <div className='d-flex align-items-stretch font1-bold' style={{width: '340px', color: '#8b8d8eff'}}>
                                <p style={{width: '340px', fontSize: '10px', marginBottom: '4px'}} >SECRETARÍA DE SALUD DE LA CIUDAD DE MÉXICO</p>
                            </div>
                            <div className='d-flex align-items-stretch font1' style={{width: '340px', color: '#8b8d8eff'}}>
                                <p style={{width: '340px', fontSize: '10px', marginBottom: '-2px'}} >DIRECCIÓN GENERAL DE ADMINISTRACIÓN Y FINANZAS</p>
                            </div>
                            <div className='d-flex align-items-stretch font1' style={{width: '340px', color: '#8b8d8eff'}}>
                                <p style={{width: '340px', fontSize: '10px', marginBottom: '-2px'}}  >DIRECCIÓN DE RECURSOS MATERIALES, ABASTECIMIENTOS Y SERVICIOS</p>
                            </div>
                            <div className='d-flex align-items-stretch font1' style={{width: '340px', color: '#8b8d8eff'}}>
                                <p style={{width: '340px', fontSize: '10px', marginBottom: '-2px'}} >SUBDIRECCIÓN DE MANTENIMIENTO Y SERVICIOS</p>
                            </div>
                            <div className='d-flex align-items-stretch font1' style={{width: '340px', color: '#8b8d8eff'}}>
                                <p style={{width: '340px', fontSize: '10px'}} className='py-0 my-0' >UNIDAD DEPARTAMENTAL DE MANTENIMIENTO DE EQUIPO</p>
                            </div>
                            <img src="/flores-magon-logo.svg" alt='juvalfri-logo' style={{width: "250px", height: 'auto', marginLeft: '50px', marginTop: '10px'}} />
                        </div>
                        <div style={{marginTop: '34px', marginLeft: '34px'}}>
                            <img src="/gdf-logo.svg" alt='juvalfri-logo' style={{width: "270px", height: 'auto'}} />
                        </div>
                        <div style={{height: '80px'}}>

                        </div>

                        <div className='d-flex justify-content-center font2'>
                            <div className='p-1' style={{marginTop: '4px',width: '770px',border: 'solid black 1.5px'}}>
                                <div><p className='text-center font1-bold mb-1' style={{fontSize: '10px'}}>ACTA DE ENTREGA-RECEPCIÓN</p></div>
                                <div>
                                    <p style={{fontSize: '8px'}}>
                                        SIENDO LAS <span style={{textDecoration: 'underline'}}>12:00</span> HORAS DEL DIA <span style={{textDecoration: 'underline'}}>{getDate(acta.fechaTerminacion,'DDD').toUpperCase()}</span> SE REUNEN EN LA UNIDAD <span style={{textDecoration: 'underline'}}>{acta.unidadMedica.toUpperCase()}</span>, LAS PERSONAS CUYOS NOMBRES,  CARGOS Y FIRMAS APARECEN AL FINAL DE LA PRESENTE ACTA, CON EL OBJETO DE LLEVAR A CABO LA RECEPCIÓN DE LOS SIGUIENTES TRABAJOS SOLICITADOS:
                                    </p>
                                </div>
                                <div>
                                    <p style={{fontSize: '8px'}} className='m-0 p-0'>
                                        NOTA:   LA INFORMACIÓN DEBE DE CONCORDAR CON LO ESTIPULADO EN EL CONTRATO.
                                    </p>
                                </div>
                                {/* lISTA DE EQUIPOS */}
                                {cfdi.conceptos.length === reportes.length ? getEquipos() : ''}
                                <div>
                                    <p style={{fontSize: '9px'}} className='mb-1 mx-2 text-bold'>
                                        OBSERVACIONES:
                                    </p>
                                    <p style={{fontSize: '9px'}} className='mb-3 mx-2'>
                                        _________________________________________________________________________________________________________________________________________________________________________
                                    </p>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div style={{width: '260px'}}>
                                        <p className='text-center mb-0 text-bold' style={{fontSize: '9px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', borderLeft: 'solid black 1px', padding: '2px'}}>No. DE CONTRATO</p>
                                    </div>
                                    <div style={{width: '250px'}}>
                                        <p className='text-center mb-0 text-bold' style={{fontSize: '9px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>No. DE FOLIO DEL ACTA DE ENTREGA RECEPCIÓN</p>
                                    </div>
                                    <div style={{width: '250px'}}>
                                        <p className='text-center mb-0 text-bold' style={{fontSize: '9px', borderTop: 'solid black 1px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>IMPORTE CON I.V.A. DE LA FACTURA </p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div style={{width: '260px'}}>
                                        <p className='text-center mb-0 text-bold' style={{fontSize: '11px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', borderLeft: 'solid black 1px',  padding: '2px'}}>{factura.noContrato.indexOf('NO.') !== -1 ? factura.noContrato.split('NO.')[1] : factura.noContrato}</p>
                                    </div>
                                    <div style={{width: '250px'}}>
                                        <p className='text-center mb-0 text-bold' style={{fontSize: '11px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>{factura.folioActaEntrega}</p>
                                    </div>
                                    <div style={{width: '250px'}}>
                                        <p className='text-center mb-0 text-bold' style={{fontSize: '11px', borderBottom: 'solid black 1px', borderRight: 'solid black 1px', padding: '2px'}}>$ {formatMoney(cfdi.comprobante.Total)}</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-around mt-3'>
                                    <p className='text-center mb-0 text-bold' style={{fontSize: '9px',}}> FECHA DE INICIO: <span style={{textDecoration: 'underline', fontWeight: '400'}}>&nbsp;&nbsp;&nbsp;&nbsp;{getDate(acta.fechaInicio, 'DDD').toUpperCase()}&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                                    <p className='text-center mb-0 text-bold' style={{fontSize: '9px',}}> FECHA DE TERMINACIÓN: <span style={{textDecoration: 'underline', fontWeight: '400'}}>&nbsp;&nbsp;&nbsp;&nbsp;{getDate(acta.fechaTerminacion, 'DDD').toUpperCase()}&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
                                </div>
                                <div className='mt-3'>
                                    <p style={{fontSize: '8px',}}>SE CIERRA LA PRESENTE FIRMANDO DE CONFORMIDAD LAS PERSONAS QUE INTERVINIERON EN EL ACTO</p>
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex' style={{width: '365px', height: '150px'}}>
                                        <div className='m-3' style={{borderBottom: 'solid 1px black', width: '210px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                (RESPONSABLE DE LA UNIDAD)
                                            </p>
                                            <p style={{fontSize: '8px'}} className='text-center'>
                                                DIRECTOR DE LA UNIDAD HOSPITALARIA Y/O UNIDAD MÉDICA Vo. Bo.
                                            </p>
                                        </div>
                                        <div className='m-3 d-flex justify-content-center align-items-end' style={{border: 'dotted 1px black', width: '150px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                SELLO
                                            </p>
                                        </div>
                                    </div>
                                    <div className='d-flex' style={{width: '365px', height: '150px'}}>
                                        <div className='m-3' style={{borderBottom: 'solid 1px black', width: '210px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                (SOLICITÓ)
                                            </p>
                                            <p style={{fontSize: '8px'}} className='text-center'>
                                                SUBDIRECCIÓN ADMINISTRATIVA O J.U.D. ADMINISTRATIVO DE LA UNIDAD MÉDICA
                                            </p>
                                        </div>
                                        <div className='m-3 d-flex justify-content-center align-items-end' style={{border: 'dotted 1px black', width: '150px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                SELLO
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex' style={{width: '365px'}}>
                                        <div style={{width: '210px', marginTop: '-12px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center text-bold mb-0'>
                                                {acta.directorUnidadMedica}
                                            </p>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-end' style={{width: '150px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                
                                            </p>
                                        </div>
                                    </div>
                                    <div className='d-flex' style={{width: '365px'}}>
                                        <div style={{width: '210px', marginTop: '-12px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center text-bold mb-0'>
                                                {acta.subdireccionAdministrativa}
                                            </p>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-end' style={{width: '150px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex' style={{width: '365px', height: '150px'}}>
                                        <div className='m-3' style={{borderBottom: 'solid 1px black', width: '210px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center'>
                                                INGENIERO BIOMÉDICO Y/0 ENCARGADO DE MANTENIMIENTO Y/O RESPONSABLE DE LA REVISIÓN Y VALIDACIÓN DEL SERVICIO DE LA UNIDAD MÉDICA
                                            </p>
                                        </div>
                                        <div className='m-3 d-flex justify-content-center align-items-end' style={{border: 'dotted 1px black', width: '150px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                SELLO
                                            </p>
                                        </div>
                                    </div>
                                    <div className='d-flex' style={{width: '365px', height: '150px', position:'relative'}}>
                                        <div className='m-3' style={{borderBottom: 'solid 1px black', width: '210px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center'>
                                                COMERCIALIZADORA DE SERVICIOS INTEGRALES JUVALFRI S.A. DE C.V.
                                            </p>
                                        </div>
                                        <div className='m-3 d-flex justify-content-center align-items-end' style={{border: 'dotted 1px black', width: '150px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                SELLO
                                            </p>
                                        </div>
                                        <div style={{position: 'absolute', top: '36px', left: '193px'}}>
                                            <img src="/sello-juvalfri.svg" alt='juvalfri-logo' style={{width: "180px", height: 'auto'}} />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex' style={{width: '365px'}}>
                                        <div style={{width: '210px', marginTop: '-12px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center text-bold mb-0'>
                                                {acta.ingenieroBiomedico}
                                            </p>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-end' style={{width: '150px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                
                                            </p>
                                        </div>
                                    </div>
                                    <div className='d-flex' style={{width: '365px'}}>
                                        <div style={{width: '210px', marginTop: '-12px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center text-bold mb-0'>
                                                {emisor.administradorUnico}
                                            </p>
                                            <p style={{fontSize: '8px'}} className='text-center text-bold mb-0'>
                                                ADMINISTRADOR ÚNICO
                                            </p>
                                        </div>
                                        <div className='d-flex justify-content-center align-items-end' style={{width: '150px'}}>
                                            <p style={{fontSize: '8px'}} className='text-center mb-0'>
                                                
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{position: 'absolute', bottom: '20px', width: '816px', left: '0px'}}>
                            <div className='d-flex justify-content-between align-items-end'>
                                <div className='mx-4'>
                                    <div className='font1' style={{color: '#8b8d8eff'}}>
                                        <p className='m-0' style={{fontSize: '11px'}}>Av. Insurgentes Norte 423, Piso 8, Nonoalco Tlatelolco</p>
                                        <p className='m-0' style={{fontSize: '11px'}}>Alcaldía Cuauhtémoc, C.P. 06000, Ciudad de México</p>
                                        <p className='m-0' style={{fontSize: '11px'}}>T. 555132 1200 EXT. 1095</p>
                                    </div>
                                </div>
                                <div className='mx-5'>
                                    <img src="/ciudad-innovadora.svg" alt='juvalfri-logo' style={{width: "180px", height: 'auto'}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            setRenderHoja(
                <>
                    <p className='h5 m-4'>No se ha validado ningún CFDI</p>
                </>
            )
        }
    }, [cfdi, reportes])

    return(
        <div style={{background: '#FFF', marginTop: '10px'}}>
            <div style={{marginBottom: '12px'}} className='mx-2'>
                <Button variant='contained' color='secondary' onClick={printDocument}>Imprimir hoja &nbsp; <i className="fad fa-print"></i></Button>
            </div>
            {renderHoja}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps)(ActaEntrega);