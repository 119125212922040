import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { Spinner } from 'react-bootstrap';
import PreviewFactura from './PreviewFactura';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Fab from '@mui/material/Fab';
import { Box, Divider, Typography, Grid, Button, Container, Paper, Select, MenuItem, InputLabel } from '@mui/material';

import CustomChip from '../../utils/components/CustomChip';

import CustomMessage from '../../utils/components/CustomMessage';
import { hospitales, formatMoney, getVigencia } from '../../utils/functions/UtilsJuvalfri';

const NuevaFactura = (props) => {

    const [emisor, setEmisor] = useState({
            denominacion: 'Comercializadora de Servicios Integrales Juvalfri S.A. de C.V.',
            regimenFiscal: '601 - Régimen General de Ley Personas Morales',
            rfc: 'CSI200211-SI1',
            direccion: 'Quiches No. 19 Int. 4, Col. La Raza, Azcapotzalco, México CDMX',
            telefono: '55 4861-7332',
            administradorUnico: 'Ing. Abdias Lopez Reyes'
    });
    const [receptor, setReceptor] = useState({
        denominacion: 'GOBIERNO DE LA CIUDAD DE MÉXICO / SECRETARÍA DE SALUD',
        rfc: 'GDF9712054NA',
        direccion: 'PLAZA DE LA CONSTITUCION S/N, COL. CENTRO DE LA CIUDAD DE MÉXICO AREA 1, CUAUHTÉMOC CIUDAD DE MÉXICO C.P. 06000'
    });
    const [factura, setFactura] = useState({
        noFactura: '',
        cfdi: '',
        fechaEmision: new Date(),
        subTotal: 0.00,
        iva: 0.00,
        total: 0.00,
        reportes: [],
        conceptos: [],
        hospital: '',
        estatus: 'PENDIENTE DE FIRMAS',
        noContrato: '',
        vigenciaContrato: '',
        ejecucion: 'DEL 13 AL 23 DE ABRIL DEL 2022',
        serie: '',
        folio: '',
        folioActaEntrega: '',
        importeLetra: '',
        dirUnMed: '',
        drmas: '',
        jud: '',
        subMan: '',
    });
    const [acta, setActa] = useState({
        fechaInicio: new Date(),
        fechaTerminacion: new Date(),
        directorUnidadMedica: '',
        subdireccionAdministrativa: '',
        ingenieroBiomedico: '',
        unidadMedica: '',
    });
    const [cfdi, setCfdi] = useState({});
    const [verifySuccess, setVerifySuccess] = useState(<></>);
    const [prevFac, setPrevFac] = useState(<></>);
    const [renderDescription, setRenderDescription] = useState(<></>);
    const [conceptosD, setConceptosD] = useState(null);
    const [reportesD, setReportesD] = useState(null);
    const [precioReporte, setPrecioReporte] = useState(null);
    const [renderContrato, setRenderContrato] = useState(<></>);
    const [contratos, setContratos] = useState(null);
    const [currentContrato, setCurrentContrato] = useState({ noContrato: ''});
    const [apiRes, setApiRes] = useState({show: false, message: '', severity: ''});

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
          document.title = 'Nueva factura';
    }, [props])

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show}})
    }

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            const res = await axios.get('/api/contratos');
            if(res.status === 200) {
                setContratos(res.data);
            }
        } catch (err) {
            console.log(err);
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al cargar los contratos, intente más tarde', severity: 'error' }});
        }
    }

    const parseCfdi = async() => {
        if(factura.cfdi !== '') {
            setVerifySuccess(<Spinner size='sm' animation="border" variant="light" />);
            try {
                const res = await axios.post('/api/facturas/parseCfdi', {"cfdi": factura.cfdi})
                if(res.status === 200) {
                    setEmisor(state => {return{...state,
                        denominacion: res.data.cfdi2.emisor.Nombre,
                        rfc: res.data.cfdi2.emisor.Rfc
                    }});
                    setReceptor(state => {return{...state,
                        denominacion: res.data.cfdi2.receptor.Nombre,
                        rfc: res.data.cfdi2.receptor.Rfc
                    }});
                    setFactura(state =>{return{...state,
                        noFactura: res.data.cfdi2.comprobante.Serie + res.data.cfdi2.comprobante.Folio,
                        folio: res.data.cfdi2.comprobante.Folio,
                        serie: res.data.cfdi2.comprobante.Serie,
                        subTotal: res.data.cfdi2.comprobante.SubTotal,
                        iva: res.data.cfdi2.totalImpuestos.TotalImpuestosTrasladados,
                        total: res.data.cfdi2.comprobante.Total,
                        fechaEmision: res.data.cfdi2.comprobante.Fecha,
                    }})
                    setCfdi(res.data.cfdi2);
                    let tmp = {};
                    res.data.cfdi2.conceptos.forEach((c, i) => {
                        tmp[i] = c['$'].Descripcion;
                    })
                    setConceptosD(tmp);
                    tmp = {};
                    res.data.cfdi2.conceptos.forEach((c, i) => {
                        tmp[i] = '';
                    })
                    setReportesD(tmp);
                    tmp = {};
                    res.data.cfdi2.conceptos.forEach((c, i) => {
                        tmp[i] = c['$'].Descripcion;
                    })
                    setPrecioReporte(tmp);
                    setVerifySuccess(<i className='fad fa-check-circle'/>);
                }
            } catch (e) {
                setVerifySuccess(<i className='fad fa-exclamation-triangle'/>);
                console.log(e);
                setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error con los datos ingresados, favor de revisar los datos e intentarlo de nuevo', severity: 'error' }});
            }
        }
    }

    const handleFile = (e) => {
        let file = e.target.files[0];
        try {
            let reader = new FileReader();

            reader.readAsText(file, 'UTF-8');
            reader.onloadend = () => {
                if (reader.readyState === 2) {
                    if (Number(file.size) > 26214400) {
                        // this.setState({fileMsg: "El archivo excede los 25 Mb, intenta con otro.", fileError: true})
                    } else {

                        setFactura(state => {return {...state, cfdi: reader.result}})
                    }
                  }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const tmp = [];
        cfdi.conceptos.forEach((c, i) => {
            tmp.push(c);
            tmp[i]['$'].Descripcion = conceptosD[i];
        })
        setCfdi(state => { return {...state, conceptos: tmp}});
        setPrevFac(<></>);
    }

    useEffect(() => {
        setPrevFac(<PreviewFactura cfdi={cfdi} emisor={emisor} receptor={receptor} factura={factura}/>);
    }, [cfdi, factura, emisor, receptor])

    const handleSaveFactura = async () => {
        try {
            let f = factura;
            f.conceptos = conceptosD;
            f.reportes = reportesD
            const res = await axios.post('/api/facturas/', {'factura': f, 'emisor': emisor, 'receptor': receptor, 'actaEntrega': acta})
            if(res.status === 200) {
                setApiRes(state => { return{ ...state, show: true, message: 'Factura creada con exito', severity: 'success' }});
                setTimeout(() => {
                    props.history.push('/facturas');
                  }, 2000);
            }
        } catch (e) {
            console.log(e);
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al crear la factura, favor de intentarlo mas tarde', severity: 'error' }});
        }
    }

    const verifyReports = async() => {
        try {
            for( let x of Object.keys(reportesD)) {
                const res = await axios.get(`/api/reportes/getReportByNumber/?reporte=${reportesD[x].split(',')[0]}`);
                if(res.status === 200){
                    const desc = `${res.data.reportes[0].equipo} MARCA ${res.data.reportes[0].marca} MODELO ${res.data.reportes[0].modelo} SERIE ${res.data.reportes[0].noSerie} INVENTARIO ${res.data.reportes[0].noInventario}`.toUpperCase()
                    setConceptosD(state => {return {...state, [x]: desc}})
                    setPrecioReporte(state => {return {...state, [x]: res.data.reportes[0].cotizacion.subtotal}})
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if(cfdi.comprobante && conceptosD !== null && reportesD !== null) {
            setRenderDescription(
                <div className='px-3'>
                    <p className='text-bold'>Conceptos encontrados en la factura:</p>
                    <div className='d-flex'>
                        <p className='text-bold text-center' style={{width: '100px', fontSize: '16px'}}>Cantidad</p>
                        <p className='text-bold text-center' style={{width: '500px', fontSize: '16px'}}>Descripción</p>
                        <p className='text-bold text-center' style={{width: '200px', fontSize: '16px'}}>Precio Unitario</p>
                        <p className='text-bold text-center' style={{width: '200px', fontSize: '16px'}}># Reporte</p>
                        {precioReporte !== null ? <p className='text-bold text-center' style={{width: '200px', fontSize: '16px'}}>Subtotal Cotizado</p> : <></>}
                    </div>
                    {cfdi.conceptos.map((c, i) => {
                        return(
                            <div className='d-flex my-2 align-items-center' key={cfdi.comprobante.Sello.substring(i, i+5)}>
                                <p className='text-bold text-center mb-0' style={{width: '100px', fontSize: '16px'}}>{c['$'].Cantidad}</p>
                                <TextField label='...' value={conceptosD[i]} multiline onChange= {(e) => setConceptosD({...conceptosD, [i]: e.target.value})} style={{width: '500px'}} />
                                <p className='text-bold text-center mb-0' style={{width: '200px', fontSize: '16px'}}>$ {formatMoney(c['$'].Importe)}</p>
                                <TextField label='...' size='small' value={reportesD[i]} onChange= {(e) => setReportesD(state => {return {...state, [i]: e.target.value}})}  style={{width: '200px'}} />
                                {precioReporte !== null ? 
                                    <p className='text-bold text-center mb-0' style={{width: '200px', fontSize: '16px', color: `${formatMoney(precioReporte[i]) === formatMoney(c['$'].Importe) ? '#000' : '#FF0000'}`}}>$ {formatMoney(precioReporte[i])}</p> :
                                    <></>
                                }
                            </div>
                        )
                    })}
                    <div className='text-center my-3'>
                        <Button variant='contained' color='secondary' className='px-3 py-2' onClick={() => verifyReports()}>Verificar descripcion y precio</Button>
                    </div>
                </div>
            )
        }
    }, [cfdi, conceptosD, reportesD, precioReporte])

    useEffect(() => {
        if(contratos !== null) {
            setRenderContrato(
                <FormControl sx={{ minWidth: 100 }} style={{display: 'flex'}}>
                    {/* <Autocomplete
                        disablePortal
                        onInputChange={(event, newInputValue) => setCurrentContrato(contratos.find(c => c.contrato.noContrato === newInputValue).contrato)}
                        inputValue={currentContrato.noContrato}
                        aria-multiline='true'
                        id="combo-box-demo"
                        required
                        options={contratos.map((option) => `${option.contrato.noContrato}`)}
                        renderInput={(params) => <TextField {...params} label='Contrato' />}
                    /> */}
                    <InputLabel id="demo-simple-select-label">Contrato</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={currentContrato.noContrato}
                        label="Contrato"
                        onChange={(e) => setCurrentContrato(contratos.find(c => c.contrato.noContrato === e.target.value).contrato)}
                    >
                        {contratos.map((c) => {
                            return <MenuItem key={c.contrato.noContrato} value={c.contrato.noContrato}>{c.contrato.noContrato}</MenuItem>
                        })}

                    </Select>
                </FormControl>
            )
        }

        if(currentContrato.noContrato !== '') { 
            setEmisor(contratos.find(c => c.contrato.noContrato === currentContrato.noContrato).emisor);
            setReceptor(contratos.find(c => c.contrato.noContrato === currentContrato.noContrato).receptor);
            setFactura({
                ...factura, 
                noContrato: currentContrato.noContrato, 
                vigenciaContrato: getVigencia(currentContrato.fechaInicio, currentContrato.fechaFin).toUpperCase(),
                drmas: currentContrato.director,
                jud: currentContrato.jud,
                subMan: currentContrato.subdirector
            });
        }
    }, [contratos, currentContrato])

    return(
        <>
            <Container maxWidth='xl' style={{marginTop: '10px'}}>
                <Box className='text-center' >
                    <Typography variant='h5' style={{fontWeight: 700}}>Formato para factura Juvalfri</Typography>
                </Box>

                <Fab aria-label="add" onClick={() => handleSaveFactura()} style={{position: 'fixed', top: '60px', left: '12px', zIndex: '10', backgroundColor: '#66bb6a', color: '#FFF'}}>
                    <i className="fad fa-save fa-2x"></i>
                </Fab>

                <Divider className='my-2' textAlign="left">
                    <CustomChip label='Datos de la factura' variant={'outlined'} color='#842033' borderColor='#842033' className='text-bold'/>
                </Divider>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Paper elevation={3} style={{marginTop: '32px', borderRadius: '10px'}}>
                        <Grid container spacing={1} className='px-2'>
                            <Grid item xs={12} sm={9} md={9} lg={9}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                    <label className='text-bold mb-1'>CFDI (archivo .xml):</label>
                                    <input onChange={(e) => handleFile(e)} className="form-control" type="file" id="formFile" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} style={{display: 'flex', alignItems: 'end'}} className='mb-2'>
                                <Button variant='contained' color='primary' className='px-2' onClick={() => parseCfdi()}>Verificar CFDI &nbsp;{verifySuccess}</Button>
                            </Grid>
                            <Grid item xs={12} >
                                {renderDescription}
                            </Grid>
                        </Grid>
                    </Paper>

                    <Divider className='my-3' textAlign="left">
                        <CustomChip label='Datos generales' variant={'outlined'} color='#842033' borderColor='#842033' className='text-bold'/>
                    </Divider>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            {renderContrato}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                onChange={(e) => setFactura({...factura, noFactura: e.target.value})}
                                value={factura.noFactura}
                                label="No. Factura"
                                fullWidth
                                required 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl sx={{ minWidth: 100 }} style={{display: 'flex'}}>
                                <Autocomplete
                                    disablePortal
                                    freeSolo={true}
                                    onInputChange={(event, newInputValue) => setFactura({ ...factura, hospital: newInputValue})}
                                    inputValue={factura.hospital}
                                    aria-multiline='true'
                                    id="combo-box-demo"
                                    options={hospitales}
                                    required
                                    renderInput={(params) => <TextField {...params} label='Unidad Hospitalaria' />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                onChange={(e) => setEmisor({...emisor, administradorUnico: e.target.value})}
                                value={emisor.administradorUnico}
                                label="Administrador único"
                                fullWidth
                                required 
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                onChange={(e) => setFactura({...factura, vigenciaContrato: e.target.value})}
                                value={factura.vigenciaContrato}
                                label="Vigencia del contrato"
                                fullWidth
                                required 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                onChange={(e) => setFactura({...factura, ejecucion: e.target.value})}
                                value={factura.ejecucion}
                                label="Periodo de ejecución"
                                fullWidth
                                required 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                onChange={(e) => setFactura({...factura, folioActaEntrega: e.target.value})}
                                value={factura.folioActaEntrega}
                                label="Folio acta-entrega"
                                fullWidth
                                required 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                onChange={(e) => setFactura({...factura, importeLetra: e.target.value})}
                                value={factura.importeLetra}
                                label="Importe con letra"
                                fullWidth
                                required 
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                onChange={(e) => setFactura({...factura, dirUnMed: e.target.value})}
                                value={factura.dirUnMed}
                                label="Director de la Unidad Medica"
                                fullWidth
                                required 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                onChange={(e) => setFactura({...factura, drmas: e.target.value})}
                                value={factura.drmas}
                                label="Director de la DRMAS"
                                fullWidth
                                required 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                onChange={(e) => setFactura({...factura, jud: e.target.value})}
                                value={factura.jud}
                                label="JUD de mantenimiento de equipo"
                                fullWidth
                                required 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                onChange={(e) => setFactura({...factura, subMan: e.target.value})}
                                value={factura.subMan}
                                label="Subdirector de Mantenimiento y Servicios"
                                fullWidth
                                required 
                            />
                        </Grid>
                    </Grid>
                    
                    <div className='d-flex my-3'>
                        <Button variant='contained' color='primary' type='submit'>Vista Previa &nbsp; <i className='fad fa-eye'></i></Button>
                        <Button variant='contained' color='secondary' onClick={() => handleSaveFactura()}  className='mx-3'>Guardar &nbsp;<i className='fad fa-save'></i></Button>
                    </div>
                </form>
                <br />
                {prevFac}
            </Container>
            <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(NuevaFactura);