import { DateTime } from 'luxon';

//Transforma una cantidad de dineros a una cadena con el formato de moneda
export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    alert('Los precios deben contener sólo valores numéricos, sin letras ni símbolos');
    console.log(e)
  }
}

//Obtiene la fecha en el formato deseado
export const getDate = (date, format) => {
  const dateD = DateTime.fromISO(date);
  return dateD.isValid ? dateD.setLocale('es').toFormat(format ? format : 'dd/MM/yyyy') : '';
}

//Obtiene los dias restantes para la fecha de vencimiento de la garantía
export const getGarantia = (fecha) => {
  let start = DateTime.fromISO(getDate(fecha, 'yyyy-MM-dd'));
  let end = DateTime.fromISO(DateTime.fromISO(getDate(fecha, 'yyyy-MM-dd')).plus({ days: 60 }).toISODate());
  let today = DateTime.fromISO(new Date().toISOString().slice(0, 10));

  let diffInDays = today.diff(start, 'days');
  diffInDays.toObject();
  let tmp = { text: '', days: 0 };
  if (diffInDays.days > 0 && diffInDays.days < 60) {
    tmp.text = `El equipo aún tiene ${60 - diffInDays.days} ${diffInDays === 59 ? 'dia' : 'días'} de garantía, vence el ${getDate(end, 'DDDD')}`;
  } else if (diffInDays.days === 60) {
    tmp.text = `La garantía del equipo vence hoy, ${getDate(today, 'DDDD')}`;
  } else if (diffInDays.days > 60) {
    tmp.text = `El equipo ya no se encuentra en garantía, venció hace ${diffInDays.days - 60} ${diffInDays === 61 ? 'dia' : 'días, el '} ${getDate(end, 'DDDD')}`;
  }
  tmp.days = diffInDays.days;
  return tmp;
}

// Obtiene la vigencia de un contrato en texto
export const getVigencia = (inicio, fin) => {
  let start = DateTime.fromISO(getDate(inicio, 'yyyy-MM-dd')).setLocale('es');
  let end = DateTime.fromISO(getDate(fin, 'yyyy-MM-dd')).setLocale('es');

  return `Vigencia del ${start.toFormat("dd 'de' LLLL")} al ${end.toFormat("dd 'de' LLLL")} del ${start.toFormat("yyyy")}`;
}

export const getProgress = (refacciones) => {
  if (refacciones.length > 0) {
    let avance = 0;
    refacciones.forEach(refaccion => {
      if (refaccion.estatus) {
        avance++;
      }
    });
    return avance / refacciones.length * 100;
  }
}

// Arreglos de datos para auto completar y filtrar
export const equipos = ['ELECTROCARDIÓGRAFO', 'ELECTROCARDIÓGRAFO DE 12 CANALES', 'ELECTROCARDIÓGRAFO MULTICANAL',
  'TOCOCARDIÓGRAFO', 'CARDIOTOCÓGRAFO', 'FONODETECTOR DE LATIDOS FETALES', 'MONITOR DE SIGNOS VITALES', 'CENTRAL DE MONITOREO', 'MESA QUIRÚRGICA UNIVERSAL ELECTROHIDRÁULICA',
  'MESA DE CIRUGÍA', 'MESA DE CIRUGÍA GENERAL', 'MESA DE CIRUGÍA PARA OBSTETRICIA', 'MESA DE CIRUGÍA PARA ORTOPEDIA CON ACCESORIOS',
  'MESA DE EXPLORACIÓN GINECOLÓGICA', 'MESA DE EXPLORACIÓN UNIVERSAL', 'MESA DE EXPULSIÓN', 'SISTEMA DE MONITORIZACIÓN FISIOLÓGICA EN PRUEBA DE ESFUERZO',
  'AUTOCLAVE', 'MARCAPASO CARDIACO EXTERNO', 'MARCAPASOS EXTERNO', 'DESFIBRILADOR CON MARCAPASOS', 'CAMILLA MULTIPLE DE POSICIÓN',
  'BANDA DE ESFUERZO VETERINARIA', 'BANDA DE ESFUERZO', 'ASPIRADOR MANUAL PORTATIL PARA SUCCIÓN CONTINUA',
  'COMPUTADORA PARA DETERMINAR GASTO CARDÍACO POR BIOIMPEDANCIA', 'NANOPORE', 'NANOPORE STYLUS']

export const hospitales = ['H.G. BALBUENA', 'H.G. DR. ENRIQUE CABRERA', 'H.G. DR. GREGORIO SALAS', 'H.G. DR. RUBEN LEÑERO', 'H.G. IZTAPALAPA', 'H.G. LA VILLA',
  'H.G. MILPA ALTA', 'H.G. XOCO', 'H.G. AJUSCO MEDIO', 'H.G. TICOMÁN', 'H.G. CLINICA EMILIANO ZAPATA', 'H.G. TLAHUAC', 'H.G. TOPILEJO', 'H.E. DR. BELISARIO DOMÍNGUEZ',
  'H.P AZCAPOTZALCO', 'H.P. COYOACAN', 'H.P. IZTACALCO', 'H.P. IZTAPALAPA', 'H.P. LA VILLA', 'H.P. LEGARIA', 'H.P. MOCTEZUMA', 'H.P. PERALVILLO',
  'H.P. SAN JUAN DE ARAGÓN', 'H.P. TACUBAYA', 'H.M.P. XOCHIMILCO', 'H.M.I. CUAUTEPEC', 'H.M.I. INGUARÁN', 'H.M.I. MAGDALENA CONTRERAS', 'H.M.I. NICOLAS M. CEDILLO',
  'H.M.I. TLAHUAC', 'U. M. SANCIONES ADMINISTRATIVAS Y DE INTEGRACIÓN SOCIAL', 'HOSPITAL GENERAL TORRE MEDICA TEPEPAN', 'CENTRO DE READAPTACION SOCIAL, MASCULINO SANTA MARTHA (CEFERESO, CERESOVA)',
  'CENTRO REGULADOR DE URGENCIAS MÉDICAS', 'CEVAREPSI', 'RECLUSORIO PREVENTIVO NORTE', 'RECLUSORIO PREVENTIVO ORIENTE', 'RECLUSORIO PREVENTIVO SUR',
  'TOXICOLOGICO VENUSTIANO CARRANZA', 'TOXICOLOGICO XOCHIMILCO', 'ALMACEN CENTRAL', 'ESCUELA DE ENFERMERIA', 'UNIDADES MEDICAS MÓVILES', 'SESDERMA'];

export const status = ['PENDIENTE', 'DIAGNOSTICADO', 'EN ESPERA DE AUTORIZACION', 'AUTORIZADO', 'EN REPARACIÓN', 'ATENDIDO', 'FACTURADO', 'CANCELADO', 'EN GARANTÍA', 'BAJA DE EQUIPO'];

export const statusFacturas = ['PENDIENTE DE FIRMAS', 'RECIBIDA POR EL HOSPITAL', 'FIRMADA', 'EN REVISIÓN PARA PAGO', 'EN ESPERA DE PAGO', 'PAGADA', 'EN REVISION POR ERRORES', 'CANCELADA'];

export const partidas = ['ELECTROCARDIÓGRAFOS', 'TOCOCARDIÓGRAFOS', 'MONITORES', 'MESAS DE CIRUGÍA', 'ESTERILIZADORES'];

export const dropListaCotizacion = ['ELECTROCARDIÓGRAFOS', 'TOCOCARDIOGRAFOS', 'MONITORES', 'MESAS DE CIRUGÍA', 'SESDERMA'];