import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { Button } from 'react-bootstrap';

import { getDate } from '../../../utils/functions/UtilsJuvalfri';

const TablaPendientes = (props) => {

    const [data, setData] = useState(null);
    const [autorizados, setAutorizados] = useState(null);
    const [pendientes, setPendientes] = useState(null);
    const [render, setRender] = useState(<></>);
    const [render2, setRender2] = useState(<></>);

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
        }
    }, [props])

    const getData = async () => {
        try {
            const res = await axios.get('/api/reportes');
            if(res.status === 200) {
                let tmp = res.data.filter(r => r.estatus === 'AUTORIZADO' || r.estatus === 'EN REPARACIÓN');
                // console.log(tmp);
                // Sort by hospital
                let tmp2 = tmp.sort((a, b) => {
                    let fa = a.hospital.toLowerCase(),
                        fb = b.hospital.toLowerCase();
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });

                setAutorizados(tmp2);
                // setData(tmp2);

                tmp = []
                let tmp3 = [];
                tmp2.forEach(r => {
                    if(r.refacciones.length > 0) { 
                        r.refacciones.forEach(d => {
                            if(!d.estatus) {
                                tmp.push(d);
                            }
                        })
                        tmp3.push({... r, refacciones: tmp});
                        tmp = [];
                    }
                })

                tmp2 = tmp3.sort((a, b) => {
                    let fa = a.hospital.toLowerCase(),
                        fb = b.hospital.toLowerCase();
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                // console.log(tmp2);

                setPendientes(tmp2);

                // setData(tmp);
            } else {
                console.log('Hola :D');
            }
        } catch(e) {
            console.log(e)
        }
    }

    const bgRowTable = estatus => {
        switch(estatus) {
            case 'PENDIENTE': 
                return 'bg-pendiente';
            case 'DIAGNOSTICADO': 
                return 'bg-diagnosticado';
            case 'EN ESPERA DE AUTORIZACION': 
                return 'bg-esperaAutorizacion';
            case 'AUTORIZADO': 
                return 'bg-autorizado';
            case 'EN REPARACIÓN': 
                return 'bg-enReparacion';
            case 'ATENDIDO': 
                return 'bg-atendido';
            case 'FACTURADO': 
                return 'bg-facturado';
            case 'CANCELADO': 
                return 'bg-cancelado';
            case 'EN GARANTÍA': 
                return 'bg-garantia';
            case 'BAJA DE EQUIPO': 
                return 'bg-baja';
            default: 
                return ''
        }
    }

    useEffect(() => {
        getData();
        document.title = 'Tabla de Pendientes'
    }, [])

    useEffect(() => {
        // console.log(autorizados);
        if(autorizados !== null) {
            setRender(
                <div>
                    <br />
                    <p className='text-bold'>Tabla de pendientes por hospital</p>
                    <table id='table-to-xls' style={{borderCollapse: 'collapse', border: '1px solid black', width: '100%', fontFamily: 'Arial', fontSize: '0.7em'}}>
                        <thead>
                            <tr className='text-center' style={{borderCollapse: 'collapse', border: '1px solid black', backgroundColor: '#9bc2e6'}}>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'70px'}}># Reporte</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Equipo</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'120px'}}>Marca</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Modelo</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'120px'}}>No. Serie</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>No. Inventario</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Fecha de reporte</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Fecha de autorización</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Cantidad</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Refacción</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            {autorizados.map((d, j) =>
                            <>
                                {j === 0 ? <tr className='text-center' style={{borderCollapse: 'collapse', border: '1px solid black', backgroundColor: '#FFECB3'}}>
                                        <th colSpan='11' style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.hospital}</th>
                                    </tr> : <></>}
                                {j > 0 && d.hospital !== autorizados[j-1].hospital ? 
                                    <tr className='text-center' style={{borderCollapse: 'collapse', border: '1px solid black', backgroundColor: '#FFECB3'}}>
                                        <th colSpan='11' style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.hospital}</th>
                                    </tr> : <></>}
                                {d.refacciones.map((dr, i) => (
                                    <tr key={dr.key} className='text-center'>
                                        {i < 1 ? 
                                            <>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{d.noReporte}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'150px'}}>{d.equipo}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.marca}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{d.modelo}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width: '100px'}}>{d.noSerie}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.noInventario}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{getDate(d.fecha.reporte, 'dd/MM/yyyy')}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{getDate(d.fecha.autorizacion, 'dd/MM/yyyy')}</td>
                                            </> : <></>}
                                        <td colSpan='1'  style={{borderCollapse: 'collapse', border: '1px solid black', background: `${dr.estatus ? '#36ad3a87' : '#FFF'}`}}>{dr.cantidad}</td>
                                        <td  style={{borderCollapse: 'collapse', border: '1px solid black', background: `${dr.estatus ? '#36ad3a87' : '#FFF'}`}}>{dr.refaccion}</td>
                                        {i < 1 ? <><td rowSpan={d.refacciones.length} className={bgRowTable(d.estatus)}  style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.estatus}</td></> : <></>}
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                </tr>
                            </>
                            )}
                        </tbody>
                    </table>
                    <hr className='my-3' />
                    
                </div>
            )
        }
        if(pendientes !== null) {
            setRender2(
                <div>
                    <p className='text-bold'>Refacciones pendientes</p>
                    <table id='table-to-xls' style={{borderCollapse: 'collapse', border: '1px solid black', width: '100%', fontFamily: 'Arial', fontSize: '0.7em'}}>
                        <thead>
                            <tr className='text-center' style={{borderCollapse: 'collapse', border: '1px solid black', backgroundColor: '#9bc2e6'}}>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'70px'}}># Reporte</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Equipo</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'120px'}}>Marca</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black', width:'160px'}}>Modelo</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Cantidad</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Refacción</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Compra</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Observaciones</th>
                                <th style={{borderCollapse: 'collapse', border: '1px solid black'}}>Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendientes.map((d, j) =>
                            <>
                                {j === 0 ? <tr className='text-center' style={{borderCollapse: 'collapse', border: '1px solid black', backgroundColor: '#FFECB3'}}>
                                            <th colSpan='6' style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.hospital}</th>
                                        </tr> : <></>}
                                    {j > 0 && d.hospital !== autorizados[j-1].hospital ? 
                                        <tr className='text-center' style={{borderCollapse: 'collapse', border: '1px solid black', backgroundColor: '#FFECB3'}}>
                                            <th colSpan='6' style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.hospital}</th>
                                        </tr> : <></>}
                                {d.refacciones.map((dr, i) => (
                                    <tr key={dr.key} className='text-center'>
                                        {i < 1 ? 
                                            <>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}} className='text-bold'>{d.noReporte}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'150px'}}>{d.equipo}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black'}}>{d.marca}</td>
                                                <td rowSpan={d.refacciones.length}  style={{borderCollapse: 'collapse', border: '1px solid black', width:'100px'}}>{d.modelo}</td>
                                            </> : <></>}
                                        <td colSpan='1'  style={{borderCollapse: 'collapse', border: '1px solid black', background: `${dr.estatus ? '#36ad3a87' : '#FFF'}`}}>{dr.cantidad}</td>
                                        <td  style={{borderCollapse: 'collapse', border: '1px solid black', background: `${dr.estatus ? '#36ad3a87' : '#FFF'}`}}>{dr.refaccion}</td>
                                        <td  style={{borderCollapse: 'collapse', border: '1px solid black', background: `${dr.estatus ? '#36ad3a87' : '#FFF'}`}}>{dr.compra}</td>
                                        <td  style={{borderCollapse: 'collapse', border: '1px solid black', background: `${dr.estatus ? '#36ad3a87' : '#FFF'}`}}>{dr.observaciones}</td>
                                        <td  style={{borderCollapse: 'collapse', border: '1px solid black', background: `${dr.estatus ? '#36ad3a87' : '#FFF'}`}}>{dr.estatus ? '✔' : ''}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                </tr>
                            </>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }
    }, [autorizados, pendientes])

    return (
        <div className='container-fluid' style={{marginTop: '10px'}}>
            {render}
            {render2}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(TablaPendientes)