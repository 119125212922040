import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import QRCode from'qrcode.react';

import { Button } from '@mui/material'

import { getDate, formatMoney } from '../../utils/functions/UtilsJuvalfri';

function PreviewFactura(props) {

    const [renderHoja, setRenderHoja] = useState(<></>);

    const printDocument = () => {
        let printContents = document.getElementById('printDiv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
    }

    const getEquipos = () => {
        return(
            <>
                <div className='d-flex justify-content-center'>
                    <div style={{marginTop: '4px', width: '770px' }}>
                        <hr style={{height: '2px', color: '#611c35', opacity: '75%', marginTop: '4px', marginBottom: '4px'}} />
                        <p className='text-bold text-center mb-2' style={{fontSize: '12px'}}>{props.factura.hospital}</p>
                        <div className='d-flex'>
                            <div style={{width: '60px'}}>
                                <p className='text-bold text-center mb-0' style={{fontSize: '11px'}}>Cantidad</p>
                            </div>
                            <div style={{width: '60px'}}>
                                <p className='text-bold text-center mb-0' style={{fontSize: '11px'}}>Unidad</p>
                            </div>
                            <div style={{width: '70px'}}>
                                <p className='text-bold text-center mb-0' style={{fontSize: '11px'}}>Clave</p>
                            </div>
                            <div style={{width: '280px'}}>
                                <p className='text-bold text-center mb-0' style={{fontSize: '11px'}}>Descripción</p>
                            </div>
                            <div style={{width: '100px'}}>
                                <p className='text-bold text-center mb-0' style={{fontSize: '11px'}}>Precio Unitario</p>
                            </div>
                            <div style={{width: '100px'}}>
                                <p className='text-bold text-center mb-0' style={{fontSize: '11px'}}>Impuestos</p>
                            </div>
                            <div style={{width: '90px'}}>
                                <p className='text-bold text-center mb-0' style={{fontSize: '11px'}}>Importe</p>
                            </div>
                        </div>
                        <hr className='my-1' />
                        {props.cfdi.conceptos.map((c, i) => {
                            return (
                                <div className='d-flex mb-2' key={props.cfdi.comprobante.Sello.substring(i, i+5)}>
                                    <div style={{width: '60px'}}>
                                        <p className='text-bold text-center' style={{fontSize: '9px'}}>{c['$'].Cantidad}</p>
                                    </div>
                                    <div style={{width: '60px'}}>
                                        <p className='text-bold text-center mb-1' style={{fontSize: '9px'}}>{c['$'].ClaveUnidad === 'E48' ? 'SERVICIO - E48' : ''}</p>
                                    </div>
                                    <div style={{width: '70px'}}>
                                    <p className='text-bold text-center' style={{fontSize: '9px'}}>{c['$'].ClaveProdServ} -</p>
                                    </div>
                                    <div style={{width: '280px'}}>
                                        <p className='text-bold mb-0' style={{fontSize: '9px'}}>{c['$'].Descripcion.toUpperCase()}</p>
                                    </div>
                                    <div style={{width: '100px'}}>
                                        <p className='text-bold text-center' style={{fontSize: '9px'}}>$ {formatMoney(c['$'].ValorUnitario)}</p>
                                    </div>
                                    <div style={{width: '100px'}}>
                                        <p className='text-bold text-center mb-0' style={{fontSize: '9px'}}>{c["cfdi:Impuestos"][0]["cfdi:Traslados"][0]["cfdi:Traslado"][0].$.Impuesto ==='002' ? `002 - IVA ${c["cfdi:Impuestos"][0]["cfdi:Traslados"][0]["cfdi:Traslado"][0].$.TasaOCuota} %` : ''}</p>
                                        <p className='text-bold text-center mb-0' style={{fontSize: '9px'}}>{`$ ${formatMoney(c["cfdi:Impuestos"][0]["cfdi:Traslados"][0]["cfdi:Traslado"][0].$.Importe)}`}</p>
                                    </div>
                                    <div style={{width: '90px'}}>
                                        <p className='text-bold text-center mb-0' style={{fontSize: '9px'}}>$ {formatMoney(c['$'].Importe)}</p>
                                    </div>
                                </div>
                            )
                            })}
                    </div>
                </div>
                
            </>
        )
    }

    const getRelacionados = (re) => {
        switch(re) {
            case '01': return '01 - Nota de crédito de los documentos relacionados';
            case '02': return '02 - Nota de débito de los documentos relacionados';
            case '03': return '03 - Devolución de mercancía sobre facturas o traslados previos';
            case '04': return '04 - Sustitución de los CFDI previos';
            case '05': return '05 - Traslados de mercancías facturados previamente';
            case '06': return '06 - Factura generada por los traslados previos';
            case '07': return '07 - CFDI por aplicación de anticipo';
            default: return '';
        }
    }

    useEffect(() => {
        // console.log(props.cfdi);
        if(props.cfdi.comprobante) {
            document.title = `F-${props.cfdi.comprobante.Serie+props.cfdi.comprobante.Folio} ${props.factura.hospital}`;
            setRenderHoja(
                <div id='printDiv'>
                    <div style={{position: 'absolute', width: '816px', height: '1056px', background: '#FFF'}} className='exo2 p-0'>
                        <div style={{position: 'absolute', top: '20px', right: '24px', width: '340px',border: 'solid black 1.5px', borderRadius: '5px'}}>
                            <p style={{color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}} className='text-bold mb-0 text-center'>
                                &nbsp;&nbsp;Factura electronica CFDI {props.cfdi.comprobante.Version}
                            </p>
                            <p className='text-center text-bold' style={{fontSize: '22px', marginBottom: '-10px'}}>Factura</p>
                            <p className='text-center text-bold' style={{fontSize: '26px', color: 'red', marginBottom: '-4px'}}>{props.cfdi.comprobante.Serie + ' ' + props.cfdi.comprobante.Folio}</p>
                            <hr className='my-1' />
                            <div className='d-flex align-items-stretch' style={{width: '340px', color: '#000'}}>
                                <p style={{width: '120px', fontSize: '10px'}} className='my-1 px-1 d-flex align-items-center'>Fecha:</p>
                                <p style={{fontSize: '10px'}} className='my-1 px-1 text-bold'>{getDate(props.cfdi.comprobante.Fecha, 'DDD')} - {getDate(props.cfdi.comprobante.Fecha, 'HH:mm:ss')}</p>
                            </div>
                            <div className='d-flex align-items-stretch' style={{width: '340px', color: '#000'}}>
                                <p style={{width: '120px', fontSize: '10px'}} className='mb-1 px-1 d-flex align-items-center'>Lugar de Expedición:</p>
                                <p style={{fontSize: '10px'}} className='mb-1 px-1 text-bold'>{props.cfdi.comprobante.LugarExpedicion}</p>
                            </div>
                            <div className='d-flex align-items-stretch' style={{width: '340px', color: '#000'}}>
                                <p style={{width: '120px', fontSize: '10px'}} className='mb-1 px-1 d-flex align-items-center'>Tipo de Comprobante:</p>
                                <p style={{fontSize: '10px'}} className='mb-1 px-1 text-bold'>{props.cfdi.comprobante.TipoDeComprobante === 'I' ? 'I - Ingreso' : ''}</p>
                            </div>
                            <div className='d-flex align-items-stretch' style={{width: '340px', color: '#000'}}>
                                <p style={{width: '120px', fontSize: '10px'}} className='mb-1 px-1 d-flex align-items-center'>Folio Fiscal:</p>
                                <p style={{fontSize: '10px'}} className='mb-1 px-1 text-bold'>{props.cfdi.complemento.UUID}</p>
                            </div>
                            <div className='d-flex align-items-stretch' style={{width: '340px', color: '#000'}}>
                                <p style={{width: '120px', fontSize: '10px'}} className='mb-1 px-1 d-flex align-items-center'>No. Certificado Emisor:</p>
                                <p style={{fontSize: '10px'}} className='mb-1 px-1 text-bold'>{props.cfdi.comprobante.NoCertificado}</p>
                            </div>
                        </div>
                        <div style={{marginTop: '20px', marginLeft: '24px'}}>
                            <img src="/Juvalfri.svg" alt='juvalfri-logo' style={{width: "230px", height: 'auto'}} />
                        </div>
                        {/* <div style={{marginLeft: '24px', marginTop: '0px', width: '420px'}}>
                            <p className='text-bold mb-0' style={{fontSize: '13px'}}>{props.cfdi.emisor.Nombre}</p>
                            <p className='text-bold mb-0' style={{fontSize: '11px'}}>{props.emisor.regimenFiscal}</p>
                            <p className='text-bold mb-0' style={{fontSize: '11px'}}>RFC: {props.emisor.rfc}</p>
                            <p className='text-bold mb-0' style={{fontSize: '10px'}}>{props.emisor.direccion}</p>
                            <p className='text-bold mb-0' style={{fontSize: '10px'}}>Tel: {props.emisor.telefono}</p>
                        </div> */}
                        <div style={{marginLeft: '24px', marginTop: '4px', border: 'solid black 1.5px', borderRadius: '5px', width: '416px'}}>
                            <p style={{color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}} className='text-bold mb-0'>
                                    &nbsp;&nbsp;Emisor
                                </p>
                            <div style={{padding: '4px'}}>
                                <p className='text-bold mb-0' style={{fontSize: '12px'}}>{props.cfdi.emisor.Nombre}</p>
                                <p className='text-bold mb-0' style={{fontSize: '10px'}}>{props.emisor.regimenFiscal}</p>
                                <p className='text-bold mb-0' style={{fontSize: '10px'}}>R.F.C.: {props.emisor.rfc}</p>
                                <p className='text-bold mb-0' style={{fontSize: '9px'}}>{props.emisor.direccion}</p>
                                <p className='text-bold mb-0' style={{fontSize: '9px'}}>Tel: {props.emisor.telefono}</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div style={{marginTop: '10px',width: '770px',border: 'solid black 1.5px', borderRadius: '5px'}}>
                                <p style={{color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}} className='text-bold mb-0'>
                                    &nbsp;&nbsp;Receptor
                                </p>
                                <div className='d-flex'>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '40px', fontSize: '10px'}} className='my-1 px-1 d-flex align-items-center'>Cliente:</p>
                                        <p style={{fontSize: '10px'}} className='my-1 px-1 text-bold'>{props.cfdi.receptor.Nombre}</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '30px', fontSize: '10px'}} className='my-1 px-1 d-flex align-items-center'>R.F.C.:</p>
                                        <p style={{fontSize: '10px'}} className='my-1 px-1 text-bold'>{props.receptor.rfc}</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '65px', fontSize: '10px'}} className='my-1 px-1 d-flex align-items-center'>Uso de CFDI:</p>
                                        <p style={{fontSize: '10px'}} className='my-1 px-1 text-bold'>{props.cfdi.receptor.UsoCFDI === 'G03' ? 'G03 ‐ Gastos en general' : ''}</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                    <p style={{width: '50px', fontSize: '10px'}} className='mb-1 px-1 d-flex align-items-center'>Dirección:</p>
                                    <p style={{fontSize: '10px'}} className='mb-1 px-1 text-bold'>{props.receptor.direccion}</p>
                                </div>
                                <hr className='my-1' />
                                <div className='d-flex'>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '50px', fontSize: '10px'}} className='mb-1 px-1 d-flex align-items-center'>Moneda:</p>
                                        <p style={{fontSize: '10px'}} className='mb-1 px-1 text-bold'>{props.cfdi.comprobante.Moneda} - Peso Mexicano</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{color: '#000'}}>
                                        <p style={{width: '80px', fontSize: '10px'}} className='mb-1 px-1 d-flex align-items-center'>Tipo de Cambio:</p>
                                        <p style={{fontSize: '10px'}} className='mb-1 px-1 text-bold'>1.0000</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {getEquipos()}

                        <div style={{position: 'absolute', bottom: '20px', width: '816px', left: '0px'}}>
                            <div className='d-flex justify-content-center' style={{width: '770px' }}>
                                <div>
                                    <p className='mb-0' style={{fontSize: '8px'}}>SERVICIO DE MANTENIMIENTO CORRECTIVO A EQUIPOS MÉDICOS</p>
                                    <p className='mb-0' style={{fontSize: '8px'}}>SEGÚN CONTRATO {props.factura.noContrato}</p>
                                    <p className='mb-0' style={{fontSize: '8px'}}>{props.factura.vigenciaContrato}</p>
                                    <p className='mb-1' style={{fontSize: '8px'}}>PERIODO DE EJECUCIÓN: {props.factura.ejecucion}</p>
                                    <p className='text-bold mb-0' style={{fontSize: '9px'}}>ESTA FACTURA AMPARA EL FOLIO {props.factura.folioActaEntrega}, DEL ACTA DE ENTREGA RECEPCIÓN</p>
                                </div>
                                <div className='mx-3'>
                                    <img src="/sello-juvalfri.svg" alt='juvalfri-sello' style={{width: "150px", height: 'auto'}} />
                                </div>
                                <div className='text-center' style={{marginTop: '20px'}}>
                                    <p className='text-bold mb-0' style={{fontSize: '8px'}}>_______________________________</p>
                                    <p className='text-bold mb-0' style={{fontSize: '8px'}}>{props.emisor.administradorUnico.toUpperCase()}</p>
                                    <p className='text-bold mb-0' style={{fontSize: '8px'}}>ADMINISTRADOR ÚNICO</p>
                                </div>
                            </div>
                            

                            <hr style={{height: '2px', color: '#611c35', opacity: '75%'}} className='my-1 mx-4' />

                            <div className='d-flex justify-content-arround mx-4'>
                                <div style={{width: '550px'}}>
                                    <p className='my-0 mx-2' style={{fontSize: '10px'}}>IMPORTE CON LETRA:</p>
                                    <p className='my-0 mx-2 text-bold' style={{fontSize: '10px'}}>{props.factura.importeLetra.toUpperCase()}</p>
                                    <hr style={{height: '2px', color: '#611c35', opacity: '75%'}} className='my-1 mx-2' />
                                    <div className='d-flex mx-2 mb-2'>
                                        <div style={{width: '270px'}}>
                                            <p className='text-bold mb-0' style={{fontSize: '8px'}}>Tipo de Relación: </p>
                                            { props.cfdi.relacionados ? <p className='text-bold mb-0' style={{fontSize: '7px'}}>{getRelacionados(props.cfdi.relacionados[0].$.TipoRelacion)}</p> : <></> }
                                            <p className='text-bold mb-0 mt-1 text-center' style={{fontSize: '8px'}}>{props.cfdi.comprobante.MetodoPago === 'PPD' ? 'PPD - PAGO EN PARCIALIDADES O DIFERIDO' :  props.cfdi.comprobante.MetodoPago === 'PUE' ? 'PUE - PAGO EN UNA SOLA EXHIBICIÓN' : ''}</p>
                                            <div className='d-flex'>
                                                <div style={{width: '135px'}}>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '7px'}}>CONDICIONES DE PAGO</p>
                                                    
                                                </div>
                                                <div style={{width: '135px'}}>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '7px'}}>FORMA DE PAGO</p>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '6px'}}>{props.cfdi.comprobante.FormaPago === '03' ? '03 ‐ Transferencia electrónica de fondos' : ''}</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-1'>
                                                <div style={{width: '135px'}}>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '7px'}}>No.CERTIFICADO EMISOR</p>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '6px'}}>{props.cfdi.comprobante.NoCertificado}</p>
                                                </div>
                                                <div style={{width: '135px'}}>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '7px'}}>No.CERTIFICADO SAT</p>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '6px'}}>{props.cfdi.complemento.NoCertificadoSAT}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{width: '270px'}}>
                                            <p className='text-bold mb-0' style={{fontSize: '8px'}}>UUID Relacionado</p>
                                            { props.cfdi.relacionados ? <p className='text-bold mb-0' style={{fontSize: '7px'}}>{props.cfdi.relacionados[0]["cfdi:CfdiRelacionado"][0].$.UUID}</p> : <></> }
                                            <p className='text-bold text-center mb-0 mt-1' style={{fontSize: '8px'}}>EFECTOS FISCALES AL PAGO</p>
                                            <p className='text-bold text-center mb-0' style={{fontSize: '7px'}}>CUENTA DE PAGO</p>
                                            <p className='text-bold text-center mb-0' style={{fontSize: '6px'}}>&nbsp;</p>
                                            <div className='d-flex mt-1'>
                                                <div style={{width: '135px'}}>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '7px'}}>FECHA Y HORA DE CERTIFICACIÓN</p>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '6px'}}>{getDate(props.cfdi.complemento.FechaTimbrado, 'DDD')} - {getDate(props.cfdi.complemento.FechaTimbrado, 'HH:mm:ss')}</p>
                                                </div>
                                                <div style={{width: '135px'}}>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '7px'}}>FOLIO FISCAL</p>
                                                    <p className='text-bold text-center mb-0' style={{fontSize: '6px'}}>{props.cfdi.complemento.UUID}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex' style={{width: '200px'}}>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px'}}>IMPORTE: </p>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px', textAlign: 'end'}}>{formatMoney(props.cfdi.comprobante.SubTotal)}</p>
                                    </div>
                                    <div className='d-flex' style={{width: '200px'}}>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px'}}>DESCUENTOS:</p>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px', textAlign: 'end'}}>0.00</p>
                                    </div>
                                    <div className='d-flex' style={{width: '200px'}}>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px'}}>SUBTOTAL:</p>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px', textAlign: 'end'}}>{formatMoney(props.cfdi.comprobante.SubTotal)}</p>
                                    </div>
                                    <div className='d-flex' style={{width: '200px'}}>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px'}}>I.V.A. 16%:</p>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px', textAlign: 'end'}}>{formatMoney(props.cfdi.totalImpuestos.TotalImpuestosTrasladados)}</p>
                                    </div>
                                    <div className='d-flex' style={{width: '200px'}}>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px'}}>TOTAL:</p>
                                        <p className='text-bold my-0' style={{fontSize: '13px', width: '100px', textAlign: 'end'}}>{formatMoney(props.cfdi.comprobante.Total)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div style={{marginLeft: '24px'}}>
                                    <QRCode
                                        value={`https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?&id=${props.cfdi.complemento.UUID}&re=${props.cfdi.emisor.Rfc}&rr=${props.cfdi.receptor.Rfc}&tt=${props.cfdi.comprobante.Total}&fe=${props.cfdi.comprobante.Sello.slice(-8)}`}
                                        size={128}
                                        bgColor={"#ffffff"}
                                        fgColor={"#000000"}
                                        level={"Q"}
                                        includeMargin={false}
                                        renderAs={"svg"}
                                    />
                                </div>
                                <div style={{width: '610px', marginLeft: '20px'}}>
                                    <p className='text-bold mb-0 text-center' style={{fontSize: '9px'}}>SELLO DIGITAL DEL CFDI EMISOR</p>
                                    <p className='text-break text-center mb-0 text-bold' style={{fontSize: '6px', paddingBottom: '4px'}}>{props.cfdi.comprobante.Sello}</p>

                                    <p className='text-bold mb-0 text-center' style={{fontSize: '9px'}}>SELLO DEL SAT</p>
                                    <p className='text-break text-center mb-0 text-bold' style={{fontSize: '6px', paddingBottom: '4px'}}>{props.cfdi.complemento.SelloSAT}</p>
                                    
                                    <p className='text-bold mb-0 text-center' style={{fontSize: '9px'}}>CADENA ORIGINAL DEL COMPLEMENTO DE CERTIFICACIÓN DIGITAL DEL SAT</p>
                                    <p className='text-center text-break text-bold mb-0' style={{fontSize: '6px', width:'610px'}}>||{props.cfdi.complemento.Version}|{props.cfdi.complemento.UUID}|{props.cfdi.complemento.FechaTimbrado}|{props.cfdi.complemento.RfcProvCertif}|</p>
                                    <p className='text-center text-break text-bold mb-1' style={{fontSize: '6px', paddingBottom: '4px', width:'610px'}}>{props.cfdi.complemento.SelloCFD}|{props.cfdi.complemento.NoCertificadoSAT}||</p>
                                    <p className='text-bold mb-0 text-center' style={{fontSize: '10px'}}>"ESTE DOCUMENTO ES LA REPRESENTACION IMPRESA DE UN CFDI"</p>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: '700px', paddingTop: '150px', marginLeft: '48px'}}>
                            <div className='d-flex'>
                                <div>
                                    <p className='text-bold mb-0' style={{fontSize: '12px'}}>______________________________________________</p>
                                    <p className='text-bold mb-0' style={{fontSize: '12px'}}>{props.factura.dirUnMed}</p>
                                    <p className='text-bold mb-5' style={{fontSize: '12px'}}>Director de la Unidad Médica</p>
                                </div>
                                <div style={{marginLeft: '48px'}}>
                                    <p className='text-bold mb-0' style={{fontSize: '12px'}}>_______________________________________</p>
                                    <p className='text-bold mb-0 text-center' style={{fontSize: '12px'}}>SELLO</p>
                                </div>
                            </div>
                            
                            <p className='text-bold mb-0 mt-3' style={{fontSize: '12px'}}>______________________________________________</p>
                            <p className='text-bold mb-0' style={{fontSize: '12px'}}>{props.factura.drmas}</p>
                            <p className='text-bold mb-5' style={{fontSize: '12px'}}>Director de Recursos Materiales Abastecimientos y Servicios</p>

                            <p className='text-bold mb-0 mt-3' style={{fontSize: '12px'}}>______________________________________________</p>
                            <p className='text-bold mb-0' style={{fontSize: '12px'}}>{props.factura.jud}</p>
                            <p className='text-bold mb-5' style={{fontSize: '12px'}}>JUD de Mantenimiento de Equipo</p>
                            
                            <p className='text-bold mb-0 mt-3' style={{fontSize: '12px'}}>______________________________________________</p>
                            <p className='text-bold mb-0' style={{fontSize: '12px'}}>{props.factura.subMan}</p>
                            <p className='text-bold mb-5' style={{fontSize: '12px'}}>Subdirector de Mantenimiento y Servicios</p>

                            <p className='my-5' style={{fontSize: '11px'}}>Las presentes firmas forman parte de la factura No. {props.cfdi.comprobante.Serie + '' + props.cfdi.comprobante.Folio}, que ampara la prestación del servicio en el contrato {props.factura.noContrato}</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            setRenderHoja(
                <>
                    <p className='h5 m-4'>No se ha validado ningún CFDI</p>
                </>
            )
        }
    }, [])

    return(
        <div style={{background: '#FFF'}}>
            <div style={{marginBottom: '12px'}} className='d-flex'>
                <Button variant='contained' className='mx-2' color='info' onClick={printDocument}>Imprimir factura &nbsp; <i className="fad fa-print"></i></Button>
            </div>
            {renderHoja}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps)(PreviewFactura);