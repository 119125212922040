import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import { ThemeProvider, createTheme } from '@mui/material/styles';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import LuxonUtils from '@date-io/luxon'

import NavBar from "./components/layout/NavBar";
import Landing from "./components/layout/Landing";
// import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";

// Reportes
// import IndexReportes from "./components/reportes/DescargarTabla";
import TablaReportes from "./components/reportes/TablaReportes";
import NuevoReporte from "./components/reportes/NuevoReporte";
import EditarReporte from "./components/reportes/EditarReporte";
import HojaDiagnostico from "./components/reportes/hojaDiagnostico/HojaDiagnostico";
import OrdenServicio from "./components/reportes/ordenServicio/OrdenServicio";
import Cotizacion from "./components/reportes/cotizacion/Cotizacion";
import HojaCotizacion from "./components/reportes/cotizacion/HojaCotizacion";
import Seguimiento from "./components/reportes/seguimiento/Seguimiento";
import TablaPendientes from "./components/reportes/pendientes/TablaPendientes";

import Estadisticas from "./components/estadisticas/Estadisticas";
import TablaGeneralReportes from "./components/estadisticas/TablaGeneralReportes";

import ListaPrecios from "./components/listaPrecios/ListaPrecios";

import Contratos from "./components/contratos/Contratos";
import NuevoContrato from "./components/contratos/NuevoContrato";
import EditarContrato from "./components/contratos/EditarContrato";

import TablaFacturas from "./components/facturas/TablaFacturas";
import NuevaFactura from "./components/facturas/NuevaFactura";
import EditarFactura from "./components/facturas/EditarFactura";
import PrintFactura from "./components/facturas/PrintFactura";
import ActaEntrega from "./components/facturas/ActaEntrega";
import ListaRefacciones from "./components/facturas/ListaRefacciones";

import Inventario from "./components/inventario/Inventario";

import './App.css';

import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import OrdenServicioSesderma from "./components/reportes/ordenServicio/OrdenServicioSesderma";
import CotizacionSesderma from "./components/reportes/cotizacion/CotizacionSesderma";

loadProgressBar();

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}

//ThemeProvider
const theme = createTheme({
  palette: {
    primary: {
      main: '#bd2e49',
      light: '#ff5f8d',
      dark: '#842033',
    },
    secondary: {
      main: '#3d9d81',
      light: '#6EC0B4',
      dark: '#1F6D58',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: [
      'Exo 2',
    ].join(','),
  },
});

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={LuxonUtils} locale='es'>
        <Router>
          <ThemeProvider theme={theme}>
            <div className="App">
              <NavBar />
              <Route exact path="/" component={Landing} />
              {/* <Route exact path="/register" component={Register} /> */}
              <Route exact path="/login" component={Login} />

              {/* <Route exact path="/admin" component={Admin} /> */}

              <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />

                  //Reportes routes
                {/* <PrivateRoute exact path="/reportes" component={IndexReportes} /> */}
                <PrivateRoute exact path="/reportes" component={TablaReportes} />
                <PrivateRoute exact path="/reportes/nuevo" component={NuevoReporte} />
                <PrivateRoute exact path="/reportes/editar/:id" component={EditarReporte} />
                <PrivateRoute exact path="/reportes/hojaDiagnostico/:id" component={HojaDiagnostico} />
                <PrivateRoute exact path="/reportes/ordenServicio/:id" component={OrdenServicio} />
                <PrivateRoute exact path="/reportes/ordenServicio/sesderma/:id" component={OrdenServicioSesderma} />
                <PrivateRoute exact path="/reportes/cotizacion/hojaCotizacion" component={HojaCotizacion} />
                <PrivateRoute exact path="/reportes/cotizacion/hojaCotizacion/sesderma" component={CotizacionSesderma} />
                <PrivateRoute exact path="/reportes/cotizacion/:id" component={Cotizacion} />
                <PrivateRoute exact path="/reportes/seguimiento/:id" component={Seguimiento} />
                <PrivateRoute exact path="/reportes/reportesPendientes" component={TablaPendientes} />

                <PrivateRoute exact path="/estadisticas" component={Estadisticas} />
                <PrivateRoute exact path="/estadisticas/tablaGeneralReportes" component={TablaGeneralReportes} />

                <PrivateRoute exact path="/listaPrecios" component={ListaPrecios} />

                <PrivateRoute exact path="/contratos" component={Contratos} />
                <PrivateRoute exact path="/contratos/nuevo" component={NuevoContrato} />
                <PrivateRoute exact path="/contratos/editar/:id" component={EditarContrato} />

                <PrivateRoute exact path="/facturas" component={TablaFacturas} />
                <PrivateRoute exact path="/facturas/nueva" component={NuevaFactura} />
                <PrivateRoute exact path="/facturas/editar/:id" component={EditarFactura} />
                <PrivateRoute exact path="/facturas/print/:id" component={PrintFactura} />
                <PrivateRoute exact path="/facturas/actaEntrega/:id" component={ActaEntrega} />
                <PrivateRoute exact path="/facturas/listaRefacciones" component={ListaRefacciones} />

                <PrivateRoute exact path="/inventario" component={Inventario} />

              </Switch>
            </div>
          </ThemeProvider>
        </Router>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
