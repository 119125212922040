import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import QRCode from 'qrcode.react';
import axios from 'axios';

import { getDate, formatMoney } from '../../utils/functions/UtilsJuvalfri';

function PrintFactura(props) {

    const [data, setData] = useState(null);
    const [renderHoja, setRenderHoja] = useState(<></>);
    let { id } = useParams();
    const [emisor, setEmisor] = useState({
        denominacion: 'Comercializadora de Servicios Integrales Juvalfri',
        regimenFiscal: '626 - Régimen Simplificado de Confianza',
        rfc: 'CSI200211-SI1',
        direccion: 'Quiches No. 19 Int. 4, Col. La Raza, Azcapotzalco, México CDMX',
        telefono: '55 4861-7332',
        administradorUnico: 'Ing. Abdias Lopez Reyes'
    });
    const [receptor, setReceptor] = useState({
        denominacion: 'GOBIERNO DE LA CIUDAD DE MÉXICO / SECRETARÍA DE SALUD',
        rfc: 'GDF9712054NA',
        direccion: 'PLAZA DE LA CONSTITUCION S/N, COL. CENTRO DE LA CIUDAD DE MÉXICO AREA 1, CUAUHTÉMOC CIUDAD DE MÉXICO C.P. 06000'
    });
    const [factura, setFactura] = useState({
        noFactura: '',
        cfdi: '',
        fechaEmision: new Date(),
        subTotal: 0.00,
        iva: 0.00,
        total: 0.00,
        reportes: [],
        conceptos: [],
        hospital: '',
        estatus: 'PENDIENTE DE FIRMAS',
        noContrato: 'NO. SSCDMX-DGAF-074-2021',
        vigenciaContrato: 'VIGENCIA DEL 01 DE ENERO AL 31 DE DICIEMBRE DEL 2021',
        ejecucion: 'DEL 13 AL 23 DE ABRIL DEL 2021',
        serie: '',
        folio: '',
        folioActaEntrega: '',
        importeLetra: '',
        dirUnMed: '',
        drmas: 'David Melchor Miranda Ortega',
        jud: 'Ing. Darío Alejandro Alvarado Torres',
        subMan: 'Ing. José Mauricio Pérez Pérez',
        fechaHospital: new Date(),
        fechaServiciosMedicos: new Date(),
        fechaContrarecivo: new Date(),
        fechaPago: new Date(),
        observaciones: '',
    });
    const [cfdi, setCfdi] = useState({});
    const [conceptosD, setConceptosD] = useState(null);
    const [reportesD, setReportesD] = useState(null);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            let res;
            if (props.location.state !== undefined) {
                res = await axios.get(`/api/facturas/${props.location.state.id}`);
            } else {
                res = await axios.get(`/api/facturas/${id}`);
            }
            if (res.status === 200) {
                setData(res.data);
                let dtaFac = res.data.factura;
                dtaFac.fechaHospital = res.data.factura.fechaHospital ? new Date(res.data.factura.fechaHospital) : null;
                dtaFac.fechaServiciosMedicos = res.data.factura.fechaServiciosMedicos ? new Date(res.data.factura.fechaServiciosMedicos) : null;
                setFactura(dtaFac);
                setEmisor(res.data.emisor);
                setReceptor(res.data.receptor);
                setReportesD(res.data.factura.reportes);
                setConceptosD(res.data.factura.conceptos);
                parseCfdi2(res.data.factura.cfdi, res.data.factura.conceptos);
                // setFactura(state => {return {... state, fechaHospital: res.data.factura.fechaHospital ? new Date(res.data.factura.fechaHospital) : null}});
                // setFactura(state => {return {... state, fechaServiciosMedicos: res.data.factura.fechaServiciosMedicos ? new Date(res.data.factura.fechaServiciosMedicos) : null}});
                // console.log(res.data);
            }
        } catch (err) {
            console.log(err);
            alert('Ocurrió un error al cargar el reporte, favor de intentarlo mas tarde');
        }
    }

    const parseCfdi2 = async (dd, cc) => {
        if (dd !== '') {
            try {
                const res = await axios.post('/api/facturas/parseCfdi', { "cfdi": dd })
                if (res.status === 200) {
                    // console.log(res.data.cfdi2.conceptos);
                    setEmisor(state => {
                        return {
                            ...state,
                            denominacion: res.data.cfdi2.emisor.Nombre,
                            rfc: res.data.cfdi2.emisor.Rfc
                        }
                    });
                    setReceptor(state => {
                        return {
                            ...state,
                            denominacion: res.data.cfdi2.receptor.Nombre,
                            rfc: res.data.cfdi2.receptor.Rfc
                        }
                    });
                    setFactura(state => {
                        return {
                            ...state,
                            noFactura: res.data.cfdi2.comprobante.Serie + res.data.cfdi2.comprobante.Folio,
                            folio: res.data.cfdi2.comprobante.Folio,
                            serie: res.data.cfdi2.comprobante.Serie,
                            subTotal: res.data.cfdi2.comprobante.SubTotal,
                            iva: res.data.cfdi2.totalImpuestos.TotalImpuestosTrasladados,
                            total: res.data.cfdi2.comprobante.Total,
                            fechaEmision: res.data.cfdi2.comprobante.Fecha,
                        }
                    })
                    // setCfdi(res.data.cfdi2);
                    let tmpCfdi = res.data.cfdi2;
                    let tmp = res.data.cfdi2.conceptos;
                    // console.log(tmp);
                    res.data.cfdi2.conceptos.forEach((c, i) => {
                        tmp[i]['$'].Descripcion = cc[i];
                    })
                    tmpCfdi.conceptos = tmp;
                    // setCfdi(state => { return {... state, conceptos: tmp}});
                    setCfdi(tmpCfdi);
                }
            } catch (e) {
                console.log(e);
                alert('Ocurrió un error con los datos ingresados, favor de revisar los datos e intentarlo de nuevo');
            }
        }
    }

    const printDocument = () => {
        let printContents = document.getElementById('printDiv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();
    }

    // cambia el modo de edicion del documento
    useEffect(() => {
        if (edit)
            document.designMode = "on";
        else
            document.designMode = "off";
    }, [edit])

    const getEquipos = () => {
        return (
            <>
                <div className='d-flex justify-content-center'>
                    <div style={{ marginTop: '4px', width: '770px' }}>
                        <hr style={{ height: '2px', color: '#611c35', opacity: '75%', marginTop: '4px', marginBottom: '4px' }} />
                        <p className='text-bold text-center mb-2' style={{ fontSize: '12px' }}>{factura.hospital}</p>
                        <div className='d-flex'>
                            <div style={{ width: '60px' }}>
                                <p className='text-bold text-center mb-0' style={{ fontSize: '11px' }}>Cantidad</p>
                            </div>
                            <div style={{ width: '60px' }}>
                                <p className='text-bold text-center mb-0' style={{ fontSize: '11px' }}>Unidad</p>
                            </div>
                            <div style={{ width: '70px' }}>
                                <p className='text-bold text-center mb-0' style={{ fontSize: '11px' }}>Clave</p>
                            </div>
                            <div style={{ width: '280px' }}>
                                <p className='text-bold text-center mb-0' style={{ fontSize: '11px' }}>Descripción</p>
                            </div>
                            <div style={{ width: '100px' }}>
                                <p className='text-bold text-center mb-0' style={{ fontSize: '11px' }}>Precio Unitario</p>
                            </div>
                            <div style={{ width: '100px' }}>
                                <p className='text-bold text-center mb-0' style={{ fontSize: '11px' }}>Impuestos</p>
                            </div>
                            <div style={{ width: '90px' }}>
                                <p className='text-bold text-center mb-0' style={{ fontSize: '11px' }}>Importe</p>
                            </div>
                        </div>
                        <hr className='my-1' />
                        {cfdi.conceptos.map((c, i) => {
                            return (
                                <div className='d-flex mb-2' key={cfdi.comprobante.Sello.substring(i, i + 5)}>
                                    <div style={{ width: '60px' }}>
                                        <p className='text-bold text-center' style={{ fontSize: '9px' }}>{c['$'].Cantidad}</p>
                                    </div>
                                    <div style={{ width: '60px' }}>
                                        <p className='text-bold text-center mb-1' style={{ fontSize: '9px' }}>{c['$'].ClaveUnidad === 'E48' ? 'SERVICIO - E48' : c['$'].ClaveUnidad === 'H87' ? 'PIEZA - H87' : c['$'].ClaveUnidad}</p>
                                    </div>
                                    <div style={{ width: '70px' }}>
                                        <p className='text-bold text-center' style={{ fontSize: '9px' }}>{c['$'].ClaveProdServ} -</p>
                                    </div>
                                    <div style={{ width: '280px' }}>
                                        <p className='text-bold mb-0' style={{ fontSize: '9px' }}>{c['$'].Descripcion.toUpperCase()}</p>
                                    </div>
                                    <div style={{ width: '100px' }}>
                                        <p className='text-bold text-center' style={{ fontSize: '9px' }}>$ {formatMoney(c['$'].ValorUnitario)}</p>
                                    </div>
                                    <div style={{ width: '100px' }}>
                                        <p className='text-bold text-center mb-0' style={{ fontSize: '9px' }}>{c["cfdi:Impuestos"][0]["cfdi:Traslados"][0]["cfdi:Traslado"][0].$.Impuesto === '002' ? `002 - IVA ${c["cfdi:Impuestos"][0]["cfdi:Traslados"][0]["cfdi:Traslado"][0].$.TasaOCuota} %` : ''}</p>
                                        <p className='text-bold text-center mb-0' style={{ fontSize: '9px' }}>{`$ ${formatMoney(c["cfdi:Impuestos"][0]["cfdi:Traslados"][0]["cfdi:Traslado"][0].$.Importe)}`}</p>
                                    </div>
                                    <div style={{ width: '90px' }}>
                                        <p className='text-bold text-center mb-0' style={{ fontSize: '9px' }}>$ {formatMoney(c['$'].Importe)}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </>
        )
    }

    const getRelacionados = (re) => {
        switch (re) {
            case '01': return '01 - Nota de crédito de los documentos relacionados';
            case '02': return '02 - Nota de débito de los documentos relacionados';
            case '03': return '03 - Devolución de mercancía sobre facturas o traslados previos';
            case '04': return '04 - Sustitución de los CFDI previos';
            case '05': return '05 - Traslados de mercancías facturados previamente';
            case '06': return '06 - Factura generada por los traslados previos';
            case '07': return '07 - CFDI por aplicación de anticipo';
            case '08': return '08 - Factura generada por pagos en parcialidades';
            case '09': return '09 - Factura generada por pagos diferidos';
            default: return '';
        }
    }

    const getUsoCFDI = (u) => {
        switch (u) {
            case 'G01': return 'G01 - Adquisición de mercancias';
            case 'G02': return 'G02 - Devoluciones, descuentos o bonificaciones';
            case 'G03': return 'G03 - Gastos en general';
            default: return '';
        }
    }

    const getMetodoPago = (m) => {
        switch (m) {
            case 'PUE': return 'PUE - Pago en una sola exhibición';
            case 'PPD': return 'PPD - Pago en parcialidades o diferido';
            default: return '';
        }
    }

    const getFormaPago = (f) => {
        switch (f) {
            case '01': return '01 - Efectivo';
            case '02': return '02 - Cheque nominativo';
            case '03': return '03 - Transferencia electrónica de fondos';
            case '04': return '04 - Tarjeta de crédito';
            case '05': return '05 - Monedero electrónico';
            case '06': return '06 - Dinero electrónico';
            case '08': return '08 - Vales de despensa';
            case '12': return '12 - Dación en pago';
            case '13': return '13 - Pago por subrogación';
            case '14': return '14 - Pago por consignación';
            case '15': return '15 - Condonación';
            case '17': return '17 - Compensación';
            case '23': return '23 - Novación';
            case '24': return '24 - Confusión';
            case '25': return '25 - Remisión de deuda';
            case '26': return '26 - Prescripción o caducidad';
            case '27': return '27 - A satisfacción del acreedor';
            case '28': return '28 - Tarjeta de débito';
            case '29': return '29 - Tarjeta de servicios';
            case '30': return '30 - Aplicación de anticipos';
            case '31': return '31 - Intermediario pagos';
            case '99': return '99 - Por definir';
            default: return '';
        }
    }

    useEffect(() => {
        if (cfdi.comprobante) {
            document.title = `F-${cfdi.comprobante.Serie + cfdi.comprobante.Folio} ${factura.hospital}`;
            setRenderHoja(
                <div id='printDiv'>
                    <div style={{ position: 'absolute', width: '816px', height: '1056px', background: '#FFF' }} className='exo2 p-0'>
                        <div style={{ position: 'absolute', top: '20px', right: '24px', width: '340px', border: 'solid black 1.5px', borderRadius: '5px' }}>
                            <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} className='text-bold mb-0 text-center'>
                                &nbsp;&nbsp;Factura electrónica CFDI - {cfdi.comprobante.Version}
                            </p>
                            <p className='text-center text-bold' style={{ fontSize: '22px', marginBottom: '-10px' }}>Factura</p>
                            <p className='text-center text-bold' style={{ fontSize: '26px', color: 'red', marginBottom: '-4px' }}>{cfdi.comprobante.Serie + ' ' + cfdi.comprobante.Folio}</p>
                            <hr className='my-1' />
                            <div className='d-flex align-items-stretch' style={{ width: '340px', color: '#000' }}>
                                <p style={{ width: '120px', fontSize: '10px' }} className='my-1 px-1 d-flex align-items-center'>Fecha:</p>
                                <p style={{ fontSize: '10px' }} className='my-1 px-1 text-bold'>{getDate(cfdi.comprobante.Fecha, 'DDD')} - {getDate(cfdi.comprobante.Fecha, 'HH:mm:ss')}</p>
                            </div>
                            <div className='d-flex align-items-stretch' style={{ width: '340px', color: '#000' }}>
                                <p style={{ width: '120px', fontSize: '10px' }} className='mb-1 px-1 d-flex align-items-center'>Lugar de Expedición:</p>
                                <p style={{ fontSize: '10px' }} className='mb-1 px-1 text-bold'>{cfdi.comprobante.LugarExpedicion}</p>
                            </div>
                            <div className='d-flex align-items-stretch' style={{ width: '340px', color: '#000' }}>
                                <p style={{ width: '120px', fontSize: '10px' }} className='mb-1 px-1 d-flex align-items-center'>Tipo de Comprobante:</p>
                                <p style={{ fontSize: '10px' }} className='mb-1 px-1 text-bold'>{cfdi.comprobante.TipoDeComprobante === 'I' ? 'I - Ingreso' : ''}</p>
                            </div>
                            <div className='d-flex align-items-stretch' style={{ width: '340px', color: '#000' }}>
                                <p style={{ width: '120px', fontSize: '10px' }} className='mb-1 px-1 d-flex align-items-center'>Folio Fiscal:</p>
                                <p style={{ fontSize: '10px' }} className='mb-1 px-1 text-bold'>{cfdi.complemento.UUID}</p>
                            </div>
                            <div className='d-flex align-items-stretch' style={{ width: '340px', color: '#000' }}>
                                <p style={{ width: '120px', fontSize: '10px' }} className='mb-1 px-1 d-flex align-items-center'>No. Certificado Emisor:</p>
                                <p style={{ fontSize: '10px' }} className='mb-1 px-1 text-bold'>{cfdi.comprobante.NoCertificado}</p>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px', marginLeft: '24px' }}>
                            <img src="/Juvalfri.svg" alt='juvalfri-logo' style={{ width: "230px", height: 'auto' }} />
                        </div>
                        {/* <div style={{marginLeft: '24px', marginTop: '0px', width: '420px'}}>
                            <p className='text-bold mb-0' style={{fontSize: '13px'}}>{cfdi.emisor.Nombre}</p>
                            <p className='text-bold mb-0' style={{fontSize: '11px'}}>{emisor.regimenFiscal}</p>
                            <p className='text-bold mb-0' style={{fontSize: '11px'}}>RFC: {emisor.rfc}</p>
                            <p className='text-bold mb-0' style={{fontSize: '10px'}}>{emisor.direccion}</p>
                            <p className='text-bold mb-0' style={{fontSize: '10px'}}>Tel: {emisor.telefono}</p>
                        </div> */}
                        <div style={{ marginLeft: '24px', marginTop: '4px', border: 'solid black 1.5px', borderRadius: '5px', width: '416px' }}>
                            <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} className='text-bold mb-0'>
                                &nbsp;&nbsp;Emisor
                            </p>
                            <div style={{ padding: '4px' }}>
                                <p className='text-bold mb-0' style={{ fontSize: '12px' }}>{cfdi.emisor.Nombre}</p>
                                <p className='text-bold mb-0' style={{ fontSize: '10px' }}>{emisor.regimenFiscal}</p>
                                <p className='text-bold mb-0' style={{ fontSize: '10px' }}>R.F.C.: {emisor.rfc}</p>
                                <p className='text-bold mb-0' style={{ fontSize: '9px' }}>{emisor.direccion}</p>
                                <p className='text-bold mb-0' style={{ fontSize: '9px' }}>Tel: {emisor.telefono}</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div style={{ marginTop: '10px', width: '770px', border: 'solid black 1.5px', borderRadius: '5px' }}>
                                <p style={{ color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} className='text-bold mb-0'>
                                    &nbsp;&nbsp;Receptor
                                </p>
                                <div className='d-flex'>
                                    <div className='d-flex align-items-stretch' style={{ color: '#000' }}>
                                        <p style={{ width: '40px', fontSize: '10px' }} className='my-1 px-1 d-flex align-items-center'>Cliente:</p>
                                        <p style={{ fontSize: '10px' }} className='my-1 px-1 text-bold'>{cfdi.receptor.Nombre}</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{ color: '#000' }}>
                                        <p style={{ width: '30px', fontSize: '10px' }} className='my-1 px-1 d-flex align-items-center'>R.F.C.:</p>
                                        <p style={{ fontSize: '10px' }} className='my-1 px-1 text-bold'>{receptor.rfc}</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{ color: '#000' }}>
                                        <p style={{ width: '65px', fontSize: '10px' }} className='my-1 px-1 d-flex align-items-center'>Uso de CFDI:</p>
                                        <p style={{ fontSize: '10px' }} className='my-1 px-1 text-bold'>{getUsoCFDI(cfdi.receptor.UsoCFDI)}</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-stretch' style={{ color: '#000' }}>
                                    <p style={{ width: '50px', fontSize: '10px' }} className='mb-1 px-1 d-flex align-items-center'>Dirección:</p>
                                    <p style={{ fontSize: '10px' }} className='mb-1 px-1 text-bold'>{receptor.direccion}</p>
                                </div>
                                <hr className='my-1' />
                                <div className='d-flex'>
                                    <div className='d-flex align-items-stretch' style={{ color: '#000' }}>
                                        <p style={{ width: '50px', fontSize: '10px' }} className='mb-1 px-1 d-flex align-items-center'>Moneda:</p>
                                        <p style={{ fontSize: '10px' }} className='mb-1 px-1 text-bold'>{cfdi.comprobante.Moneda} - Peso Mexicano</p>
                                    </div>
                                    <div className='d-flex align-items-stretch' style={{ color: '#000' }}>
                                        <p style={{ width: '80px', fontSize: '10px' }} className='mb-1 px-1 d-flex align-items-center'>Tipo de Cambio:</p>
                                        <p style={{ fontSize: '10px' }} className='mb-1 px-1 text-bold'>1.0000</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {getEquipos()}

                        <div style={{ position: 'absolute', bottom: '20px', width: '816px', left: '0px' }}>
                            <div className='d-flex justify-content-center' style={{ width: '770px' }}>
                                <div>
                                    <p className='mb-0' style={{ fontSize: '8px' }}>SERVICIO DE MANTENIMIENTO CORRECTIVO A EQUIPOS MÉDICOS</p>
                                    <p className='mb-0' style={{ fontSize: '8px' }}>SEGÚN CONTRATO {factura.noContrato}</p>
                                    <p className='mb-0' style={{ fontSize: '8px' }}>{factura.vigenciaContrato}</p>
                                    <p className='mb-1' style={{ fontSize: '8px' }}>PERIODO DE EJECUCIÓN: {factura.ejecucion}</p>
                                    <p className='text-bold mb-0' style={{ fontSize: '9px' }}>ESTA FACTURA AMPARA EL FOLIO {factura.folioActaEntrega}, DEL ACTA DE ENTREGA RECEPCIÓN</p>
                                </div>
                                <div className='mx-3'>
                                    <img src="/sello-juvalfri.svg" alt='juvalfri-sello' style={{ width: "150px", height: 'auto' }} />
                                </div>
                                <div className='text-center' style={{ marginTop: '20px' }}>
                                    <p className='text-bold mb-0' style={{ fontSize: '8px' }}>_______________________________</p>
                                    <p className='text-bold mb-0' style={{ fontSize: '8px' }}>{emisor.administradorUnico.toUpperCase()}</p>
                                    <p className='text-bold mb-0' style={{ fontSize: '8px' }}>ADMINISTRADOR ÚNICO</p>
                                </div>
                            </div>


                            <hr style={{ height: '2px', color: '#611c35', opacity: '75%' }} className='my-1 mx-4' />

                            <div className='d-flex justify-content-arround mx-4'>
                                <div style={{ width: '550px' }}>
                                    <p className='my-0 mx-2' style={{ fontSize: '10px' }}>IMPORTE CON LETRA:</p>
                                    <p className='my-0 mx-2 text-bold' style={{ fontSize: '10px' }}>{factura.importeLetra.toUpperCase()}</p>
                                    <hr style={{ height: '2px', color: '#611c35', opacity: '75%' }} className='my-1 mx-2' />
                                    <div className='d-flex mx-2 mb-2'>
                                        <div style={{ width: '270px' }}>
                                            <p className='text-bold mb-0' style={{ fontSize: '8px' }}>TIPO DE RELACIÓN: </p>
                                            {cfdi.relacionados ? <p className='text-bold mb-0' style={{ fontSize: '7px' }}>{getRelacionados(cfdi.relacionados[0].$.TipoRelacion)}</p> : <></>}
                                            {/* <p className='text-bold mb-0 mt-1 text-center' style={{ fontSize: '8px' }}>Método de pago: {getMetodoPago(cfdi.comprobante.MetodoPago)}</p> */}
                                            <div style={{ width: '270px' }} className='mt-1'>
                                                <p className='text-bold text-center mb-0' style={{ fontSize: '7px' }}>CONDICIONES DE PAGO</p>
                                                <p className='text-bold text-center mb-0' style={{ fontSize: '6px' }}></p>
                                            </div>
                                            <div className='d-flex mt-1'>
                                                <div style={{ width: '135px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '7px' }}>METODO DE PAGO:</p>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '6px' }}>{getMetodoPago(cfdi.comprobante.MetodoPago)}</p>
                                                </div>
                                                <div style={{ width: '135px' }}>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '7px' }}>FORMA DE PAGO</p>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '6px' }}>{getFormaPago(cfdi.comprobante.FormaPago)}</p>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-1'>
                                                <div style={{ width: '135px' }}>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '7px' }}>No.CERTIFICADO EMISOR</p>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '6px' }}>{cfdi.comprobante.NoCertificado}</p>
                                                </div>
                                                <div style={{ width: '135px' }}>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '7px' }}>No.CERTIFICADO SAT</p>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '6px' }}>{cfdi.complemento.NoCertificadoSAT}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '270px' }}>
                                            <p className='text-bold mb-0' style={{ fontSize: '8px' }}>UUID RELACIONADO:</p>
                                            {cfdi.relacionados ? <p className='text-bold mb-0' style={{ fontSize: '7px' }}>{cfdi.relacionados[0]["cfdi:CfdiRelacionado"][0].$.UUID}</p> : <></>}
                                            <p className='text-bold text-center mb-0 mt-1' style={{ fontSize: '8px' }}>EFECTOS FISCALES AL PAGO</p>
                                            <p className='text-bold text-center mb-0' style={{ fontSize: '7px' }}>CUENTA DE PAGO</p>
                                            <p className='text-bold text-center mb-0' style={{ fontSize: '6px' }}>&nbsp;</p>
                                            <div className='d-flex mt-1'>
                                                <div style={{ width: '135px' }}>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '7px' }}>FECHA Y HORA DE CERTIFICACIÓN</p>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '6px' }}>{getDate(cfdi.complemento.FechaTimbrado, 'DDD')} - {getDate(cfdi.complemento.FechaTimbrado, 'HH:mm:ss')}</p>
                                                </div>
                                                <div style={{ width: '135px' }}>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '7px' }}>FOLIO FISCAL</p>
                                                    <p className='text-bold text-center mb-0' style={{ fontSize: '6px' }}>{cfdi.complemento.UUID}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='d-flex' style={{ width: '200px' }}>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px' }}>IMPORTE: </p>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px', textAlign: 'end' }}>{formatMoney(cfdi.comprobante.SubTotal)}</p>
                                    </div>
                                    <div className='d-flex' style={{ width: '200px' }}>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px' }}>DESCUENTOS:</p>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px', textAlign: 'end' }}>0.00</p>
                                    </div>
                                    <div className='d-flex' style={{ width: '200px' }}>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px' }}>SUBTOTAL:</p>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px', textAlign: 'end' }}>{formatMoney(cfdi.comprobante.SubTotal)}</p>
                                    </div>
                                    <div className='d-flex' style={{ width: '200px' }}>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px' }}>I.V.A. 16%:</p>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px', textAlign: 'end' }}>{formatMoney(cfdi.totalImpuestos.TotalImpuestosTrasladados)}</p>
                                    </div>
                                    <div className='d-flex' style={{ width: '200px' }}>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px' }}>TOTAL:</p>
                                        <p className='text-bold my-0' style={{ fontSize: '13px', width: '100px', textAlign: 'end' }}>{formatMoney(cfdi.comprobante.Total)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div style={{ marginLeft: '24px' }}>
                                    <QRCode
                                        value={`https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx?&id=${cfdi.complemento.UUID}&re=${cfdi.emisor.Rfc}&rr=${cfdi.receptor.Rfc}&tt=${cfdi.comprobante.Total}&fe=${cfdi.comprobante.Sello.slice(-8)}`}
                                        size={128}
                                        bgColor={"#ffffff"}
                                        fgColor={"#000000"}
                                        level={"Q"}
                                        includeMargin={false}
                                        renderAs={"svg"}
                                    />
                                </div>
                                <div style={{ width: '610px', marginLeft: '20px' }}>
                                    <p className='text-bold mb-0 text-center' style={{ fontSize: '9px' }}>SELLO DIGITAL DEL CFDI EMISOR</p>
                                    <p className='text-break text-center mb-0 text-bold' style={{ fontSize: '6px', paddingBottom: '4px' }}>{cfdi.comprobante.Sello}</p>

                                    <p className='text-bold mb-0 text-center' style={{ fontSize: '9px' }}>SELLO DEL SAT</p>
                                    <p className='text-break text-center mb-0 text-bold' style={{ fontSize: '6px', paddingBottom: '4px' }}>{cfdi.complemento.SelloSAT}</p>

                                    <p className='text-bold mb-0 text-center' style={{ fontSize: '9px' }}>CADENA ORIGINAL DEL COMPLEMENTO DE CERTIFICACIÓN DIGITAL DEL SAT</p>
                                    <p className='text-center text-break text-bold mb-0' style={{ fontSize: '6px', width: '610px' }}>||{cfdi.complemento.Version}|{cfdi.complemento.UUID}|{cfdi.complemento.FechaTimbrado}|{cfdi.complemento.RfcProvCertif}|</p>
                                    <p className='text-center text-break text-bold mb-1' style={{ fontSize: '6px', paddingBottom: '4px', width: '610px' }}>{cfdi.complemento.SelloCFD}|{cfdi.complemento.NoCertificadoSAT}||</p>
                                    <p className='text-bold mb-0 text-center' style={{ fontSize: '10px' }}>"ESTE DOCUMENTO ES LA REPRESENTACION IMPRESA DE UN CFDI"</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '700px', paddingTop: '150px', marginLeft: '48px' }}>
                            <div className='d-flex'>
                                <div>
                                    <p className='text-bold mb-0' style={{ fontSize: '12px' }}>______________________________________________</p>
                                    <p className='text-bold mb-0' style={{ fontSize: '12px' }}>{factura.dirUnMed}</p>
                                    <p className='text-bold mb-5' style={{ fontSize: '12px' }}>Director de la Unidad Médica</p>
                                </div>
                                <div style={{ marginLeft: '48px' }}>
                                    <p className='text-bold mb-0' style={{ fontSize: '12px' }}>_______________________________________</p>
                                    <p className='text-bold mb-0 text-center' style={{ fontSize: '12px' }}>SELLO</p>
                                </div>
                            </div>

                            <p className='text-bold mb-0 mt-3' style={{ fontSize: '12px' }}>______________________________________________</p>
                            <p className='text-bold mb-0' style={{ fontSize: '12px' }}>{factura.drmas}</p>
                            <p className='text-bold mb-5' style={{ fontSize: '12px' }}>Director de Recursos Materiales Abastecimientos y Servicios</p>

                            <p className='text-bold mb-0 mt-3' style={{ fontSize: '12px' }}>______________________________________________</p>
                            <p className='text-bold mb-0' style={{ fontSize: '12px' }}>{factura.jud}</p>
                            <p className='text-bold mb-5' style={{ fontSize: '12px' }}>JUD de Mantenimiento de Equipo</p>

                            <p className='text-bold mb-0 mt-3' style={{ fontSize: '12px' }}>______________________________________________</p>
                            <p className='text-bold mb-0' style={{ fontSize: '12px' }}>{factura.subMan}</p>
                            <p className='text-bold mb-5' style={{ fontSize: '12px' }}>Subdirector de Mantenimiento y Servicios</p>

                            <p className='my-5' style={{ fontSize: '11px' }}>Las presentes firmas forman parte de la factura No. {cfdi.comprobante.Serie + '' + cfdi.comprobante.Folio}, que ampara la prestación del servicio en el contrato {factura.noContrato}</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            setRenderHoja(
                <>
                    <p className='h5 m-4'>No se ha validado ningún CFDI</p>
                </>
            )
        }
    }, [cfdi])

    return (
        <div style={{ background: '#FFF', marginTop: '10px' }}>
            <div style={{ marginBottom: '12px' }}>
                <Button variant='contained' className='mx-2' color='secondary' onClick={printDocument}>Imprimir factura &nbsp; <i className="fad fa-print"></i></Button>
                <Button className='mx-2' variant='contained' color='secondary' onClick={() => setEdit(edit => !edit)}>{edit ? 'Finalizar edición' : 'Editar documento'} &nbsp; <i className="fas fa-print" style={{ transform: 'scale(1.3)' }}></i></Button>
            </div>
            {renderHoja}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrintFactura);