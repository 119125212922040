import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

import { Container, TextField, Box, Button, InputAdornment, IconButton, Grid, Typography } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Login (props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({email: '', password: '', emailnotfound: '', passwordincorrect: ''});
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    useEffect(() => {
      if(props.auth.isAuthenticated) {
        props.history.push("/dashboard");
      }

      if (props.errors) {
        setErrors(props.errors);
      }

    }, [props, props.auth, props.errors])
  
    const handleSubmit = e => {
      e.preventDefault();
  
      const userData = {
        email: email,
        password: password
      };
  
      props.loginUser(userData);
    };

    return (
      <Container maxWidth="xl" style={{marginTop: '10px'}}>
        <Container>
          <Box mb={3}>
            <Link to="/" style={{color: '#000088'}}>
              <Typography variant="h6"><i className="fad fa-long-arrow-left"></i> &nbsp; Regresar </Typography>
            </Link>
          </Box>
        </Container>
        <Container>
          <Box mb={3}>
            <Typography variant="h5" sx={{fontWeight: 700, fontSize: "1.8em", color: '#000'}} className="text-bold">Iniciar Sesión</Typography>
          </Box>
        </Container>
        <Container>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid container maxWidth="sm" spacing={{ xs: 2, md: 3}}>
              <Grid item display="grid" xs={12} >
                <TextField
                  error={errors.email ? true : false || errors.emailnotfound ? true : false}
                  variant="outlined"
                  id="email"
                  type={'email'}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  label="Correo electrónico"
                  helperText={`${errors.email === undefined ? '' : errors.email}` || `${errors.emailnotfound === undefined ? '' : errors.emailnotfound}`}
                />
              </Grid>
              <Grid item display="grid" xs={12}>
                <TextField
                  error={errors.password ? true : false || errors.passwordincorrect ? true : false}
                  id="password"
                  label="Contraseña"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  endadornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  helperText={`${errors.password === undefined ? '' : errors.password}` || `${errors.passwordincorrect === undefined ? '' : errors.passwordincorrect}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type='submit' variant="contained">Iniciar sesión &nbsp; <i className="fad fa-sign-in"></i></Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Container>
    )
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect( mapStateToProps, { loginUser })(Login);
