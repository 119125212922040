import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import './styles.css';
import { useParams } from 'react-router-dom';

import { Button } from '@mui/material';

import { getDate } from '../../../utils/functions/UtilsJuvalfri';

function HojaDiagnostico(props) {

    const [renderHoja, setRenderHoja] = useState(<></>);
    const [data, setData] = useState({});
    let { id } = useParams();

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
    }, [props])

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            let res;
            if(props.location.state !== undefined) {
                res = await axios.get(`/api/reportes/${props.location.state.id}`);
            } else {
                res = await axios.get(`/api/reportes/${id}`);
            }
            if(res.status === 200) {
                setData(res.data);
                document.title = 'Hoja Diagnóstico ' + res.data.noReporte;
            }
        } catch (err) {
            console.log(err);
            alert('Ocurrió un error al cargar los reportes, favor de intentarlo mas tarde');
        }
    }

    const printDocument = () => {
        let printContents = document.getElementById('printDiv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        window.location.reload(true);
    }

    const getDiagnostico = () => {
        return(
            <>
                <div style={{width: '770px'}}>
                    {data.falla !== '' ? <div style={{width: '770px', border: 'solid black 1.5px', borderRadius: '5px', marginTop: '18px'}}>
                        <p style={{color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}} className='text-bold mb-0 text-center'>
                            &nbsp;&nbsp;FALLA REPORTADA
                        </p>
                        <div style={{width: '715px !important'}}>
                            <p style={{color: '#000', fontSize: '10px'}} className='p-2 mb-0 text-center'>
                                { data.fallaReportada.toUpperCase() }
                            </p>
                        </div>
                    </div> : <></>}
                    
                    <div style={{width: '770px', height: '130px', border: 'solid black 1.5px', borderRadius: '5px', marginTop: '18px'}}>
                        <p style={{color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}} className='text-bold mb-0 text-center'>
                            &nbsp;&nbsp;DESCRIPCIÓN DEL TRABAJO
                        </p>
                        <div style={{width: '715px !important'}}>
                            <p style={{color: '#000', fontSize: '10px'}} className='pt-2 pb-1 mb-0 text-center'>
                                _____________________________________________________________________________________________________________________________________________________________________
                            </p>
                            <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                _____________________________________________________________________________________________________________________________________________________________________
                            </p>
                            <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                _____________________________________________________________________________________________________________________________________________________________________
                            </p>
                            <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                _____________________________________________________________________________________________________________________________________________________________________
                            </p>
                            <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                _____________________________________________________________________________________________________________________________________________________________________
                            </p>
                        </div>
                    </div>
                    
                    <div style={{width: '770px', height: '320px', border: 'solid black 1.5px', borderRadius: '5px', marginTop: '18px'}}>
                        <p style={{color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}} className='text-bold mb-0 text-center'>
                            &nbsp;&nbsp;REFACCIONES
                        </p>
                        <div className='d-flex'>
                            <div style={{top: '20px', left: '0px', width: '67px', height:'335px'}}>
                                <p style={{color: '#000', fontSize: '10px'}} className='pt-2 pb-1 mb-0 text-center'>NO. <br />CATÁLOGO</p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                            </div>
                            <div style={{top: '20px', left: '67px', width: '633px', height:'335px'}}>
                                <p style={{color: '#000', fontSize: '10px'}} className='pt-2 pb-1 mb-0 text-center'><br />REFACCIÓN</p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    ____________________________________________________________________________________________________________________________________________
                                </p>
                            </div>
                            <div style={{top: '20px', left: '635px', width: '67px', height:'335px'}}>
                                <p style={{color: '#000', fontSize: '10px'}} className='pt-2 pb-1 mb-0 text-center'><br />CANT.</p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                                <p style={{color: '#000', fontSize: '10px'}} className='pb-1 mb-0 text-center'>
                                    __________
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        if(data.noReporte) {
            document.title = `Diagnostico ${data.noReporte}-${data.noInventario}`;
            setRenderHoja(
                <div id='printDiv' style={{position: 'absolute', width: '816px', height: '1056px'}} className='exo2 p-0'>

                    <div style={{height: '160px'}}>
                        <img src="/bg-juvalfri.svg" alt='juvalfri-logo' style={{position: 'absolute', top: '0px', left: '0px', width: "100%", height: 'auto'}} />
                    </div>

                    <div style={{position: 'absolute', top: '80px', right: '23px', width: '200px'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p className='mb-0' style={{fontWeight: '600', fontSize: '14px'}}>
                                Diagnostico &nbsp;<span style={{fontSize: '1.2em', fontWeight: '700'}}>{data.noReporte}</span>
                            </p>
                        </div>
                    </div>

                    <div style={{width: '770px', marginLeft: '23px'}}>
                        <div className='d-flex justify-content-between'>
                            <div style={{width: '375px', border: 'solid black 1.5px', borderRadius: '5px'}}>
                                <p style={{color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}} className='text-bold mb-0 text-center'>
                                    &nbsp;&nbsp;DATOS DEL EQUIPO
                                </p>
                                <div className='d-flex align-items-stretch' style={{width: '343.44px', color: '#000'}}>
                                    <p style={{width: '104px', fontSize: '10px'}} className='my-1 px-1 text-bold d-flex align-items-center'>TIPO DE EQUIPO:</p>
                                    <p style={{width: '239.44px', fontSize: '10px'}} className='my-1 px-1'>{data.equipo.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{width: '343.44px', color: '#000'}}>
                                    <p style={{width: '104px', fontSize: '10px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>MARCA:</p>
                                    <p style={{width: '239.44px', fontSize: '10px'}} className='mb-1 px-1'>{data.marca.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{width: '343.44px', color: '#000'}}>
                                    <p style={{width: '104px', fontSize: '10px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>MODELO:</p>
                                    <p style={{width: '239.44px', fontSize: '10px'}} className='mb-1 px-1'>{data.modelo.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{width: '343.44px', color: '#000'}}>
                                    <p style={{width: '104px', fontSize: '10px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>NO. SERIE:</p>
                                    <p style={{width: '239.44px', fontSize: '10px'}} className='mb-1 px-1'>{data.noSerie.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{width: '343.44px', color: '#000'}}>
                                    <p style={{width: '104px', fontSize: '10px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>NO. INVENTARIO:</p>
                                    <p style={{width: '239.44px', fontSize: '10px'}} className='mb-1 px-1'>{data.noInventario.toUpperCase()}</p>
                                </div>
                            </div>
                            <div style={{width: '375px', border: 'solid black 1.5px', borderRadius: '5px'}}>
                                <p style={{color: '#000', fontSize: '10px', background: '#BAD7F2', borderBottom: 'solid black 1.5px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px'}} className='text-bold mb-0 text-center'>
                                    &nbsp;&nbsp;DATOS DEL HOSPITAL
                                </p>
                                <div className='d-flex align-items-stretch' style={{width: '343.44px', color: '#000'}}>
                                    <p style={{width: '90px', fontSize: '10px'}} className='my-1 px-1 text-bold d-flex align-items-center'>FECHA:</p>
                                    <p style={{width: '270.44px', fontSize: '10px'}} className='my-1 px-1'>{(getDate(data.fecha.diagnostico, 'DDD')).toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{width: '343.44px', color: '#000'}}>
                                    <p style={{width: '90px', fontSize: '10px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>HOSPITAL:</p>
                                    <p style={{width: '270.44px', fontSize: '10px'}} className='mb-1 px-1'>{data.hospital.toUpperCase()}</p>
                                </div>
                                <div className='d-flex align-items-stretch' style={{width: '343.44px', color: '#000'}}>
                                    <p style={{width: '90px', fontSize: '10px'}} className='mb-1 px-1 text-bold d-flex align-items-center'>ÁREA:</p>
                                    <p style={{width: '270.44px', fontSize: '10px'}} className='mb-1 px-1'>{data.area.toUpperCase()}</p>
                                </div>
                            </div>
                        </div>
                        {getDiagnostico()}
                    </div>
                    <div>
                        <div style={{position: 'absolute', left: '66.240px', bottom: '90px', width: '705px', height: '30.673px'}}>
                            <p className='text-center mb-0'>__________________________</p>
                            <p className='text-center mb-0' style={{color: '#000', fontSize: '10px'}}>RESPONSABLE</p>
                            <p className='text-center mb-0' style={{color: '#000', fontSize: '10px'}}>NOMBRE Y FIRMA</p>
                        </div>
                    </div>
                </div>
            )
        }
    }, [data])

    return(
        <div style={{background: '#FFF'}}>
            <div style={{marginTop: '10px', marginBottom: '12px'}}>
                <Button variant='contained' color='info' onClick={() => props.history.goBack()} className='mx-3'>
                    Regresar &nbsp; <i className="fad fa-arrow-circle-left" style={{transform: 'scale(1.3)'}}></i>
                </Button>
                <Button variant='contained' color='secondary' onClick={printDocument}>Imprimir hoja &nbsp; <i className="fas fa-print" style={{transform: 'scale(1.3)'}}></i></Button>
            </div>
            {renderHoja}
            
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
  
export default connect(mapStateToProps)(HojaDiagnostico);