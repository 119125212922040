import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import axios from 'axios';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from '@mui/material/TablePagination';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

// Custom components and functions
import CustomChip from '../../utils/components/CustomChip';
import { formatMoney, getDate } from '../../utils/functions/UtilsJuvalfri';
import useWindowDimensions from '../../utils/functions/useWindowDimensions';
import { statusFacturas } from '../../utils/functions/UtilsJuvalfri';
import { hospitales } from '../../utils/functions/UtilsJuvalfri';

// Styled components
import styled from 'styled-components';

const HeadText = styled.p`
    font-weight: 700;
    margin: 0px;
    font-size: 1.1em;
    padding: 0px;
`

const TextBold = styled.p`
  font-weight: 700;
  margin: 0px;
`

const CustomDiv = styled.div`
  width: ${props => props.width} !important;
`

function createData(id, noFactura, estatus, fechaEmision, hospital, subTotal,
  iva, total, noContrato, fechaHospital, fechaServiciosMedicos,
  fechaContrarecibo, fechaPago, observaciones, conceptos, reportes) {
  return {
    id,
    noFactura,
    estatus,
    fechaEmision,
    hospital,
    subTotal,
    iva,
    total,
    noContrato,
    fechaHospital,
    fechaServiciosMedicos,
    fechaContrarecibo,
    fechaPago,
    observaciones,
    conceptos,
    reportes,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [reportes, setReportes] = useState([{}]);

  useEffect(() => {
    if (open) {
      getReports();
    }
  }, [open])

  const getReports = async () => {
    try {
      let tmp = [];
      for (let x of Object.keys(row.reportes)) {
        const res = await axios.get(`/api/reportes/getReportByNumber/?reporte=${row.reportes[x].split(',')[0]}`);
        if (res.status === 200) {
          tmp.push(res.data.reportes[0]);
        }
      }
      setReportes(tmp);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}
        onDoubleClick={() => setOpen(!open)}
        selected={open}
        hover
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            style={{ transform: 'scale(0.8)' }}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center" style={{ width: '60px' }}>
          <TextBold>{row.noFactura}</TextBold>
        </TableCell>
        <TableCell align="center" style={{ width: '120px' }}><CustomChip label={row.estatus} variant='filled' estatus={row.estatus} /></TableCell>
        <TableCell align="center">{row.hospital}</TableCell>
        <TableCell align="center">{getDate(row.fechaEmision)}</TableCell>
        <TableCell align="center"><CustomDiv width='100px'><TextBold>{`$ ${formatMoney(row.subTotal)}`}</TextBold></CustomDiv></TableCell>
        <TableCell align="center"><CustomDiv width='90px'><TextBold>{`$ ${formatMoney(row.iva)}`}</TextBold></CustomDiv></TableCell>
        <TableCell align="center"><CustomDiv width='100px'><TextBold>{`$ ${formatMoney(row.total)}`}</TextBold></CustomDiv></TableCell>
        <TableCell align="center">{getDate(row.fechaHospital)}</TableCell>
        <TableCell align="center">{getDate(row.fechaServiciosMedicos)}</TableCell>
        <TableCell align="center">{getDate(row.fechaContrarecibo)}</TableCell>
        <TableCell align="center">{getDate(row.fechaPago)}</TableCell>
        <TableCell align="center">{row.observaciones}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <RenderDetails row={row} reportes={reportes} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function TablaReportes(props) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState({ estatus: 'ESTATUS', hospital: 'HOSPITAL', noFactura: '' });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const roundButton1 = useRef(null);
  const roundButton2 = useRef(null);
  const roundButton3 = useRef(null);
  const roundButton4 = useRef(null);
  const [data2, setData2] = useState([]);
  const [currentSuma, setCurrentSuma] = useState({ sub: 0, iva: 0, total: 0 });
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (props.auth.user.rol !== 'Admin') {
      props.history.push("/dashboard");
    }
  }, [props])

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if (data) {
      const tmp = data.map((row) => {
        return createData(row._id, row.factura.noFactura, row.factura.estatus, row.factura.fechaEmision, row.factura.hospital, row.factura.subTotal,
          row.factura.iva, row.factura.total, row.factura.noContrato, row.factura.fechaHospital, row.factura.fechaServiciosMedicos,
          row.factura.fechaContrarecibo, row.factura.fechaPago, row.factura.observaciones, row.factura.conceptos, row.factura.reportes
        )
      })
      setRows(tmp);
    }
  }, [data])

  const getData = async () => {
    try {
      const res = await axios.get('/api/facturas');
      if (res.status === 200) {
        setData(res.data);
        setData2(res.data);
        let tmpTotal = {
          sub: 0,
          iva: 0,
          total: 0
        }
        res.data.forEach(fac => {
          if (fac.factura.estatus !== 'CANCELADA') {
            tmpTotal.sub += fac.factura.subTotal;
            tmpTotal.iva += fac.factura.iva;
            tmpTotal.total += fac.factura.total;
          }
        })
        setCurrentSuma(tmpTotal);
        document.title = 'Facturas';
      }
    } catch (err) {
      console.log(err);
      alert('Ocurrió un error al cargar los reportes, favor de intentarlo mas tarde');
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const actions = [
    { icon: <FileCopyIcon />, name: 'Nueva Factura' },
    { icon: <FormatListNumberedIcon />, name: 'Lista de Refacciones' },
    { icon: <SaveIcon />, name: 'Descargar Tabla' },
    { icon: <RefreshIcon />, name: 'Actualizar' },
  ];

  useEffect(() => {
    simpleFilter()
  }, [filter])

  const simpleFilter = () => {
    let est, hosp;

    if (filter.estatus === 'TODOS' || filter.estatus === 'ESTATUS') {
      est = /[a-z]/i;
    } else {
      est = new RegExp(filter.estatus, 'i');
    }

    if (filter.hospital === 'TODOS' || filter.hospital === 'HOSPITAL') {
      hosp = /[a-z]/i;
    } else {
      hosp = new RegExp(filter.hospital, 'i');
    }

    let arrTmp = [];

    if (filter.noFactura !== '') {
      const noFac = new RegExp(filter.noFactura, 'i');
      arrTmp = data2.filter(x => est.test(x.factura.estatus) && hosp.test(x.factura.hospital) && noFac.test(x.factura.noFactura));
    } else {
      arrTmp = data2.filter(x => est.test(x.factura.estatus) && hosp.test(x.factura.hospital));
    }

    if (arrTmp !== undefined && arrTmp.length < 1) {
      setData([]);
    } else {
      setData(arrTmp);
      let tmpTotal = {
        sub: 0,
        iva: 0,
        total: 0
      }
      arrTmp.forEach(fac => {
        if (fac.factura.estatus !== 'CANCELADA') {
          tmpTotal.sub += fac.factura.subTotal;
          tmpTotal.iva += fac.factura.iva;
          tmpTotal.total += fac.factura.total;
        }
      })
      setCurrentSuma(tmpTotal);
    }
  }

  const handleClick = (action) => {
    switch (action) {
      case 'Nueva Factura': return roundButton1.current.click();
      case 'Lista de Refacciones': return roundButton2.current.click();
      case 'Descargar Tabla': return roundButton3.current.click();
      case 'Actualizar': return roundButton4.current.click();
    }
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'fixed', bottom: 12, left: 12 }}
        icon={<SpeedDialIcon />}
        direction="right"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action.name)}
          />
        ))}
      </SpeedDial>
      <TableContainer sx={{ height: `${height - 100}px` }}>
        <Table size="small" stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '50px' }} />
              {/* <TableCell align="center"><HeadText># Reporte</HeadText></TableCell> */}
              <TableCell align="center" style={{ minWidth: '90px' }}>
                <TextField
                  id="outlined-basic"
                  label="# Factura"
                  variant="standard"
                  size="small"
                  value={filter.noFactura}
                  onChange={(e) => setFilter({ ...filter, noFactura: e.target.value === '' ? '' : e.target.value })}
                /></TableCell>
              {/* <TableCell align="center"><HeadText>Estatus</HeadText></TableCell> */}
              <TableCell align="center">
                <TextField
                  id="filled-select-currency-native"
                  select
                  style={{ display: 'flex', maxWidth: '200px' }}
                  size='small'
                  label="Estatus"
                  value={filter.estatus}
                  onChange={(e) => setFilter({ ...filter, estatus: e.target.value })}
                  SelectProps={{
                    native: true,
                  }}
                  variant="standard"
                >
                  <option>ESTATUS</option>
                  {statusFacturas.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </TableCell>
              {/* <TableCell align="center"><HeadText>Equipo</HeadText></TableCell> */}
              {/* <TableCell align="center" style={{width: '200px !important'}}><HeadText>Hospital</HeadText></TableCell> */}
              <TableCell align="center">
                <TextField
                  id="filled-select-currency-native"
                  select
                  style={{ display: 'flex', maxWidth: '200px', minWidth: '120px' }}
                  label="Hospital"
                  size='small'
                  value={filter.hospital}
                  onChange={(e) => setFilter({ ...filter, hospital: e.target.value })}
                  SelectProps={{
                    native: true,
                  }}
                  variant="standard"
                >
                  <option>HOSPITAL</option>
                  {hospitales.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              </TableCell>
              <TableCell align="center"><HeadText>Fecha de Emisión</HeadText></TableCell>
              <TableCell align="center"><HeadText>Sub total</HeadText></TableCell>
              <TableCell align="center"><HeadText>IVA</HeadText></TableCell>
              <TableCell align="center"><HeadText>Total</HeadText></TableCell>
              <TableCell align="center"><HeadText>Fecha de entrega al hospital</HeadText></TableCell>
              <TableCell align="center"><HeadText>Fecha ingreso a servicios medicos</HeadText></TableCell>
              <TableCell align="center"><HeadText>Fecha contra recibo</HeadText></TableCell>
              <TableCell align="center"><HeadText>Fecha de pago</HeadText></TableCell>
              <TableCell align="center"><HeadText>Observaciones</HeadText></TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <Row key={row.id} row={row} />
            ))}
            <TableRow style={{ background: '#842033' }}>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"><TextBold style={{ color: '#FFF' }}>$ {formatMoney(currentSuma.sub)}</TextBold></TableCell>
              <TableCell align="center"><TextBold style={{ color: '#FFF' }}>$ {formatMoney(currentSuma.iva)}</TextBold></TableCell>
              <TableCell align="center"><TextBold style={{ color: '#FFF' }}>$ {formatMoney(currentSuma.total)}</TextBold></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 25, { value: -1, label: 'Todo' }]}
        style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#1F6D58', color: 'white', display: 'flex', justifyContent: 'center' }}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Link className='d-none' ref={roundButton1} to={{ pathname: '/facturas/nueva/' }}>
        <button>Nuevo Reporte</button>
      </Link>
      <Link target='_blank' className='d-none' ref={roundButton2} to={{ pathname: '/facturas/listaRefacciones/' }}>
        <button>Lista de Refacciones</button>
      </Link>
      <Link target='_blank' className='d-none' ref={roundButton3} to={{ pathname: '/facturas/descargar/' }}>
        <button>Descargar Tabla</button>
      </Link>
      <button className='d-none' ref={roundButton4} onClick={() => getData()}>Actualizar</button>
    </Paper>
  );
}

function RenderDetails(props) {
  const [reportes, setReportes] = useState([{}]);
  const [renderReportes, setRenderReportes] = useState(<></>);

  useEffect(() => {
    if (props.reportes.length > 0) {
      setReportes(props.reportes)
    }
  }, [props])

  useEffect(() => {
    if (reportes.length > 0 && reportes[0]._id !== undefined) {
      setRenderReportes(
        <>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {reportes.map((r, i) => (
              <div key={i} style={{ fontSize: '0.9em', color: '#000', maxWidth: '350px' }} className='mx-1'>
                <Paper elevation={3} className='p-1'>
                  <p className='text-bold pb-0 mb-0 text-center'>
                    Datos del Reporte
                  </p>
                  <hr className='my-1' />
                  <div className='d-flex align-items-stretch'>
                    <p style={{ width: '100px' }} className='mb-1 px-1 d-flex align-items-center text-bold'># Reporte:</p>
                    <p className='mb-1 px-1 text-bold h6'>{r.noReporte}</p>
                  </div>
                  <div className='d-flex align-items-stretch'>
                    <p style={{ width: '100px' }} className='mb-1 px-1 d-flex align-items-center text-bold'>Estatus:</p>
                    <CustomChip label={r.estatus} variant='outlined' estatus={r.estatus} />
                  </div>
                  <div className='d-flex align-items-stretch'>
                    <p style={{ width: '100px' }} className='my-1 px-1 d-flex align-items-center text-bold'>Equipo:</p>
                    <p className='my-1 px-1'>{r.equipo}</p>
                  </div>
                  <div className='d-flex align-items-stretch'>
                    <p style={{ width: '100px' }} className='mb-1 px-1 d-flex align-items-center text-bold'>Marca:</p>
                    <p className='mb-1 px-1'>{r.marca}</p>
                  </div>
                  <div className='d-flex align-items-stretch'>
                    <p style={{ width: '100px' }} className='mb-1 px-1 d-flex align-items-center text-bold'>Modelo:</p>
                    <p className='mb-1 px-1'>{r.modelo}</p>
                  </div>
                  <div className='d-flex align-items-stretch'>
                    <p style={{ width: '100px' }} className='mb-1 px-1 d-flex align-items-center text-bold'>No. Serie:</p>
                    <p className='mb-1 px-1'>{r.noSerie}</p>
                  </div>
                  <div className='d-flex align-items-stretch'>
                    <p style={{ width: '100px' }} className='mb-1 px-1 d-flex align-items-center text-bold'>No. Inventario:</p>
                    <p className='mb-1 px-1'>{r.noInventario}</p>
                  </div>
                  <div className='d-flex align-items-stretch'>
                    <p style={{ width: '100px' }} className='mb-1 px-1 d-flex align-items-center text-bold'>Fecha Servicio:</p>
                    <p className='d-flex mb-1 px-1 align-items-center'>{r.fecha.servicio ? (getDate(r.fecha.servicio, 'DDD')).toUpperCase() : ''}</p>
                  </div>
                  <hr className='my-1' />
                  {r.cotizacion ?
                    <div>
                      <div style={{ fontSize: '0.9em', color: '#000', maxWidth: '350px' }} >
                        <div className='d-flex align-items-stretch'>
                          <p style={{ width: '100px' }} className='my-1 px-1 d-flex align-items-center text-bold'>Subtotal:</p>
                          <p className='my-1 px-1'>$ {formatMoney(r.cotizacion.subtotal)}</p>
                        </div>
                        <div className='d-flex align-items-stretch'>
                          <p style={{ width: '100px' }} className='mb-1 px-1 d-flex align-items-center text-bold'>IVA:</p>
                          <p className='mb-1 px-1'>$ {formatMoney(r.cotizacion.iva)}</p>
                        </div>
                        <div className='d-flex align-items-stretch'>
                          <p style={{ width: '100px' }} className='mb-1 px-1 d-flex align-items-center text-bold'>Total:</p>
                          <p className='mb-1 px-1 text-bold h6'>$ {formatMoney(r.cotizacion.total)}</p>
                        </div>
                      </div>
                    </div> : <></>}
                </Paper>
              </div>
            ))}
          </div>
        </>
      );
    }
  }, [reportes])

  return (
    <>
      <Box className='py-2'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-arround' }}>
            <Link target='_blank' to={{ pathname: '/facturas/editar/' + props.row.id, state: { id: props.row.id } }} style={{ textDecoration: 'none' }}>
              <Button variant='contained' color='secondary' className='mx-2'>Editar Factura &nbsp; <i className='fad fa-file-edit'></i></Button>
            </Link>
            <Link target='_blank' to={{ pathname: '/facturas/print/' + props.row.id, state: { id: props.row.id } }} style={{ textDecoration: 'none' }}>
              <Button variant='contained' color='info' className='mx-2'>Imprimir Factura &nbsp; <i className='fad fa-file-invoice-dollar'></i></Button>
            </Link>
            <Link target='_blank' to={{ pathname: '/facturas/actaEntrega/' + props.row.id, state: { id: props.row.id } }} style={{ textDecoration: 'none' }}>
              <Button variant='contained' color='primary' className='mx-2'>Acta de Entrega-Recepción &nbsp; <i className='fad fa-file'></i></Button>
            </Link>
          </div>
        </div>
        <hr />

        {renderReportes}

      </Box>
    </>
  )
}

TablaReportes.prototype = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(TablaReportes)