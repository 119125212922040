import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Box, Divider, Typography, Grid, Button, Container, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Fab, ListItem, ListItemButton } from '@mui/material';

import CustomMessage from '../../utils/components/CustomMessage';
import CustomChip from '../../utils/components/CustomChip';
import { partidas } from '../../utils/functions/UtilsJuvalfri';

function Inventario(props) {
    const [data, setData] = useState([]);
    const [inventario, setInventario] = useState(null);
    const [inventario2, setInventario2] = useState(null);
    const [renderInventario, setRenderInventario] = useState(<></>);
    const [noCatalogo, setNoCatalogo] = useState('');
    const [refaccion, setRefaccion] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [partida, setPartida] = useState('');
    const [inventarioJuvalfri, setInventarioJuvalfri] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [newFlag, setNewFlag] = useState(false);

    const [consecutivo, setConsecutivo] = useState(0);

    const [modalShow, setModalShow] = useState(false);
    const [renderModal, setRenderModal] = useState(<></>);
    const [modalEdit, setModalEdit] = useState(false);

    const [currentEditRef, setCurrentEditRef] = useState(null);
    const [noCatalogoEdit, setNoCatalogoEdit] = useState('');
    const [refaccionEdit, setRefaccionEdit] = useState('');
    const [cantidadEdit, setCantidadEdit] = useState('');
    const [partidaEdit, setPartidaEdit] = useState('');
    const [inventarioJuvalfriEdit, setInventarioJuvalfriEdit] = useState('');
    const [reportesEdit, setReportesEdit] = useState('');
    const [observacionesEdit, setObservacionesEdit] = useState('');

    const [search, setSearch] = useState('');

    const [apiRes, setApiRes] = useState({show: false, message: '', severity: ''});

    useEffect(() => {
        if (props.auth.user.rol !=='Admin') {
            props.history.push("/dashboard");
          }
    }, [props])

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            let res = await axios.get('/api/inventarios');
            if(res.status === 200) {
                setData(res.data[0]);
                setInventario(res.data[0].inventario);
                setInventario2(res.data[0].inventario);
                document.title = 'Inventario Juvalfri';
            }
            res  = await axios.get('/api/inventarios/getConsecutivo');
            if(res.status === 200) {
                setConsecutivo(res.data);
                setInventarioJuvalfri(`JV-22-${String(res.data).padStart(4, '0')}`);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show}})
    }

    const getUuid = () => { return uuidv4() };
    
    const handleSubmit = async(e) => {
        e.preventDefault();

        const tmpArr = inventario;
        tmpArr.push({
            noCatalogo: noCatalogo,
            refaccion: refaccion,
            cantidad: cantidad,
            partida: partida,
            inventarioJuvalfri: inventarioJuvalfri,
            key: getUuid()
        });

        try {
            const res = await axios.post(`/api/inventarios/${data._id}`, {inventario: tmpArr, type: 'new'});
            if(res.status === 200) {
                setApiRes(state => { return{ ...state, show: true, message: 'Refacción agregada al inventario', severity: 'success' }});
                getData();
                setNoCatalogo('');
                setRefaccion('');
                setCantidad('');
                setPartida('');
                setObservaciones('');
            }
        } catch (err) {
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al crear el documento, favor de intentarlo mas tarde', severity: 'error' }});
            console.log(err);
        }
    }

    const handleEdit = (d) => {
        setModalEdit(true);
        setModalShow(true);
        setCurrentEditRef(d);
        setNoCatalogoEdit(d.noCatalogo);
        setRefaccionEdit(d.refaccion);
        setCantidadEdit(d.cantidad);
        setPartidaEdit(d.partida);
        setInventarioJuvalfriEdit(d.inventarioJuvalfri);
        setObservacionesEdit(d.observaciones);
        setReportesEdit(d.reportes);
    }

    const handleUpdateRefaccion = async (d, e) => {
        setModalShow(false);

        const tmpArr = inventario.map(r => r.key === e ? 
            { ...d, 
                noCatalogo: noCatalogoEdit,
                refaccion: refaccionEdit,
                cantidad: Number(cantidadEdit),
                partida: partidaEdit,
                inventarioJuvalfri: inventarioJuvalfriEdit,
                observaciones: observacionesEdit,
                reportes: reportesEdit,
                key: e,
            } : r);

        try {
            const res = await axios.post(`/api/inventarios/${data._id}`, {inventario: tmpArr});
            if(res.status === 200) {
                setApiRes(state => { return{ ...state, show: true, message: 'Refacción actualizada', severity: 'success' }});
                getData();
                setNoCatalogoEdit('');
                setRefaccionEdit('');
                setCantidad('');
                setPartidaEdit('');
                setInventarioJuvalfriEdit('');
                setObservacionesEdit('');
                setReportesEdit('');
                setCurrentEditRef(null);
            }
        } catch (err) {
            setApiRes(state => { return{ ...state, show: true, message: 'Ocurrió un error al modificar la refacción documento, favor de intentarlo mas tarde', severity: 'error' }});
            console.log(err);
        }
    }

    const handleCloseModal = () => {
        setModalShow(false);
        setModalEdit(false);
        setNewFlag(false);
    }

    useEffect(() => {
        if(inventario2 !== null) {
            setRenderInventario(
                <>
                    <Grid container spacing={1} style={{borderBottom: 'solid 1px #DDDDDD'}}>
                        <Grid item xs={12} md={1}>
                            <p className='text-bold mb-1 text-center'>No. Catalogo</p>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <p className='text-bold mb-1 text-center'>Refaccion</p>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <p className='text-bold mb-1 text-center'>Cantidad</p>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <p className='text-bold mb-1 text-center'>Partida</p>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <p className='text-bold mb-1 text-center'>Inventario</p>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <p className='text-bold mb-1 text-center'>Observaciones</p>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <p className='text-bold mb-1 text-center'>Reportes</p>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <p className='text-bold mb-1 text-center'></p>
                        </Grid>
                    </Grid>
                    {inventario2.map((r) => (
                        <ListItem key={r.key} disablePadding style={{borderBottom: 'solid #CFD8DC 0.5px'}}>
                            <ListItemButton onDoubleClick={() => handleEdit(r)}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={1}>
                                        <p className='my-1 text-center'>{r.noCatalogo}</p>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <p className='my-1 text-center'>{r.refaccion}</p>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <p className='my-1 text-center text-bold' style={{fontSize: '1.1em'}}>{r.cantidad}</p>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <p className='my-1 text-center'>{r.partida}</p>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <p className='my-1 text-center'>{r.inventarioJuvalfri}</p>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <p className='my-1 text-center'>{r.observaciones}</p>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <p className='my-1 text-center'>{r.reportes}</p>
                                    </Grid>
                                    <Grid item xs={12} md={1} className='text-center'>
                                        <Button size='small' variant='contained' color='info' onClick={() => handleEdit(r)}>Editar</Button>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </>
            )
        }
    }, [inventario, inventario2]);

    useEffect(() => {
        if(modalEdit) {
            if(currentEditRef !== null) {
                setRenderModal(
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={2}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                    <TextField id="outlined-basic" label="No. Catalogo" variant="outlined" value={noCatalogoEdit} onChange={(e) => setNoCatalogoEdit(e.target.value)} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                    <TextField id="outlined-basic" label="Refaccion" multiline variant="outlined" value={refaccionEdit} onChange={(e) => setRefaccionEdit(e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2} >
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                    <TextField id="outlined-basic" label="Cantidad" variant="outlined" multiline={true} value={cantidadEdit} onChange={(e) => setCantidadEdit(e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                    <Autocomplete
                                        freeSolo={true}
                                        onInputChange={(event, newInputValue) => setPartidaEdit(newInputValue)}
                                        inputValue={partidaEdit}
                                        aria-multiline='true'
                                        id="combo-box-demo"
                                        options={partidas}
                                        renderInput={(params) => <TextField {...params} label='Partida' />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                    <TextField id="outlined-basic" label="Inventario Juvalfri" variant="outlined" value={inventarioJuvalfriEdit} onChange={(e) => setInventarioJuvalfriEdit(e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2}>
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                    <TextField id="outlined-basic" label="Reportes asignados" variant="outlined" value={reportesEdit} onChange={(e) => setReportesEdit(e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                    <TextField id="outlined-basic" label="Observaciones" variant="outlined" value={observacionesEdit} onChange={(e) => setObservacionesEdit(e.target.value)} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item xs={12} sm={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                            <Button variant='contained' color='secondary' className='m-1 p-2' onClick={() => handleUpdateRefaccion(currentEditRef, currentEditRef.key)}>Actualizar &nbsp;&nbsp;<i className='fad fa-cogs' style={{transform: 'scale(1.3)'}}></i></Button>
                        </Grid>
                    </div>
                )
            }
        }
        if(newFlag) {
            setRenderModal(
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="No. Catalogo" variant="outlined" value={noCatalogo} onChange={(e) => setNoCatalogo(e.target.value)} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={8} >
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Refaccion" multiline variant="outlined" value={refaccion} onChange={(e) => setRefaccion(e.target.value)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Cantidad" variant="outlined" multiline={true} value={cantidad} onChange={(e) => setCantidad(Number(e.target.value))} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <Autocomplete
                                    freeSolo={true}
                                    onInputChange={(event, newInputValue) => setPartida(newInputValue)}
                                    inputValue={partida}
                                    aria-multiline='true'
                                    id="combo-box-demo"
                                    options={partidas}
                                    renderInput={(params) => <TextField {...params} label='Partida' />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Inventario Juvalfri" variant="outlined" value={inventarioJuvalfri} onChange={(e) => setInventarioJuvalfri(e.target.value)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={10} >
                            <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                                <TextField id="outlined-basic" label="Observaciones" variant="outlined" value={observaciones} onChange={(e) => setObservaciones(e.target.value)} />
                            </FormControl>
                        </Grid>
                        
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button className='mt-2' variant="contained" color="secondary" onClick={handleSubmit}>Agregar Refaccion al Inventario &nbsp; <i className="fad fa-save"></i></Button>
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }, [newFlag, modalEdit, noCatalogoEdit, refaccionEdit, cantidadEdit, partidaEdit, inventarioJuvalfriEdit, observacionesEdit, reportesEdit, currentEditRef, partida, noCatalogo, refaccion, cantidad, inventarioJuvalfri, observaciones]);

    const handleNew = () => {
        setNewFlag(true);
        setModalShow(true);
    }

    useEffect(() => {
        if(inventario !== null) {
            simpleFilter()
        }
    }, [search, inventario])

    const simpleFilter = () => {
        let rd;
  
        if(search === ''){
            rd = /[a-z]/i;
        } else {
            rd = new RegExp(search, 'i');
        }
  
        let arrTmp = [];
  
        arrTmp = inventario.filter(x => rd.test(x.refaccion));
  
        if(arrTmp !== undefined && arrTmp.length < 1) {
            setInventario2([]);
        } else {
            setInventario2(arrTmp);
        }
    }

  return (
    <Container maxWidth='xxl' style={{marginTop: '10px'}}>
        <Box className='text-center' >
            <Typography variant='h5' style={{fontWeight: 700}}>Inventario Juvalfri</Typography>
        </Box>

        <Fab aria-label="add" onClick={() => handleNew()} style={{position: 'fixed', top: '60px', left: '12px', zIndex: '10', backgroundColor: '#66bb6a', color: '#FFF'}}>
            <i className="fad fa-plus fa-2x"></i>
        </Fab>

        <Divider className='my-3' textAlign="center">
            <CustomChip label='Refacciones disponibles' className='text-bold'/>
        </Divider>

        <Grid container spacing={1} className='mb-2'>
            <Grid item xs={12} md={5} >
                <FormControl sx={{ m: 1, minWidth: 100 }} style={{display: 'flex'}}>
                    <TextField id="outlined-basic" label="Refaccion" multiline variant="outlined" value={search} onChange={(e) => setSearch(e.target.value)} />
                </FormControl>
            </Grid>
        </Grid>

        {renderInventario}

        <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={modalShow}
                className='exo2'
                scroll='paper'
                onClose={() => handleCloseModal()}
            >
                <DialogTitle >
                    {newFlag ? 'Nueva entrada de refacciones' : 'Editar entrada de refacciones'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {newFlag ? 'Ingrese los datos de la refaccion' : 'Editar los datos de la refaccion'}
                    </DialogContentText>
                    {renderModal}
                </DialogContent>
                <DialogActions>
                    <Button color='warning' variant='outlined' onClick={() => handleCloseModal()}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        
        <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />
    </Container>
  );
}

Inventario.prototype = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Inventario)